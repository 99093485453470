import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EMPTY_PHONE_NUMBER, SMS_RATE_LIMIT } from '../../constants/common';
import NoNumber from '../../components/forms/2fa/NoNumber';
import ConfirmSMS from '../../components/forms/2fa/ConfirmSms';
import NewNumber from '../../components/forms/2fa/NewNumber';
import PopupContainer from '../../layouts/containers/PopupContainer';
import LoadingController from '../../components/utils/LoadingController';
import { useAuth } from '../../providers/AuthProvider';
import { getPhoneHint } from '../../utils/getPhoneHint';
import { OPERATIONS } from '../../utils/constants';
import { useOperation } from '../../providers/OperationProvider';
import { useTranslation } from 'react-i18next';
import { ROUTE } from '../../utils/route';

export default function PhonePage() {
  const authProvider = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [smsTimeout, setSmsTimeout] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setOperation } = useOperation();
  const { t } = useTranslation();

  useEffect(() => {
    if (smsTimeout > 0) {
      const interval = setInterval(() => {
        setSmsTimeout((prevTimeout) => prevTimeout - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [smsTimeout]);

  const onSendSmsClick = async (number?: string) => {
    setIsLoading(true);
    try {
      if (number) {
        setPhoneNumber(number);
        authProvider.setPhoneHint(getPhoneHint(number));
      }
      await authProvider.sendSms(number || phoneNumber);
      setSmsTimeout(SMS_RATE_LIMIT);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401)
        navigate(ROUTE.rootPage);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onVerifyClick = async (code: string) => {
    setIsLoading(true);
    try {
      await authProvider.verifySmsCode(code, authProvider.temporaryToken);
      navigate(ROUTE.dashboardPage.rootPage);
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) navigate(ROUTE.rootPage);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer title={t('common.2fa-header')}>
      {authProvider.phoneHint === EMPTY_PHONE_NUMBER ? (
        <NoNumber />
      ) : isLoading || !authProvider.temporaryToken ? (
        <LoadingController />
      ) : !authProvider.phoneHint ? (
        <NewNumber onSendClick={onSendSmsClick} />
      ) : (
        <ConfirmSMS
          phoneHint={authProvider.phoneHint}
          smsTimeout={smsTimeout}
          onSendClick={onSendSmsClick}
          onVerifyClick={onVerifyClick}
        />
      )}
    </PopupContainer>
  );
}
