import React, { ReactNode } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from '@mui/material';

interface Props {
  title?: string;
  children: ReactNode;
  header?: ReactNode;
  subheader?: ReactNode | ReactNode[];
  actions?: ReactNode[];
}

export default function CardContainer({
  title,
  header,
  subheader,
  children,
  actions,
}: Props) {
  return (
    <Container maxWidth="md" sx={{ py: 10 }}>
      <Card
        variant={'outlined'}
        sx={{
          borderRadius: '12px',
          alignItems: { xs: 'center', md: 'flex-end' },
        }}
      >
        <CardHeader
          title={title ?? ''}
          subheader={
            Array.isArray(subheader) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: { xs: 'center', md: 'flex-end' },
                  width: '100%',
                }}
              >
                {subheader.map((element, index) => (
                  <div
                    key={index}
                    style={{
                      padding: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {element}
                  </div>
                ))}
              </Box>
            )
          }
          action={header ?? null}
        />
        {children && (
          <CardContent sx={{ backgroundColor: 'white' }}>
            {children}

            {actions && actions.length > 0 && (
              <Grid container spacing={2} padding={2}>
                {actions.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    {item}
                  </Grid>
                ))}
              </Grid>
            )}
          </CardContent>
        )}
      </Card>
    </Container>
  );
}
