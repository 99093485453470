import api from '../api';
import { useAuth } from '../providers/AuthProvider';
import { ITagRequest, ITagResponse } from '../interfaces/tags.interface';
import { IParams } from '../interfaces/request.interface';

interface TagContextType {
  getTags: (taxId: string, params: IParams) => Promise<ITagResponse>;
  addTag: (taxId: string, item: ITagRequest) => Promise<void>;
  removeTag: (taxId: string, item: ITagRequest) => Promise<void>;
}

const useTags = (): TagContextType => {
  const auth = useAuth();
  const getTags = async (
    taxId: string,
    params: IParams,
  ): Promise<ITagResponse> => {
    try {
      const response = await api.get<ITagResponse>(`/tags?taxId=${taxId}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const addTag = async (taxId: string, item: ITagRequest): Promise<void> => {
    try {
      await api.put<ITagResponse>(
        '/tags/add',
        { ...item, taxId },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  const removeTag = async (taxId: string, item: ITagRequest): Promise<void> => {
    try {
      await api.put<ITagResponse>(
        '/tags/remove',
        { ...item, taxId },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getTags,
    addTag,
    removeTag,
  };
};

export default useTags;
