import React from 'react';
import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PopupContainer from '../layouts/containers/PopupContainer';
import { Typography } from '@mui/material';

export default function ErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError();
  console.error(error);

  return (
    <PopupContainer title={t('pages.error404.not-found')}>
      <Typography variant="body1" component="p">
        {t('pages.error404.description')}
      </Typography>
      <img
        style={{ width: '70%', paddingTop: '20px' }}
        src={`/404-error.jpg`}
        alt={'404 error'}
        loading="lazy"
      />
    </PopupContainer>
  );
}
