import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { useAuth } from '../../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import { useOperation } from '../../../providers/OperationProvider';
import i18next from 'i18next';
import { OPERATIONS } from '../../../utils/constants';

interface Props {
  onClick: Function;
  disabled: boolean;
}

export default function SignUp({ onClick, disabled }: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const authProvider = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    emailError: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {}, [formData]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      setLoading(true);
      event.preventDefault();
      if (validateEmail(formData.email)) {
        setFormData({
          ...formData,
          emailError: '',
        });

        await authProvider.sendEmail(formData.email, i18next.resolvedLanguage);
        onClick();
      } else {
        setFormData({
          ...formData,
          emailError: t('pages.signup.error-msg') as string,
        });
      }
    } catch (error) {
      console.error(error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      sx={{ mt: 5 }}
      component="form"
      noValidate
      onSubmit={handleSubmit}
    >
      <Grid container>
        <Grid item xs={12}>
          <ClassicInput
            required
            fullWidth
            id="email"
            label={t('pages.signup.email-form')}
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleInputChange}
            error={!!formData.emailError}
            helperText={formData.emailError}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        {loading ? (
          <CircularProgress sx={{ m: 2 }} />
        ) : (
          <ClassicButton disabled={disabled} type="submit">
            {t('common.button.login-btn')}
          </ClassicButton>
        )}
      </Grid>
    </Grid>
  );
}
