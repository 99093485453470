import '../default.css';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  title: string;
  subtitle: ReactNode;
  cta?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
}
export default function NodeContainer({
  title,
  subtitle,
  cta,
  actions,
  children,
}: Props) {
  return (
    <>
      <Grid container sx={{ px: { xs: 2, md: 8 } }}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'start' },
          }}
        >
          <Typography component="h1" variant="h1">
            {title}
          </Typography>
        </Grid>
        {cta && (
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
              gap: 1,
              marginTop: [2, 0],
              alignItems: 'center',
            }}
          >
            {cta}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{ px: { xs: 2, md: 8 }, py: 2, borderBottom: '1px solid #9AD3DA' }}
      >
        {subtitle && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            {subtitle}
          </Grid>
        )}
        {actions && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
              gap: { xs: 0.4, md: 1 },
              marginTop: [2, 0],
            }}
          >
            {actions}
          </Grid>
        )}
      </Grid>
      <Container maxWidth="xl" component="main" sx={{ minHeight: '100vh' }}>
        {children}
      </Container>
    </>
  );
}
