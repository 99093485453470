// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./layouts/fonts/Nunito-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Nunito';
  src: local('Nunito'),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --vh: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #3d518c !important;
  font-family: 'Nunito', sans-serif !important;
}

.drawer {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
  height: 80px;
  justify-content: center;
}

div::-webkit-scrollbar {
  display: none;
}

.signature-canvas {
  height: 200px;
  width: 500px;
  border: 1px solid black;
  background: white;
}
@media (max-width: 600px) {
  .signature-canvas {
    width: 200px;
    height: 500px;
  }
}

.sigImage {
  display: block;
  max-width: 50%;
  max-height: 50px;
  margin: 0 auto;
  border: 1px solid black;
  padding: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB;8DACwE;EACxE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;;EAEE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,8BAA8B;EAC9B,4CAA4C;AAC9C;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF;;AAEA;EACE,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,cAAc;EACd,uBAAuB;EACvB,aAAa;AACf","sourcesContent":["@font-face {\n  font-family: 'Nunito';\n  src: local('Nunito'),\n    url('./layouts/fonts/Nunito-VariableFont_wght.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n:root {\n  --vh: 100%;\n}\n\nbody,\nhtml {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  background: #3d518c !important;\n  font-family: 'Nunito', sans-serif !important;\n}\n\n.drawer {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  overflow: hidden;\n  height: 80px;\n  justify-content: center;\n}\n\ndiv::-webkit-scrollbar {\n  display: none;\n}\n\n.signature-canvas {\n  height: 200px;\n  width: 500px;\n  border: 1px solid black;\n  background: white;\n}\n@media (max-width: 600px) {\n  .signature-canvas {\n    width: 200px;\n    height: 500px;\n  }\n}\n\n.sigImage {\n  display: block;\n  max-width: 50%;\n  max-height: 50px;\n  margin: 0 auto;\n  border: 1px solid black;\n  padding: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
