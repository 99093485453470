import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NoData from '../../../components/utils/NoData';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, Tab } from '@mui/material';
import useProjects from '../../../hooks/useProjects';
import PermissionGuard from '../../../guards/PermissionGuard';
import SortButton from '../../../components/actions/SortButton';
import LoadingController from '../../../components/utils/LoadingController';
import Pagination from '@mui/material/Pagination';
import ProjectItem from './items/ProjectItem';
import {
  ITEMS_PER_PAGE,
  SORTING,
  OPERATIONS,
  Tabs,
} from '../../../utils/constants';
import { useOperation } from '../../../providers/OperationProvider';
import {
  IProject,
  IProjectResponse,
} from '../../../interfaces/projects.interface';
import { ISorting } from '../../../interfaces/sorting.interface';
import { TabContext, TabList } from '@mui/lab';
import ProjectDialog from './ProjectDialog';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { usePermission } from '../../../providers/PermissionProvider';
import { SEARCH_INPUT_DELAY } from '../../../constants/common';

export default function ProjectPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { setOperation } = useOperation();
  const { getProjects, getDeactivatedProjects } = useProjects();
  const enterpriseProvider = useEnterprise();
  const permissionsProvider = usePermission();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fetchedProjects, setProjects] = useState<IProjectResponse>({
    data: [],
    total: 0,
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState<ISorting>(
    SORTING.CREATED_AT_DESC,
  );
  const [selectedFilter, setSelectedFilter] = useState<Tabs>(
    state && state.deactivated ? Tabs.DEACTIVATED : Tabs.ACTIVATED,
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState<number>(0);
  const [selectedProject, setSelectedProject] = useState<IProject>();

  useEffect(() => {
    if (enterpriseProvider.enterprises.data.length > 0)
      fetchProjects(currentPage, selectedSort, selectedFilter, searchQuery);
  }, [refresh, enterpriseProvider.enterprises]);

  const handleSortSelect = async (sort: ISorting) => {
    setSelectedSort(sort);
    setRefresh(Date.now());
  };

  const fetchProjects = async (
    page: number,
    sort: ISorting,
    filter: Tabs,
    searchQuery: string,
  ) => {
    setLoading(true);
    try {
      const taxIds = enterpriseProvider.enterprises.data
        .filter((ent) =>
          permissionsProvider.permissions.some(
            (perm) =>
              perm.taxId === ent.taxId &&
              perm.flag === 'ENTERPRISE_GET_DEACTIVATED_PROJECTS',
          ),
        )
        .map((el) => el.taxId);

      const fetchedProjects =
        filter === Tabs.ACTIVATED
          ? await getProjects({
              pageIndex: page - 1,
              sorting: sort.value,
              searchQuery,
            })
          : await getDeactivatedProjects(taxIds, {
              pageIndex: page - 1,
              sorting: sort.value,
              searchQuery,
            });
      setProjects(fetchedProjects);
    } catch (error) {
      console.error('Error: ', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  const onProjectClick = (project: IProject) => {
    setSelectedProject(project);
    setIsDialogOpen(true);
  };

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setCurrentPage(newPage);
    setRefresh(Date.now());
  };

  const handleFilterSelect = (filter: Tabs) => {
    setSelectedFilter(filter);
    setRefresh(Date.now());
  };

  const handleSearch = (value: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(value);
    const timeout = setTimeout(() => {
      setRefresh(Date.now());
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.project.title')}
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.projects')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
        subtitle={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              justifyContent: { xs: 'center', md: 'space-between' },
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <PermissionGuard
              permissions={['ENTERPRISE_GET_DEACTIVATED_PROJECTS']}
            >
              <TabContext value={selectedFilter}>
                <Box>
                  <TabList
                    onChange={(event, newValue) => handleFilterSelect(newValue)}
                  >
                    <Tab
                      label={t('common.profile.activated')}
                      value={Tabs.ACTIVATED}
                    />

                    <Tab
                      label={t('common.profile.deactivated')}
                      value={Tabs.DEACTIVATED}
                    />
                  </TabList>
                </Box>
              </TabContext>
            </PermissionGuard>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <SortButton
                sortOptions={[
                  SORTING.CREATED_AT_DESC,
                  SORTING.CREATED_AT_ASC,
                  SORTING.TITLE_ASC,
                  SORTING.CURRENT_USERS_DESC,
                  SORTING.TAGS_ASC,
                  SORTING.MAX_USERS_DESC,
                  SORTING.END_TIMESTAMP_ASC,
                ]}
                onSelectSort={handleSortSelect}
                selectedSort={selectedSort}
              />
              <PermissionGuard permissions={['ENTERPRISE_CREATE_PROJECT']}>
                <Box sx={{ mx: 1 }}>
                  <ClassicButton
                    sx={{ mt: 0 }}
                    startIcon={<AddIcon />}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    {t('common.button.add-btn')}
                  </ClassicButton>
                </Box>
              </PermissionGuard>
            </Box>
          </Box>
        }
      >
        {isLoading ? (
          <LoadingController />
        ) : fetchedProjects.total === 0 ? (
          <NoData />
        ) : (
          <>
            <Grid container spacing={2} sx={{ py: 5 }}>
              {fetchedProjects.data.map((project, index) => (
                <ProjectItem
                  key={index}
                  project={project}
                  setRefresh={setRefresh}
                  onItemClick={onProjectClick}
                />
              ))}
            </Grid>
            <Pagination
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              count={Math.ceil(fetchedProjects.total / ITEMS_PER_PAGE)}
              page={currentPage}
              onChange={handlePageChange}
              style={{ margin: '10px 0' }}
            />
          </>
        )}
      </NodeContainer>
      {isDialogOpen && (
        <ProjectDialog
          project={selectedProject}
          open={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
            setSelectedProject(undefined);
          }}
          setRefreshProjects={(value) => {
            setRefresh(value);
          }}
        />
      )}
    </header>
  );
}
