import { Card, Checkbox, Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IConsent } from '../../../../interfaces/consents.interface';

type Props = {
  consent: IConsent;
  isEditMode: boolean;
  showCheckboxes: boolean;
  updateObligatoryStatus?: () => void;
  onClick?: () => void;
};

export default function ConsentItem({
  consent,
  isEditMode,
  showCheckboxes,
  onClick,
  updateObligatoryStatus,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        p: 2.5,
        width: '100%',
        cursor: isEditMode ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid
            item
            xs={showCheckboxes ? 9 : 12}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography variant="subtitle1" align="left">
              {consent.title}
            </Typography>
          </Grid>
          {showCheckboxes && (
            <Grid item xs={3}>
              <Typography variant="body1" align="center">
                {t('common.required-consent')}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xs={showCheckboxes ? 9 : 12}>
            <Chip label={`v.${consent.version}`} size="small" />
          </Grid>
          {showCheckboxes && (
            <Grid
              item
              xs={3}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Checkbox
                checked={consent.isObligatory}
                disabled={!isEditMode}
                onClick={(e) => {
                  if (updateObligatoryStatus) {
                    e.stopPropagation();
                    updateObligatoryStatus();
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
