import React from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CenterContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30vh',
  textAlign: 'center',
});

const Image = styled('img')({
  maxWidth: '160px',
  marginBottom: '8px',
});

export default function NoData() {
  const { t } = useTranslation();
  const noDataWarning = t('common.no-data');

  return (
    <CenterContent>
      <Image src="/drawer.png" alt={noDataWarning} />
      <p>{noDataWarning} </p>
    </CenterContent>
  );
}
