import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import useLogs from '../../../hooks/useLogs';
import Pagination from '@mui/material/Pagination';
import LoadingController from '../../../components/utils/LoadingController';
import NoData from '../../../components/utils/NoData';
import { ITEMS_PER_PAGE, OPERATIONS } from '../../../utils/constants';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { useOperation } from '../../../providers/OperationProvider';
import { ILog, ILogResponse } from '../../../interfaces/logs.interface';
import { usePermission } from '../../../providers/PermissionProvider';
import DynamicTable, {
  Column,
} from '../../../components/builders/DynamicTable';
import moment from 'moment';
import { ROUTE } from '../../../utils/route';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClassicInput } from '../../../layouts/styled/buttons';
import {
  SEARCH_INPUT_DELAY,
  EMPTY_DATA,
  DEFAULT_PAGE_INDEX,
  DEFAULT_SORTING,
} from '../../../constants/common';
import { Chip, Typography } from '@mui/material';
import { getBlockExplorer } from '../../../utils/getLink';

export default function LogPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setOperation } = useOperation();
  const { getLogs } = useLogs();
  const enterpriseProvider = useEnterprise();
  const permissionsProvider = usePermission();
  const [refresh, setRefresh] = useState<number>(0);

  const columnsBase: Column<ILog>[] = [
    {
      label: t('common.tables.header.operationAt'),
      accessor: 'createdAt',
      type: 'createdAt',
      formatter: (value: number) => (
        <span>{moment.unix(value).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
    {
      label: t('common.tables.header.taxId'),
      accessor: 'taxId',
      type: 'taxId',
    },
    {
      label: t('common.tables.header.operationBy'),
      accessor: 'operationBy',
      type: 'operationBy',
    },
    {
      label: t('common.tables.header.operation'),
      accessor: 'operationType',
      type: 'operationType',
    },
    {
      label: t('common.tables.header.operationSubject'),
      accessor: 'operationSubject',
      type: 'operationSubject',
    },
    {
      label: t('common.tables.header.object'),
      accessor: 'object',
      type: 'object',
    },
    {
      label: t('common.tables.header.txnHash'),
      accessor: (item: ILog) => {
        return item && item.txnHash ? (
          <Typography variant={'body1'}>
            <a
              href={`${getBlockExplorer(item.createdAt)}${item.txnHash}`}
              target="_blank"
            >
              {item.txnHash.slice(0, 4)}...
              {item.txnHash.slice(-3)}
            </a>
          </Typography>
        ) : (
          <Chip
            label={t('common.no-data')}
            color="default"
            variant="outlined"
            size="small"
          />
        );
      },
      type: 'txnHash',
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const [logs, setLogs] = useState<ILogResponse>(EMPTY_DATA);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [sorting, setSorting] = useState<string>(DEFAULT_SORTING);

  useEffect(() => {
    if (enterpriseProvider.enterprises.data.length > 0) {
      fetchLogs(pageIndex, sorting, searchQuery);
    }
  }, [refresh]);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    setSearchQuery(searchParams.get('searchQuery') || '');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      sorting && sorting.split('_').length === 2 ? sorting : DEFAULT_SORTING,
    );
    setRefresh(Date.now());
  }, [searchParams]);

  const onSortClick = (column: string) => {
    setSearchParams({
      searchQuery,
      sorting: `${column}_${
        sorting.split('_')[0] === column && sorting.split('_')[1] === 'ASC'
          ? 'DESC'
          : 'ASC'
      }`,
    });
  };

  const onItemClick = (item: ILog) => {
    const operationType = item.operationType.toLowerCase();
    switch (true) {
      case operationType.includes('consent'):
        navigate(ROUTE.dashboardPage.consents.root);
        break;
      case operationType.includes('question'):
        navigate(ROUTE.dashboardPage.questions.root);
        break;
      case operationType.includes('user'):
        navigate(
          ROUTE.dashboardPage.enterprise.users.replace(':taxId', item.taxId),
        );
        break;
      case operationType.includes('enterprise'):
        navigate(ROUTE.dashboardPage.enterprise.root);
        break;
      case operationType.includes('benefit'):
        navigate(ROUTE.dashboardPage.benefits.root);
        break;
      case operationType.includes('tag'):
        navigate(
          ROUTE.dashboardPage.enterprise.tags.replace(':taxId', item.taxId),
        );
        break;
    }
  };
  const fetchLogs = async (
    pageIndex: number,
    sorting: string,
    searchQuery: string,
  ) => {
    setLoading(true);
    try {
      const taxIds = enterpriseProvider.enterprises.data
        .filter((el) =>
          permissionsProvider.permissions.some(
            (perm) =>
              perm.taxId === el.taxId && perm.flag === 'ENTERPRISE_GET_LOGS',
          ),
        )
        .map((x) => x.taxId);
      setLogs(
        await getLogs(taxIds, {
          pageIndex: pageIndex - 1,
          sorting,
          searchQuery,
        }),
      );
    } catch (error) {
      console.error('Error fetching logs:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      sorting,
      searchQuery,
    });
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({
        sorting,
        searchQuery,
      });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.operations.title')}
        subtitle={t('pages.operations.subtitle')}
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.operations')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
      >
        {isLoading ? (
          <LoadingController />
        ) : logs.data.length === 0 ? (
          <NoData />
        ) : (
          <DynamicTable
            title={''}
            data={logs.data}
            columns={columnsBase}
            sort={{
              column: sorting.split('_')[0],
              direction: sorting.split('_')[1],
            }}
            onHeaderClick={onSortClick}
            onItemClick={onItemClick}
            onExpandClick={(item) => console.log('Expand click', item)}
          />
        )}

        {logs.total > 0 && (
          <Pagination
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            count={Math.ceil(logs.total / ITEMS_PER_PAGE)}
            page={pageIndex}
            onChange={handlePageChange}
            style={{ margin: '10px 0' }}
          />
        )}
      </NodeContainer>
    </header>
  );
}
