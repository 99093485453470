import api from '../api';
import { useAuth } from '../providers/AuthProvider';
import { useCookies } from 'react-cookie';
import { ILogResponse, INotification } from '../interfaces/logs.interface';
import { ITEMS_PER_PAGE } from '../utils/constants';
import { IParams } from '../interfaces/request.interface';

interface NotificationContextType {
  updateCookies: (timestamp: number) => void;
  getUnreadNotificationsCount: () => Promise<number>;
  getNotifications: (params: IParams) => Promise<ILogResponse>;
  sendNotification: (requestBody: INotification) => Promise<void>;
  updateNotificationsStatus: (params: number[]) => Promise<void>;
}

const useNotifications = (): NotificationContextType => {
  const auth = useAuth();
  const [cookies, setCookie] = useCookies(['notification']);

  const getTimestampFromCookie = (): number => {
    try {
      return Number(cookies.notification) || 0;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateCookies = (timestamp: number): void => {
    try {
      if (getTimestampFromCookie() < timestamp)
        setCookie('notification', timestamp);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getUnreadNotificationsCount = async (): Promise<number> => {
    try {
      const notifications = await getNotifications({
        pageSize: ITEMS_PER_PAGE,
        sorting: `createdAt_DESC`,
      });
      return notifications.new;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getNotifications = async (params: IParams): Promise<ILogResponse> => {
    try {
      const response = await api.get<ILogResponse>('/notifications', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateNotificationsStatus = async (notifications: number[] ): Promise<void> => {
    try {
      await api.post('/notifications/update', 
      { notifications },
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      });
  
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  const sendNotification = async (
    requestBody: INotification,
  ): Promise<void> => {
    try {
      await api.post(
        '/notifications/send',
        { ...requestBody },
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    updateCookies,
    getUnreadNotificationsCount,
    getNotifications,
    sendNotification,
    updateNotificationsStatus,
  };
};

export default useNotifications;
