import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PolishResource from './locale/pl';
import EnglishResource from './locale/eng';

export default i18n.use(initReactI18next).init({
  fallbackLng: 'pl',
  debug: true,

  interpolation: {
    escapeValue: false,
  },

  resources: {
    pl: {
      translation: PolishResource,
    },
    en: {
      translation: EnglishResource,
    },
  },
});
