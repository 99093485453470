import { Badge, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ROUTE } from '../../utils/route';
import { useState, useEffect } from 'react';
import { OPERATIONS } from '../../utils/constants';
import useNotifications from '../../hooks/useNotifications';
import { useAuth } from '../../providers/AuthProvider';
import useWebSocket from 'react-use-websocket';
import { useOperation } from '../../providers/OperationProvider';

const socketUrl = process.env.REACT_APP_WSS_URL || 'ws://localhost:8000/';

export default function NotificationSelector() {
  const auth = useAuth();
  const { setOperation } = useOperation();
  const { getUnreadNotificationsCount } = useNotifications();
  const { lastMessage } = useWebSocket(socketUrl + auth.getToken());

  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isLoading && auth.sessionToken) loadData();
  }, [lastMessage, auth.isLoading, auth.sessionToken]);

  const loadData = async (): Promise<void> => {
    try {
      setUnreadNotificationsCount(await getUnreadNotificationsCount());
    } catch (error) {
      console.error('Error fetching unread notifications count:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  return (
    <IconButton
      sx={{ border: '1px solid', borderRadius: '50%' }}
      color="inherit"
      onClick={() => {
        navigate(ROUTE.dashboardPage.notification.root);
      }}
    >
      <Badge badgeContent={unreadNotificationsCount} color="error">
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
}
