import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import CardContainer from '../../../components/forms/CardContainer';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import LoadingController from '../../../components/utils/LoadingController';
import { useNavigate, useParams } from 'react-router-dom';
import useProjects from '../../../hooks/useProjects';
import { ROUTE } from '../../../utils/route';
import { useOperation } from '../../../providers/OperationProvider';
import i18next from 'i18next';
import { OPERATIONS } from '../../../utils/constants';

export default function ProjectInvitePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projectHash } = useParams();
  const { setOperation } = useOperation();

  const { inviteUserToProject } = useProjects();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const handleSubmit = async () => {
    try {
      setLoading(true);
      navigate(ROUTE.dashboardPage.projects.root);
      await inviteUserToProject(
        projectHash ?? '',
        email,
        i18next.resolvedLanguage,
      );
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.invited-to-project'),
      });
    } catch (error) {
      console.error(error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardContainer
      title={t('common.forms.titles.invite-participant-title') as string}
      actions={[
        <ClassicButton onClick={handleSubmit}>
          {t('common.button.invite-btn')}
        </ClassicButton>,
      ]}
    >
      {isLoading || !projectHash ? (
        <LoadingController />
      ) : (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <ClassicInput
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value as string)}
              label={t('common.profile.email')}
            />
          </Grid>
        </Grid>
      )}
    </CardContainer>
  );
}
