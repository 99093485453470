import './default.css';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { AppBar, CssBaseline, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { layoutTheme } from './styled/layout';

export default function FlowLayout() {
  return (
    <ThemeProvider theme={layoutTheme}>
      <CssBaseline />
      <div className="App">
        <Helmet>
          <title>Data Lake Application</title>
          <meta name="description" content="Data Lake Application" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </Helmet>

        <AppBar position="static">
          <Toolbar
            disableGutters
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <a href="/">
              <img src={'/logo.svg'} alt="logo" style={{ width: '15rem' }} />
            </a>
          </Toolbar>
        </AppBar>

        <main
          style={{
            minHeight: '95vh',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ minWidth: '50%' }}>
            <Outlet />
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
}
