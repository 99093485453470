import { IQuestion } from '../../../../interfaces/questions.interface';
import { Card, Checkbox, Chip, Grid, Typography } from '@mui/material';
import { CUSTOM, OPEN, YES_NO } from '../../../../constants/common';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import { useState } from 'react';
import { ClassicButton } from '../../../../layouts/styled/buttons';

type Props = {
  question: IQuestion;
  isEditMode?: boolean;
  showCheckboxes?: boolean;
  showAnswers?: boolean;
  onRevokeClick?: (questionHash: string) => Promise<void>;
  updateQuestion?: (id: number) => void;
  onClick?: () => void;
};

export default function QuestionItem({
  question,
  isEditMode = false,
  showCheckboxes = false,
  showAnswers = true,
  onClick,
  updateQuestion,
  onRevokeClick,
}: Props) {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onConfirmClick = async () => {
    setIsDialogOpen(false);
    if (!!onRevokeClick) await onRevokeClick(question.questionHash);
  };

  return (
    <>
      <Card
        sx={{
          p: 2.5,
          width: '100%',
          cursor: isEditMode ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" align="left">
              {question.question}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {!onRevokeClick && (
              <Grid item xs={showCheckboxes && question.type !== OPEN ? 9 : 12}>
                <Chip
                  label={
                    question.type === YES_NO
                      ? t('common.forms.labels.yes_no-item')
                      : question.type === CUSTOM
                      ? t('common.forms.labels.custom-item')
                      : question.type === OPEN
                      ? t('common.forms.labels.open-item')
                      : question.type
                  }
                  size="small"
                />
              </Grid>
            )}

            {showCheckboxes && question.type !== OPEN && (
              <Grid item xs={3}>
                <Typography variant="body1" align="center">
                  {t('common.required-answer')}
                </Typography>
              </Grid>
            )}
          </Grid>

          {!!question.answers &&
            showAnswers &&
            question.answers.map((el, index) => (
              <>
                {el.answer && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item xs={showCheckboxes ? 9 : 12}>
                      <Typography variant="body1" align="left">
                        {!onRevokeClick && `${index + 1}. `}
                        {question.type === YES_NO
                          ? t(`common.forms.labels.${el.answer}`)
                          : el.answer}
                      </Typography>
                    </Grid>

                    {showCheckboxes && (
                      <Grid
                        item
                        xs={3}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Checkbox
                          checked={el.isObligatory}
                          disabled={!isEditMode}
                          onClick={(e) => {
                            if (updateQuestion) {
                              e.stopPropagation();
                              updateQuestion(index);
                            }
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            ))}

          {!!onRevokeClick && (
            <Grid item xs={12}>
              <ClassicButton
                key="grant-revoke-button"
                onClick={() => setIsDialogOpen(true)}
                startIcon={null}
              >
                {t('common.button.remove-answer-btn')}
              </ClassicButton>
            </Grid>
          )}
        </Grid>
      </Card>

      <ConfirmationDialog
        open={isDialogOpen}
        description={t('common.confirm-question-revoke')}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        onConfirmClick={onConfirmClick}
      />
    </>
  );
}
