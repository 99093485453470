import api from '../api';
import { useAuth } from '../providers/AuthProvider';

interface FileProps {
  fileName: string;
  url: string;
}
interface TagContextType {
  getUploadUrl: (taxId: string, filename: string) => Promise<FileProps>;
  getReadUrl: (taxId: string, filename: string) => Promise<string>;
  deleteFile: (taxId: string, filename: string) => Promise<void>;
  uploadToAWS: (link: string, file: File) => Promise<void>;
}

const useFiles = (): TagContextType => {
  const auth = useAuth();
  const getUploadUrl = async (
    taxId: string,
    filename: string,
  ): Promise<FileProps> => {
    try {
      const response = await api.get<FileProps>(
        `/enterprise/consents/attachments/upload?taxId=${taxId}&fileName=${filename}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  const uploadToAWS = async (link: string, file: File): Promise<void> => {
    try {
      await fetch(link, {
        method: 'PUT',
        body: file,
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const deleteFile = async (taxId: string, filename: string): Promise<void> => {
    try {
      const response = await api.delete<void>(
        `/enterprise/consents/attachments?taxId=${taxId}&fileName=${filename}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getReadUrl = async (
    taxId: string,
    fileName: string,
  ): Promise<string> => {
    try {
      return `${process.env.REACT_APP_ASSETS_URL}/consent/${taxId}/${fileName}`;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getUploadUrl,
    getReadUrl,
    deleteFile,
    uploadToAWS,
  };
};

export default useFiles;
