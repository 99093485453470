const EnglishResource = {
  common: {
    welcome: 'Welcome!',
    here: 'here',
    'public-beta': 'Public Beta',
    motto: 'Help us inspire trust in science!',
    success: 'Success!',
    'contact-support': 'Contact Support',
    'go-to-dapp': 'Go to dapp',
    unverified: 'Unverified',
    'enter-number': 'Please enter your phone number',
    'no-number':
      'There is no phone number under your account. Unfortunately, you cannot use the platform without a phone number. To update your phone number, please get in touch with us.',
    '2fa-header': 'Two Factor Authentication',
    'enter-code': 'Please enter the code sent to your phone number',
    'confirm-number':
      'The verification code will be sent to the following number',
    'confirm-delete': 'Delete',
    'confirm-delete-message': 'Are you sure you want to delete this item?',
    'confirm-consents-revoke':
      'Are you sure you want to withdraw all your consents?',
    'confirm-consent-revoke': 'Are you sure you want to withdraw your consent?',
    'confirm-consent-sign': 'Are you sure you want to sign consent?',
    'confirm-remove-project-user':
      'Are you sure you want to remove this user from your project?',
    'confirm-questions-revoke':
      'Are you sure you want to delete all the question data?',
    'confirm-question-revoke':
      'Are you sure you want to delete the question data?',
    'confirm-remove-user':
      'Are you sure you want to remove this user from enterprise?',
    'confirm-delete-profile-message':
      'Do you want to remove your Data Lake Application account?',
    'details-delete-profile message':
      'Remember, that your consents and questions granted or filled through our platform for Data Lake or third parties are still valid until they are revoked, even after account is deleted.',
    'deleted-profile-message': 'Your account has been deleted',
    'no-data': 'No data',
    'not-provided': 'Not provided',
    'add-new-enterprise-btn': 'Add Enterprise Account',
    'wallet-declaration':
      'I have the full rights to use the following wallet and I agree with connecting it into Data Lake Application',
    'document-generation': 'Document generation',
    'csv-generation': 'CSV generation',
    'withdraw-reason': 'Withdraw reason',
    'sign-reason': 'Sign reason',
    'sms-rate-limit': 'Send the code again in: ',
    'unsupported-device':
      'Your screen is too small for signature. Please, try to rotate your phone or use another device.',
    optional: 'Optional',
    settings: 'Settings',
    actions: 'Actions',
    points: 'Points',
    lake: '$LAKE',
    reason: 'Reason: ',
    'required-answer': 'Required Answers',
    'required-consent': 'Is consent required?',
    'search-label': {
      projects: 'Search by project or user data...',
      consents: 'Search by consent or user data...',
      questions: 'Search by question or user data...',
      tags: 'Search by tag...',
      operations: 'Search by subject, object or operation type...',
      enterprises: 'Search by enterprise data...',
      users: 'Search by user data...',
    },
    'operation-status': {
      success: 'Operation successful!',
      info: 'Operation in progress...',
      error: 'Operation failed!',
    },
    'pending-transaction': {
      info: 'Hold on tight, your consents are being saved!',
      link: 'Verify the status of your operation ',
    },
    annotations: {
      'data-wont-be-saved': 'Your data and consent will not be saved',
      'data-will-be-saved': 'Your data and consent will be saved',
      'connect-wallet':
        'You need to have at least 10.000 LAKE tokens in your wallet to activate your new enterprise',
      'enterprise-tags':
        'Configure tags to filter and sort objects in the system',
      'question-description':
        'question description will be visible both to your personnel and people giving consents',
      'question-tags': 'Add tags to browse and filter your questions',
      'consent-enterprise':
        'Select an enterprise connected to this consent form',
      'consent-description':
        'Consent description will be visible both to your personnel and people giving consents',
      'consent-information-obligation':
        'In this field you can add additional information accompanying the consent, which will be visible to the person agreeing to the consent, such as an information clause',
      'consent-signature-type':
        'Select a signature type you would like to collect along with the consent',
      'consent-version':
        'Every change you make to the consent parameters will result in your consent form being automatically upgraded to the newest version',
      'consent-signs':
        'A total number of active consents (signed and not withdrawn)',
      salt: 'Four randomly generated english words',
      'user-signature':
        'KECCAK256 hash created from user data combined with salt by comma',
      'project-enterprise':
        'Select an enterprise connected to this project form',
      'project-description':
        'Project description will be visible both to your personnel and people giving consents',
      'project-note':
        'A note will not be visible to patients / research participants',
      'project-tags': 'Add tags to browse and filter your projects',
      'project-consents': 'Choose consents added to this project form',
      'project-question': 'Choose question added to this project form',
      'project-contact':
        'Contact person details will be visible both to your personnel and people giving consents',
      'project-end-date':
        'Project will be automatically deactivated after project end date',
      'project-required-fields':
        'Choose information that you would like to collect from your patients / research participants',
      'project-max-users': 'Choose your recruitment target for this project',
      'user-flow': {
        online: {
          part1:
            'If you provide your phone number, we will create an account for you in the Data Lake Application so that you can manage your consents in accordance with the ',
          part2:
            ' using the provided phone number and the email address to which you received the project invitation. You can find the rules of processing your personal data in our',
          part3: ' Privacy Policy.',
        },
        visit:
          'If you provide patient’s email address and phone number, we will create an account for the patient on the Data Lake Application where patient will be able to manage his / her consents, in line with the ',
        terms: 'Terms and Conditions',
      },
      'access-key':
        'If you generate Access Key for the project, you will be able to use the Data Lake Widget and create public links.',
    },
    'question-answers': 'question answers',
    'beta-access': {
      'success-title': 'Enterprise will be activated!',
      'failed-title': 'Enterprise will not be automatically activated.',
      title: 'Secure your access today!',
      'success-message':
        'Your Enterprise will be activated as you hold the required amount of the LAKE tokens!',
      'failed-message': 'You do not hold the required amount of tokens!',
      message: 'You can purchase your access tokens here:',
      congratulations: 'Congratulations!',
      subtitle: 'You’re all set up!',
      disclaimer: 'Beta Version: Functionalities may be limited.',
      buy: 'Go to dApp',
    },
    status: {
      active: 'Active',
      inactive: 'Inactive',
    },
    wallet: {
      connect: 'Connect ERC20 wallet',
      verify: 'Verify wallet',
      disconnect: 'Disconnect wallet',
    },
    button: {
      'activate-btn': 'Activate',
      'beta-test-btn': 'Go to the app',
      'deactivate-btn': 'Deactivate',
      'online-btn': 'Invite Online',
      'appointment-btn': 'Recruit Patient',
      'login-btn': 'Log In',
      'delete-profile-btn': 'Delete profile',
      'confirm-btn': 'Confirm',
      'export-csv-btn': 'Export CSV',
      'create-account-btn': 'Create Account',
      'add-tag-btn': 'Add Tag',
      'add-question-btn': 'Add Question',
      'add-answer-btn': 'Add Answer',
      'add-flag-btn': 'Add Flag',
      'add-btn': 'Add',
      'begin-btn': 'Begin!',
      'print-btn': 'Print',
      'remove-btn': 'Remove',
      'set-permissions-btn': 'Set permissions',
      'save-btn': 'Save',
      'create-btn': 'Create',
      'send-btn': 'Send',
      'clear-filter-btn': 'Clear Filter',
      'clear-sort-btn': 'Clear Sort',
      'clear-signature-btn': 'Clear',
      'next-btn': 'Next',
      'claim-btn': 'Claim',
      'show-more': 'Read More',
      'show-less': 'Read Less',
      'cancel-btn': 'Cancel',
      'sign-btn': 'Sign',
      'edit-btn': 'Edit',
      'end-btn': 'End',
      'sort-btn': 'Sort',
      'invite-btn': 'Invite',
      'filtr-btn': 'Filter',
      'more-info-btn': 'More Info',
      'show-answers-btn': 'Show Answers',
      'start-btn': 'Start',
      'upload-logo-btn': 'Upload Enterprise Logo',
      'send-code-btn': 'Send Code',
      'verify-btn': 'Verify',
      'revoke-all-btn': 'Revoke All',
      'remove-answer-btn': 'Remove Answer',
      'go-back-btn': 'Return to app',
      'back-btn': 'Back',
      'download-btn': 'Download',
      'generate-btn': 'Generate',
      'chat-btn': 'Chat',
      'email-btn': 'Email',
      'upload-btn': 'Upload',
      'edit-phone': 'Change Phone Number',
      'copy-link-btn': 'Copy Link',
      'close-btn': 'Close',
    },
    tabs: {
      consents: 'CONSENTS',
      questions: 'questions',
    },
    sorting: {
      all: 'ALL',
      signed: 'SIGNED',
      drafts: 'DRAFTS',
      oldest: 'OLDEST',
      newest: 'NEWEST',
      title: 'BY TITLE',
      name: 'BY NAME',
      popular: 'MOST POPULAR',
      potential: 'SIZE',
      ending: 'END DATE',
      secondName: 'BY SECOND NAME',
      totalSigns: 'POPULAR',
      activeSigns: 'ACTIVE SIGNS',
      lastActivity: 'BY LAST ACTIVITY',
      membersCount: 'BY MEMBERS',
      invitedUsersCount: 'MOST ACTIVE',
      tags: 'BY TAGS',
    },
    notify: {
      'USER CREATED': 'USER CREATED',
      'USER UPDATED': 'USER UPDATED',
      'ENTERPRISE CREATED': 'ENTERPRISE CREATED',
      'ENTERPRISE ACTIVATED': 'ENTERPRISE ACTIVATED',
      'ENTERPRISE DEACTIVATED': 'ENTERPRISE DEACTIVATED',
      'ENTERPRISE MODIFIED': 'ENTERPRISE MODIFIED',
      'ENTERPRISE DELETED': 'ENTERPRISE DELETED',
      'MAGIC LINK SENT': 'MAGIC LINK SENT',
      'AUTHENTICATED WITH GOOGLE': 'AUTHENTICATED WITH GOOGLE',
      'SMS SENT': 'SMS SENT',
      'AUTHENTICATED WITH SMS': 'AUTHENTICATED WITH SMS',
      AUTHENTICATED: 'AUTHENTICATED',
      'CONSENT SIGNED': 'CONSENT SIGNED',
      'CONSENT WITHDRAWN': 'CONSENT WITHDRAWN',
      'MESSAGE FROM ENTERPRISE': 'MESSAGE FROM ENTERPRISE',
      'PERMISSION ADDED': 'PERMISSION ADDED',
      'PERMISSION REMOVED': 'PERMISSION REMOVED',
      'USER ADDED TO ENTERPRISE': 'USER ADDED TO ENTERPRISE',
      'USER REMOVED FROM ENTERPRISE': 'USER REMOVED FROM ENTERPRISE',
      'PERMISSION FLAGS ADDED': 'PERMISSION FLAGS ADDED',
      'PERMISSION FLAGS DELETED': 'PERMISSION FLAGS DELETED',
      'CONSENT DRAFT CREATED': 'CONSENT DRAFT CREATED',
      'CONSENT DRAFT MODIFIED': 'CONSENT DRAFT MODIFIED',
      'CONSENT ACTIVATED': 'CONSENT ACTIVATED',
      'CONSENT DEACTIVATED': 'CONSENT DEACTIVATED',
      'CONSENT MODIFIED': 'CONSENT MODIFIED',
      'question DRAFT CREATED': 'question DRAFT CREATED',
      'question ACTIVATED': 'question ACTIVATED',
      'question DEACTIVATED': 'question DEACTIVATED',
      'question ANSWERED': 'question ANSWERED',
      'question MODIFIED': 'question MODIFIED',
      'TAGS ADDED': 'TAGS ADDED',
      'TAGS DELETED': 'TAGS DELETED',
      'TAGS ADDED TO CONSENT': 'TAGS ADDED TO CONSENT',
      'TAGS ADDED TO question': 'TAGS ADDED TO question',
      'TAGS ADDED TO USER': 'TAGS ADDED TO USER',
      'TAGS DELETED FROM CONSENT': 'TAGS DELETED FROM CONSENT',
      'TAGS DELETED FROM question': 'TAGS DELETED FROM question',
      'TAGS DELETED FROM USER': 'TAGS DELETED FROM USER',
      'PROJECT CREATED': 'PROJECT CREATED',
      'QUESTION CREATED': 'QUESTION CREATED',
    },
    'terms-of-service': {
      part1: 'By logging in, you agree to our ',
      part2: 'Terms of Service',
    },
    'data-processing-agreement': {
      part1: 'Accept ',
      part2: 'Data Processing Agreement',
    },
    'privacy-policy': {
      'file-name': 'Privacy Policy',
    },
    toggle: {
      individual: 'Individual',
      enterprise: 'I want to log in as an enterprise owner',
      activate: 'Activate',
      deactivate: 'Deactivate',
      publish: 'Publish',
      'revoke-consent': 'Revoke Consent',
      'grant-consent': 'Grant Consent',
    },
    labels: {
      'users-in-project-table': 'Your Users in the Project',
      'users-in-project-statistics-table':
        'Your Users in the Project Statistics',
      'users-in-project-overview-table': 'All Users in the Project',
      'users-in-consent-table': 'Operations on the consent',
      'users-in-question-table': 'Operations on the question',
      firstName: 'First Name',
      secondName: 'Second Name',
      email: 'Email',
      phone: 'Phone',
      checkbox: 'Verification of identity by staff',
      personalId: 'Personal ID',
      operationsHistory: 'Operations history',
    },
    profile: {
      id: 'ID',
      userHash: 'User hash',
      ownerHash: 'Owner hash',
      name: 'Name',
      taxId: 'Tax ID',
      firstName: 'First Name',
      secondName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      'phone-email-statement':
        'If you provide patient’s email address and telephone number, an account will be created on the Data Lake Application',
      country: 'Country',
      city: 'City',
      street: 'Street',
      building: 'Building',
      apartment: 'Apartment',
      source: 'Source',
      severity: 'Severity',
      notes: 'Notes',
      zip: 'ZIP Code',
      verificationLevel: 'Verification Level',
      registered: 'Registered by',
      message: 'Message',
      createdAt: 'Created At',
      activated: 'Activated',
      deactivated: 'Deactivated',
      personalId: 'Personal ID',
      lastActivity: 'Last Activity',
      membersCount: 'Enterprise Members',
      wallet: 'Crypto Wallet',
      address: 'Address',
    },
    tables: {
      header: {
        id: 'ID',
        email: 'Email',
        'first-name': 'First Name',
        'second-name': 'Second Name',
        'is-signed': 'Consent Status',
        notes: 'Notes',
        'created-at': 'Date of creation',
        'modified-at': 'Date of modification',
        phone: 'Phone Number',
        'invited-by': 'Invited by',
        'registred-by': 'Registred by',
        actions: 'Actions',
        today: 'Today',
        yesterday: 'Yesterday',
        thisWeek: 'This Week',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        all: 'All',
        title: 'Name',
        description: 'Description',
        taxId: 'Tax ID',
        tags: 'Tags',
        draft: 'Draft',
        activated: 'Activated',
        version: 'Version',
        content: 'Content',
        informationObligation: 'Additional Information',
        attachments: 'Attachments',
        signatureType: 'Signature Type',
        operationLog: 'Operation Log',
        operation: 'Operation',
        operationBy: 'Operation By',
        salt: 'Salt',
        userSignature: 'User Signature',
        operationAt: 'Date of operation',
        operationSubject: 'Operation Subject',
        object: 'Object',
        txnHash: 'Operation Hash',
        status: 'Status',
        accessKey: 'Access Key',
        question: 'Question',
        type: 'Type',
      },
      content: {
        signed: 'Signed',
        withdrawn: 'Withdrawn',
        notSigned: 'Not signed',
      },
      project: {
        joined: 'Joined',
        notJoined: 'Not joined',
      },
    },
    prints: {
      fullName: 'Name and Surname',
      personalId: 'Personal ID',
      phone: 'Phone',
      email: 'E-mail',
      question: 'Questions',
      consents: 'Consents',
      signed: 'granted',
      notSigned: 'not signed',
      withdrawn: 'withdrawn',
      consentStatus: 'Consent status: ',
      systemInfo: 'Consents were granted using',
      operationInfo: 'You can verify the latest operation using this',
      link: 'link',
      version: 'Version',
      fromDay: 'from day',
      informationObligation: 'Additional Information',
      attachments: 'Attachments',
    },
    forms: {
      noEnterpriseAccount: 'No Enterprise Account',
      createEnterpriseAccount: 'Create an account to get started',
      titles: {
        'enterprise-title': 'Page Title',
        'consent-title': 'Your Consent',
        'invite-participant-title': 'Invite Participant',
        'fill-participant-data': "Fill Patient's Data",
        'user-data': 'User Data',
        project: 'Project Form',
      },
      labels: {
        owner: 'Owner',
        taxId: 'Tax ID',
        title: 'Title',
        pointsPrice: 'Points Price',
        minPointsToClaim: 'Minimal Points',
        isCodeUnique: 'Unique Code',
        yes: 'Yes',
        no: 'No',
        allowedClaims: 'Allowed Claims',
        codes: 'Codes',
        enterprise: 'Enterprise',
        'question-description': 'question Description',
        requiredFields: 'Required Fields',
        'sub-account-statement':
          'I declare that I have verified the identity of the patient.',
        answer: 'Answer',
        question: 'Question',
        questions: 'Questions',
        questionType: 'Question Type',
        company: 'Company',
        'form.province': 'Province',
        'therapeutic-area': 'Therapeutic Area',
        'account-type': 'Account Type',
        'signature-type': 'Signature Type',
        createdAt: 'Created at',
        signs: 'Signs',
        activeSigns: 'Active consent signatures',
        salt: 'Salt',
        userSignature: 'User Signature',
        'mobile-device-signature': 'Mobile Device Signature',
        'patient-declaration': 'Patient Declaration',
        'vat-number': 'VAT Number',
        email: 'Email',
        name: 'Name',
        tag: 'Your Tags',
        permissions: 'Permissions',
        'user-permissions-groups': 'User permissions groups',
        'consent-content': 'Consent Content',
        'information-obligation': 'Additional Information',
        attachments: 'Attachments',
        'consent-description': 'Consent Description',
        city: 'City',
        building: 'Building',
        street: 'Street',
        zip: 'ZIP Code',
        visibility: 'Visibility',
        'invitation-link': 'Invitation Link',
        description: 'Description',
        note: 'Note',
        consents: 'Consents',
        contact: 'Contact Person',
        'end-date': 'Project End Date',
        'max-patients': 'Maximum Users',
        'yes_no-item': 'Yes / No',
        'custom-item': 'Custom',
        'open-item': 'Open',
        active: 'Activated',
        inactive: 'Inactive - enterprise functionalities inaccessible',
        'my-last-activity': 'My last activity',
        txn: 'Operation Log',
        clear: 'Clear',
        type: 'Type of question',
        answers: 'Answers',
      },
      errors: {
        'field-required': 'Field is required',
        'vat-number-too-short': 'VAT Number is too short',
        'invalid-vat-number': 'Invalid VAT Number',
        'gus-data-unavailable': 'GUS Data is unavailable',
        'at-least-one': 'At least one answer is needed.',
        'invalid-email': 'Invalid Email',
        'invalid-phone': 'Invalid Phone Number',
        'invalid-phone-or-email': 'Invalid Phone Number or Email',
        'max-users': 'Value should be between 0 and 1000000000',
        'invalid-personal-id': 'Invalid Personal ID',
        'consents-required': 'This consent is required',
        'signature-required': 'Signature is required',
        'file-size': 'File size should be less than 2MB',
        'file-type': 'File type should be .pdf, .jpg, .jpeg or .png',
        'project-question':
          'The given answer does not meet the conditions for inclusion in the project.',
      },
    },
    error: {
      required: 'Required',
      'no-data': 'No data',
      'consent-inactive': 'Consent is inactive',
      'consent-mobile': 'Consent requires mobile device signature',
    },
  },
  userflow: {
    invite: {
      title: 'Invite a Study Participant',
    },
    welcome: {
      title: 'Welcome Data Hero!',
      description:
        'Thank you for sharing your treatment history and research results to help other patients. The first step is behind you. Completing the rest of the formalities is easy, and we will guide you through the entire process. Are you ready to become a Data Hero?',
    },
    email: {
      title: "Let's start from email!",
    },
    question: {
      title: 'Complete a question',
    },
    consent: {
      title: 'Consents',
    },
    sign: {
      info: 'Please sign with your full name and surname',
      title: 'Sign',
    },
    congratulations: {
      title: 'Thank you for completing the form!',
      description:
        'If you have provided your email address and phone number, click below to log in to the system',
      contact:
        'If you have any questions about the Data Lake system, please contact us!',
    },
  },
  pages: {
    signup: {
      title: 'Sign Up',
      'welcome-disabled': 'Sorry!',
      description:
        'Sign up by entering your email address below - we will send you an email with a link which will login you.',
      'disabled-description':
        'Unfortunately, the login process is currently disabled, because the system is under maintenance. Try again later.',
      'email-form': 'Your Email',
      'success-msg':
        "You should receive an email from us shortly. If you don't receive it, please check your spam folder and then try registering again.",
      'error-msg': 'Invalid email address',
      action: 'Sign Up',
    },
    accountVerification: {
      title: 'We are verifying your submission',
      subtitle: 'We will inform you about updates through email',
      question: 'In case of any questions get in touch with us',
    },
    error404: {
      title: 'Oops!',
      description: 'Sorry, an unexpected error occurred.',
      'not-found': 'Page not found',
    },
    project: {
      title: 'Projects',
      subtitle: '',
      'title-editor': 'Project',
    },
    userData: {
      title: 'My Data',
      subtitle: '',
    },
    benefits: {
      title: 'Benefits',
      subtitle: '',
      'title-editor': 'Benefit',
    },
    consents: {
      title: 'Consents',
      subtitle: '',
      'title-editor': 'Consent',
    },
    users: {
      title: 'Users',
      subtitle: '',
    },
    dashboard: {
      title: 'Dashboard',
      subtitle: '',
    },
    operations: {
      title: 'Operations',
      subtitle: '',
      messages: {
        'enrolled-to-project':
          'User was successfully enrolled in to the project',
        'invited-to-project': 'User was successfully invited to the project',
        'project-edited': 'Project was successfully edited',
        'project-saved': 'Project was successfully saved',
        'question-revoked': 'question(s) were successfully revoked',
        'consent-revoked': 'Consent(s) were successfully revoked',
        'consent-signed': 'Consent(s) were successfully signed',
        'benefit-added': 'Benefit was successfully added',
        'question-edited': 'Question was successfully edited',
        'question-deleted': 'Question was successfully deleted',
        'question-added': 'Question was successfully added',
        'question-activated': 'Question was successfully activated',
        'question-deactivated': 'Question was successfully deactivated',
        'project-activated': 'Project was successfully activated',
        'project-deactivated': 'Project was successfully deactivated',
        'project-liked': 'Project was successfully liked',
        'project-disliked': 'Project was successfully disliked',
        'enterprise-modified': 'Enterprise was successfully modified',
        'tags-modified': 'Tags were successfully modified',
        'user-removed': 'User was successfully removed',
        'user-modified': 'User was successfully modified',
        'consent-created': 'Consent was successfully created',
        'consent-modified': 'Consent was successfully modified',
        'consent-deleted': 'Consent was successfully deleted',
        'consent-activated': 'Consent was successfully activated',
        'consent-deactivated': 'Consent was successfully deactivated',
        'enterprise-activated': 'Enterprise was successfully activated',
        'enterprise-deactivated': 'Enterprise was successfully deactivated',
        'benefit-activated': 'Benefit was successfully activated',
        'benefit-deactivated': 'Benefit was successfully deactivated',
        'permissions-modified': 'Permissions were successfully modified',
        'link-copied': 'Link was successfully copied',
      },
    },
    enterpriseOperations: {
      title: 'Enterprise Operations',
      subtitle: '',
    },
    notification: {
      title: 'Notifications',
      'title-add': 'Add Notification',
      subtitle: '',
      'add-btn': 'Add',
      form: {
        'user-id': 'User ID',
        message: 'Your message...',
      },
    },
    questions: {
      title: 'Questions',
      subtitle: 'Set up a questions which you can later use in a project',
      'title-editor': 'Question',
    },
    profile: {
      title: 'Profile',
      subtitle: '',
      header: 'Your Profile',
    },
    logout: {
      title: 'Logout',
    },
    enterprise: {
      title: 'Activate Account',
      subtitle:
        'You need to activate your enterprise to add users from your organization and set up questions, consents and project enrollment forms',
      header: 'List:',
      'create-account': 'Create Account',
      activateAccount: 'Activate Account',
      deactivateAccount: 'Deactivate Account',
      editAccount: 'Edit Account',
    },
    termsService: {
      title: 'Privacy Policy',
    },
    billing: {
      title: 'Pricing',
      subtitle: '',
    },
    enterprises: {
      title: 'Enterprises',
      subtitle: 'Active Entities',
      managementUsers: 'Users',
      'title-editor': 'Enterprise',
    },
    tags: {
      title: 'Tags',
      subtitle: 'Your Labels',
    },
  },
  dialogs: {
    setPermissions: {
      title: 'Set user permissions',
    },
  },
};

export default EnglishResource;
