import React, { useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import useProfile from '../../../hooks/useProfile';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import ProfileItem from './items/ProfileItem';
import { ClassicButton } from '../../../layouts/styled/buttons';
import { useAuth } from '../../../providers/AuthProvider';
import { MESSAGE_TIMEOUT, OPERATIONS } from '../../../utils/constants';
import { useOperation } from '../../../providers/OperationProvider';
import LoadingController from '../../../components/utils/LoadingController';

export default function ProfilePage() {
  const auth = useAuth();
  const { setOperation } = useOperation();
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const profileProvider = useProfile();

  const deleteProfile = async () => {
    setIsDeleting(true);
    try {
      await profileProvider.deleteProfile();
      setIsConfirmation(true);
      setTimeout(() => {
        auth.signOut();
        setDeleteDialogOpen(false);
      }, MESSAGE_TIMEOUT);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.profile.title')}
        subtitle={t('pages.profile.subtitle')}
      >
        <Grid container justifyContent="center" sx={{ py: 5 }}>
          <Grid item xs={12} md={12}>
            {profileProvider.currentProfile ? (
              <ProfileItem
                key={'profile-item'}
                profile={profileProvider.currentProfile}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '50px 150px',
                }}
              >
                <CircularProgress size={50} />
              </Box>
            )}
          </Grid>
        </Grid>

        {/* Delete Btn */}
        <ClassicButton onClick={() => setDeleteDialogOpen(true)}>
          {t('common.button.delete-profile-btn')}
        </ClassicButton>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>{t('common.confirm-delete')}</DialogTitle>
          <DialogContent sx={{ minWidth: { xs: '300px', md: '450px' } }}>
            {isDeleting ? (
              <LoadingController />
            ) : isConfirmation ? (
              <DialogContentText>
                {t('common.deleted-profile-message')}
              </DialogContentText>
            ) : (
              <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                {t('common.confirm-delete-profile-message')}
                {'\n'}
                {t('common.details-delete-profile message')}
              </DialogContentText>
            )}
          </DialogContent>
          {!isConfirmation && (
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>
                {t('common.button.cancel-btn')}
              </Button>
              <Button onClick={() => deleteProfile()}>
                {t('common.button.confirm-btn')}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </NodeContainer>
    </header>
  );
}
