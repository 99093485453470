import { ISorting } from '../interfaces/sorting.interface';

export const COLORS = {
  neutralColor: '#2A2F4F',
  primaryColor: '#3D518C',
  secondaryColor: '#5065A1',
  accentColor: '#71C0CB',
  specialColor: '#DEF0F4',
  successColor: '#4CAF50',
  errorColor: '#ef5350',
  fieldErrorColor: '#D23130',
  infoColor: '#03a9f4',
  lightGrey: '#F5F5F5',
  greyColor: '#CACACA',
  whiteColor: '#FFFFFF',
};

export enum Tabs {
  NONE = '',
  DRAFT = 'draft',
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
  CONSENTS = 'consents',
  QUESTIONS = 'questions',
}

export const OPERATIONS = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

export const SORTING = {
  CREATED_AT_ASC: {
    title: 'common.sorting.oldest',
    value: 'createdAt_ASC',
  } as ISorting,
  CREATED_AT_DESC: {
    title: 'common.sorting.newest',
    value: 'createdAt_DESC',
  } as ISorting,
  TITLE_ASC: { title: 'common.sorting.title', value: 'title_ASC' } as ISorting,
  NAME_ASC: { title: 'common.sorting.name', value: 'name_ASC' } as ISorting,
  CURRENT_USERS_DESC: {
    title: 'common.sorting.popular',
    value: 'currentUsers_DESC',
  } as ISorting,
  MAX_USERS_DESC: {
    title: 'common.sorting.potential',
    value: 'maxUsers_DESC',
  } as ISorting,
  END_TIMESTAMP_ASC: {
    title: 'common.sorting.ending',
    value: 'endTimestamp_ASC',
  } as ISorting,
  SECOND_NAME_ASC: {
    title: 'common.sorting.secondName',
    value: 'secondName_ASC',
  } as ISorting,
  TOTAL_SIGNS_DESC: {
    title: 'common.sorting.totalSigns',
    value: 'totalSigns_DESC',
  } as ISorting,
  ACTIVE_SIGNS_DESC: {
    title: 'common.sorting.activeSigns',
    value: 'activeSigns_DESC',
  } as ISorting,
  ENTERPRISE_MEMBERS_DESC: {
    title: 'common.sorting.membersCount',
    value: 'membersCount_DESC',
  } as ISorting,
  ACTIVITY_DESC: {
    title: 'common.sorting.lastActivity',
    value: 'lastActivity_DESC',
  } as ISorting,
  INVITED_USERS_COUNT_DESC: {
    title: 'common.sorting.invitedUsersCount',
    value: 'invitedUsersCount_DESC',
  } as ISorting,
  TAGS_ASC: {
    title: 'common.sorting.tags',
    value: 'tags_ASC',
  } as ISorting,
};

export const SORTING_OPTIONS = [
  SORTING.CREATED_AT_ASC,
  SORTING.CREATED_AT_DESC,
  SORTING.TITLE_ASC,
  SORTING.NAME_ASC,
  SORTING.CURRENT_USERS_DESC,
  SORTING.MAX_USERS_DESC,
  SORTING.END_TIMESTAMP_ASC,
  SORTING.SECOND_NAME_ASC,
  SORTING.TOTAL_SIGNS_DESC,
  SORTING.ACTIVE_SIGNS_DESC,
  SORTING.ENTERPRISE_MEMBERS_DESC,
  SORTING.ACTIVITY_DESC,
  SORTING.INVITED_USERS_COUNT_DESC,
  SORTING.TAGS_ASC,
];

export const FILTERS = {
  ENTERPRISE_ITEM: {
    ALL: [
      'ownerHash',
      'id',
      'logoUrl',
      'id',
      'activated',
      'createdAt',
      'lastActivity',
      'membersCount',
    ],
    OWNER: ['ownerHash', 'id', 'logoUrl', 'id', 'activated'],
  },
};

export const LANGUAGES = [
  { name: 'Polski', flag: '/polish-flag.png', code: 'pl' },
  { name: 'English', flag: '/english-flag.png', code: 'en' },
];

const PERMISSIONS = {
  USER: {
    USER_GET_USER_DATA: 'USER_GET_USER_DATA',
    USER_MODIFY_USER_DATA: 'USER_MODIFY_USER_DATA',
    USER_SEND_SMS: 'USER_SEND_SMS',
    USER_AUTHENTICATE_SMS: 'USER_AUTHENTICATE_SMS',
    USER_GET_GUS_DATA: 'USER_GET_GUS_DATA',
    USER_GET_LOGS: 'USER_GET_LOGS',
    USER_GET_NOTIFICATIONS: 'USER_GET_NOTIFICATIONS',
    USER_GET_ALL_ENTERPRISES_DATA: 'USER_GET_ALL_ENTERPRISES_DATA',
    USER_GET_ENTERPRISE_DATA: 'USER_GET_ENTERPRISE_DATA',
    USER_CREATE_ENTERPRISE: 'USER_CREATE_ENTERPRISE',
    USER_MODIFY_ENTERPRISE: 'USER_MODIFY_ENTERPRISE',
    USER_GET_PERMISSIONS: 'USER_GET_PERMISSIONS',
    USER_GET_TOKEN: 'USER_GET_TOKEN',
    USER_SWITCH_ENTERPRISE: 'USER_SWITCH_ENTERPRISE',
    USER_GET_PERMISSION_FLAGS: 'USER_GET_PERMISSION_FLAGS',
  },
  ENTERPRISE: {
    ENTERPRISE_SEND_NOTIFICATION: 'ENTERPRISE_SEND_NOTIFICATION',
    ENTERPRISE_GET_LOGS: 'ENTERPRISE_GET_LOGS',
    ENTERPRISE_ADD_PERMISSION: 'ENTERPRISE_ADD_PERMISSION',
    ENTERPRISE_REMOVE_PERMISSION: 'ENTERPRISE_REMOVE_PERMISSION',
  },
  SUPER_ENTERPRISE: {
    SUPER_ENTERPRISE_ACTIVATE_ENTERPRISE:
      'SUPER_ENTERPRISE_ACTIVATE_ENTERPRISE',
    SUPER_ENTERPRISE_DEACTIVATE_ENTERPRISE:
      'SUPER_ENTERPRISE_DEACTIVATE_ENTERPRISE',
  },
};

export const ITEMS_PER_PAGE = 10;
export const MAX_ITEMS_PER_PAGE = 9999;
export const MAXIMUM_FILE_SIZE = 2 * 1024 * 1024; // in mb
export const MESSAGE_TIMEOUT = 5000;
export const INFO_MESSAGE_TIMEOUT = 15000;

export const REQUIRED_FIELDS = [
  'firstName',
  'secondName',
  'email',
  'phone',
  'checkbox',
  'personalId',
];

export const SIGNING_MESSAGE =
  'I have the full rights to use this ERC-20 address and I agree with connecting it into Data Lake Application';
export const LAKE_TOKEN_ADDRESS = '0xF9Ca9523E5b5A42C3018C62B084Db8543478C400';
