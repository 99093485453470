import { useEffect, useState } from 'react';
import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import {
  ClassicButton,
  ClassicInput,
  ClassicSelect,
  OutlineButton,
} from '../../../layouts/styled/buttons';
import { useTranslation } from 'react-i18next';
import {
  IAnswerBasicData,
  IQuestion,
} from '../../../interfaces/questions.interface';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../../../utils/route';
import {
  CUSTOM,
  NO_ANSWER,
  OPEN,
  YES_ANSWER,
  YES_NO,
} from '../../../constants/common';
import { useAuth } from '../../../providers/AuthProvider';
import { COLORS } from '../../../utils/constants';

interface Props {
  isOnline: boolean;
  questions: IQuestion[];
  onButtonClick: (responses: IAnswerBasicData[]) => void;
}

export default function Questions({
  isOnline,
  questions,
  onButtonClick,
}: Props) {
  const navigate = useNavigate();
  const authProvider = useAuth();
  const { t } = useTranslation();
  const [questionResponses, setQuestionResponses] = useState<
    IAnswerBasicData[]
  >([]);
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>(
    {},
  );
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setQuestionResponses(
      questions.map((el) => ({
        questionId: el.id!,
        questionHash: el.questionHash,
        answer:
          el.type === YES_NO
            ? NO_ANSWER
            : el.type === OPEN
            ? ''
            : el.answers[0].answer,
      })),
    );
  }, [questions]);

  useEffect(() => {
    let newErrors: Record<string, string> = {};
    questions.forEach((el) => {
      if (
        el.answers.length > 0 &&
        el.answers.some((answer) => answer.isObligatory)
      ) {
        const response = questionResponses.find(
          (response) => response.questionId === el.id,
        );
        if (
          !response ||
          !el.answers.some(
            (el) => el.answer === response.answer && el.isObligatory,
          )
        )
          newErrors = {
            ...newErrors,
            [el.id]: t('common.forms.errors.project-question'),
          };
      }
    });

    setErrors(newErrors);
  }, [questionResponses]);

  const handleInputChange = (questionId: number, selectedAnswer: string) => {
    const updatedResponses = questionResponses.map((el) =>
      el.questionId === questionId ? { ...el, answer: selectedAnswer } : el,
    );
    setQuestionResponses(updatedResponses);
    setTouchedFields((prev) => ({
      ...prev,
      [questionId]: true,
    }));
  };

  const isValid = (): boolean => {
    let isValid = true;
    if (Object.entries(errors).some((el) => el[0] !== '')) {
      isValid = false;
    }
    return isValid;
  };

  const onCancelClick = () => {
    if (isOnline) authProvider.signOut();
    navigate(ROUTE.dashboardPage.projects.root);
  };

  return (
    <>
      <CardHeader
        title={
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '2.5rem', md: '3rem' } }}
            >
              {t('userflow.question.title')}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        {questions.map((item, index) => (
          <Grid container sx={{ mb: 4 }} key={index}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 auto',
                }}
              >
                <Typography variant={'body1'}>{item.question}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {item.type === YES_NO ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant={'body1'}>
                    {t('common.forms.labels.no')}
                  </Typography>
                  <Switch
                    onChange={(e) => {
                      handleInputChange(
                        item.id!,
                        e.target.checked ? YES_ANSWER : NO_ANSWER,
                      );
                    }}
                    color="primary"
                  />
                  <Typography variant={'body1'}>
                    {t('common.forms.labels.yes')}
                  </Typography>
                </Box>
              ) : item.type === CUSTOM ? (
                <ClassicSelect
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                    marginTop: '10px',
                    height: '35px',
                  }}
                  value={
                    questionResponses.find((el) => el.questionId === item.id)
                      ?.answer || item.answers[0]
                  }
                  onChange={(e) =>
                    handleInputChange(item.id!, e.target.value as string)
                  }
                >
                  {item.answers.map((answer) => (
                    <MenuItem
                      key={answer.id}
                      value={answer.answer}
                      sx={{
                        whiteSpace: 'break-spaces',
                        maxWidth: '300px',
                      }}
                    >
                      {answer.answer}
                    </MenuItem>
                  ))}
                </ClassicSelect>
              ) : (
                <ClassicInput
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                    marginTop: '10px',
                  }}
                  label={t('common.forms.labels.answer')}
                  value={item.answers[0]}
                  onChange={(e) =>
                    handleInputChange(item.id!, e.target.value as string)
                  }
                  margin="normal"
                />
              )}
            </Grid>
            {touchedFields[item.id] && errors[item.id] && (
              <Typography
                variant="caption"
                sx={{
                  color: COLORS.errorColor,
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {errors[item.id]}
              </Typography>
            )}
          </Grid>
        ))}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <OutlineButton sx={{ width: '10rem' }} onClick={onCancelClick}>
            {t('common.button.back-btn')}
          </OutlineButton>

          <span
            onMouseOver={() =>
              questions.forEach((el) => {
                setTouchedFields((prev) => ({
                  ...prev,
                  [el.id]: true,
                }));
              })
            }
          >
            <ClassicButton
              disabled={!isValid()}
              sx={{
                width: '10rem',
                ml: 3,
              }}
              onClick={() => onButtonClick(questionResponses)}
            >
              {t(`common.button.next-btn`)}
            </ClassicButton>
          </span>
        </Box>
      </CardContent>
    </>
  );
}
