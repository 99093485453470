import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IPermissionRequest } from '../../providers/PermissionProvider';
import { useEffect, useState } from 'react';
import usePermissions from '../../hooks/usePermissions';
import { OPERATIONS } from '../../utils/constants';
import { useOperation } from '../../providers/OperationProvider';

type Props = {
  userHash: string;
  taxId?: string;
  open: boolean;
  currentPermissions: string[];
  selectedPermissions: string[];
  onClose: () => void;
  handlePermissions: (
    event: React.ChangeEvent<{}>,
    value: readonly { label: string; value: string }[],
  ) => void;
  setDataRefresh: (value: number) => void;
};

export const PermissionsDialog = (props: Props) => {
  const {
    userHash,
    taxId,
    currentPermissions,
    selectedPermissions,
    open,
    onClose,
    handlePermissions,
    setDataRefresh,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availablePermissions, setAvailablePermissions] = useState<string[]>(
    [],
  );
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { addPermissions, removePermissions, getPermissionFlags } =
    usePermissions();

  useEffect(() => {
    const fetchData = async () => {
      const flags = await getPermissionFlags();
      setAvailablePermissions(
        flags.filter((el) => el.startsWith('ENTERPRISE_')),
      );
    };

    fetchData();
  }, []);

  const onConfirmClick = async () => {
    try {
      setIsLoading(true);
      const added = selectedPermissions.filter(
        (flag: string) => !currentPermissions.includes(flag),
      );
      if (added.length > 0) {
        const permsToAdd: IPermissionRequest = {
          userHash,
          taxId: taxId ?? '',
          permissions: added,
        };
        await addPermissions(permsToAdd);
      }

      const removed = currentPermissions.filter(
        (flag: string) => !selectedPermissions.includes(flag),
      );
      if (removed.length > 0) {
        const permsToRemoved: IPermissionRequest = {
          userHash,
          taxId: taxId ?? '',
          permissions: removed,
        };
        await removePermissions(permsToRemoved);
      }

      setDataRefresh(Date.now());
      onClose();
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.permissions-modified'),
      });
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{t('common.forms.labels.permissions')}</DialogTitle>
      <DialogContent sx={{ width: '600px' }}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '50px 150px',
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Autocomplete
            multiple
            id="multiple-limit-tags"
            options={availablePermissions
              .filter((el) => !selectedPermissions.includes(el))
              .map((permission) => ({
                label: permission,
                value: permission,
              }))}
            value={selectedPermissions.map((label) => ({
              label,
              value: label,
            }))}
            onChange={handlePermissions}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('common.forms.labels.permissions')}
                placeholder="..."
              />
            )}
            sx={{ width: '100%' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose}>
          {t('common.button.cancel-btn')}
        </Button>
        <Button disabled={isLoading} onClick={onConfirmClick}>
          {t('common.button.confirm-btn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
