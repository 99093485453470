import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { OutlineButton } from '../../../layouts/styled/buttons';
import { useAuth } from '../../../providers/AuthProvider';

export default function GoogleLoginForm({ disabled }: { disabled: boolean }) {
  const authProvider = useAuth();
  const [googleUrl, setGoogleUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchGoogleUrl = async () => {
      const body = await authProvider.loginGoogle();
      setGoogleUrl(body);
    };

    fetchGoogleUrl();
  }, []);

  const handleClick = () => {
    if (googleUrl) {
      window.open(googleUrl, '_self');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <OutlineButton
        variant="outlined"
        onClick={handleClick}
        startIcon={<GoogleIcon />}
        disabled={disabled}
      >
        Google
      </OutlineButton>
    </Box>
  );
}
