import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer';
import PDFStyle from '../../../../layouts/styled/pdf';
import moment from 'moment';
import {
  IConsent,
  IOperationDetails,
} from '../../../../interfaces/consents.interface';
import { PATIENT_DECLARATION } from '../../../../constants/common';
import { IBasicUser } from '../../../../interfaces/profile.interface';
import { useTranslation } from 'react-i18next';
import { getBlockExplorer } from '../../../../utils/getLink';

interface Props {
  consent: IConsent;
  signature?: string;
  user?: IBasicUser;
  operations: IOperationDetails[];
  attachments: string[];
}

export default function ConsentPrint({
  consent,
  signature,
  user,
  operations,
  attachments,
}: Props) {
  const footerRowHeight = 25;
  const { t } = useTranslation();
  return (
    <Document>
      <Page
        size="A4"
        style={{
          ...PDFStyle.page,
          paddingBottom: footerRowHeight,
        }}
      >
        <View style={PDFStyle.userDataSection} wrap>
          <View style={PDFStyle.userDataLine}>
            <Text style={PDFStyle.userDataTitle}>
              {t('common.prints.fullName')}:
            </Text>
            <Text style={PDFStyle.userDataValue}>
              {user ? user.firstName || '-' : '....................'}{' '}
              {user ? user.secondName || '-' : '....................'}
            </Text>
          </View>
          <View style={PDFStyle.userDataLine}>
            <Text style={PDFStyle.userDataTitle}>
              {t('common.prints.personalId')}:
            </Text>
            <Text style={PDFStyle.userDataValue}>
              {user ? user.personalId || '-' : '....................'}
            </Text>
          </View>
        </View>

        <View style={PDFStyle.consentsSection}>
          <Text style={PDFStyle.title}>{consent.title}</Text>
          <Text style={PDFStyle.consentContentText}>{consent.content}</Text>
        </View>

        <View style={PDFStyle.signatureSection} wrap={false}>
          <View style={PDFStyle.signatureContainer}>
            {operations.length > 0 && (
              <Text style={PDFStyle.consentStatus}>
                {t('common.prints.consentStatus')}{' '}
                {operations[0].isSigned
                  ? t('common.prints.signed')
                  : t('common.prints.withdrawn')}
              </Text>
            )}
            <Text style={PDFStyle.signatureLabel}>
              {operations.length > 0 ? (
                signature ? (
                  <Image src={signature} style={PDFStyle.signatureImage} />
                ) : consent.signatureType === PATIENT_DECLARATION &&
                  operations[0].isSigned ? (
                  <Text style={PDFStyle.dataField}>
                    {t('common.prints.systemInfo')} Data Lake System.
                  </Text>
                ) : (
                  <></>
                )
              ) : (
                '....................'
              )}
            </Text>
            {operations.length > 0 && (
              <Text style={PDFStyle.dataField}>
                <Text style={PDFStyle.dataField}>
                  {t('common.prints.operationInfo')}{' '}
                  <Link
                    src={`${getBlockExplorer(operations[0].timestamp)}${
                      operations[0].txnHash
                    }`}
                  >
                    {t('common.prints.link')}
                  </Link>
                </Text>
              </Text>
            )}
            <Text style={PDFStyle.dataField}>
              {operations.length > 0
                ? moment
                    .unix(operations[0].timestamp)
                    .format('DD-MM-YYYY HH:mm:ss')
                : '....................'}
            </Text>
          </View>
        </View>

        <View style={PDFStyle.footer} fixed>
          <View style={PDFStyle.footerRow}>
            <Text style={PDFStyle.footerText}>
              {consent.title}, {t('common.prints.version')}: {consent.version},
              {t('common.prints.fromDay')}{' '}
              {moment.unix(consent.createdAt).format('DD-MM-YYYY HH:mm:ss')}
            </Text>
          </View>
        </View>
      </Page>
      <Page
        size="A4"
        style={{
          ...PDFStyle.page,
          paddingBottom: footerRowHeight,
        }}
      >
        <View>
          <View>
            <Text style={PDFStyle.consentTitle}>
              {t('common.prints.informationObligation')} - "{consent.title}"
            </Text>
            <Text style={PDFStyle.consentContentText}>
              {consent.informationObligation}
            </Text>
          </View>
        </View>

        <View>
          <View>
            <Text style={PDFStyle.consentTitle}>
              {t('common.prints.attachments')} - "{consent.title}"
            </Text>
            <Text style={PDFStyle.consentContentText}>
              {attachments.map((attachment, index) => (
                <Text style={PDFStyle.link} key={index}>
                  {attachment}
                </Text>
              ))}
            </Text>
          </View>
        </View>

        <View style={PDFStyle.footer} fixed>
          <View style={PDFStyle.footerRow}>
            <Text style={PDFStyle.footerText}>
              {consent.title}, {t('common.prints.version')}: {consent.version},{' '}
              {t('common.prints.fromDay')}{' '}
              {moment.unix(consent.createdAt).format('DD-MM-YYYY HH:mm:ss')}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
