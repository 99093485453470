import ElementItem from '../../../../components/items/ElementItem';
import {
  ClassicButton,
  OutlineButton,
  UnderlinedButton,
} from '../../../../layouts/styled/buttons';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTE } from '../../../../utils/route';
import PermissionGuard from '../../../../guards/PermissionGuard';
import { useEnterprise } from '../../../../providers/EnterpriseProvider';
import { IProject } from '../../../../interfaces/projects.interface';
import { Box } from '@mui/material';
import { OPERATIONS } from '../../../../utils/constants';
import { useOperation } from '../../../../providers/OperationProvider';
import useProjects from '../../../../hooks/useProjects';
import useProfile from '../../../../hooks/useProfile';
import { SUPER_ADMIN_USER_HASH } from '../../../../constants/common';
import { CopyAll } from '@mui/icons-material';
import QRCodeButton from '../../../../components/actions/DisplayQRButton';

interface Props {
  project: IProject;
  setRefresh: (value: number) => void;
  onItemClick: (value: IProject) => void;
}

export default function ProjectItem({
  project,
  setRefresh,
  onItemClick,
}: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { likeProject, dislikeProject } = useProjects();
  const navigate = useNavigate();
  const enterpriseProvider = useEnterprise();
  const profileProvider = useProfile();

  const selectedEnterprise = enterpriseProvider.enterprises.data.find(
    (item) => {
      return item.taxId === project.taxId;
    },
  );

  const onLikeClick = async () => {
    try {
      if (project.isLiked) {
        await dislikeProject(project.projectHash);
      } else {
        await likeProject(project.projectHash);
      }
      setRefresh(Date.now());
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: project.isLiked
          ? t('pages.operations.messages.project-disliked')
          : t('pages.operations.messages.project-liked'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  return (
    <>
      <ElementItem
        key={project.id}
        owner={selectedEnterprise?.name}
        title={project.title}
        description={project.description}
        chip={project.tags && project.tags.length > 0 ? [...project.tags] : []}
        enterpriseLogo={selectedEnterprise?.logoUrl}
        gradient={project.gradient}
        children={
          <Box
            sx={{
              height: '225px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {project.activated && (
              <>
                <PermissionGuard
                  permissions={['ENTERPRISE_ADD_USER_TO_PROJECT']}
                  taxId={project.taxId}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ClassicButton
                      onClick={() =>
                        navigate({
                          pathname: ROUTE.projectFlowPage.add,
                          search: createSearchParams({
                            projectHash: project.projectHash,
                          }).toString(),
                        })
                      }
                    >
                      {t('common.button.appointment-btn')}
                    </ClassicButton>
                    {project.accessKey && (
                      <QRCodeButton
                        link={`${process.env.REACT_APP_ENV}${
                          ROUTE.projectFlowPage.join
                        }?projectHash=${project.projectHash}&referral=${
                          profileProvider.currentProfile?.userHash ||
                          SUPER_ADMIN_USER_HASH
                        }&accessKey=${project.accessKey}`}
                        targetElementId={`project-${project.id}`}
                      />
                    )}
                  </div>
                </PermissionGuard>
                <PermissionGuard
                  permissions={['ENTERPRISE_INVITE_USER_TO_PROJECT']}
                  taxId={project.taxId}
                >
                  <ClassicButton
                    onClick={() =>
                      navigate(
                        ROUTE.dashboardPage.projects.inviteParticipant.replace(
                          ':projectHash',
                          project.projectHash,
                        ),
                        {
                          state: {
                            previousPath: ROUTE.dashboardPage.projects.root,
                            project: project,
                          },
                        },
                      )
                    }
                  >
                    {t('common.button.online-btn')}
                  </ClassicButton>
                </PermissionGuard>
                <PermissionGuard
                  permissions={['ENTERPRISE_INVITE_USER_TO_PROJECT']}
                  taxId={project.taxId}
                >
                  {project.accessKey && (
                    <OutlineButton
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_ENV}${
                            ROUTE.projectFlowPage.join
                          }?projectHash=${project.projectHash}&referral=${
                            profileProvider.currentProfile?.userHash ||
                            SUPER_ADMIN_USER_HASH
                          }&accessKey=${project.accessKey}`,
                        );
                        setOperation({
                          severity: OPERATIONS.SUCCESS,
                          message: t('pages.operations.messages.link-copied'),
                        });
                      }}
                    >
                      <CopyAll /> {t('common.button.copy-link-btn')}
                    </OutlineButton>
                  )}
                </PermissionGuard>
              </>
            )}
            <UnderlinedButton
              sx={{ paddingBottom: '10px' }}
              onClick={() => onItemClick(project)}
            >
              {t('common.button.more-info-btn')}
            </UnderlinedButton>
          </Box>
        }
        canBeLiked={true}
        onLikeClick={onLikeClick}
        isLiked={project.isLiked}
      />
    </>
  );
}
