import api from '../api';
import { useAuth } from '../providers/AuthProvider';
import { IGusData } from '../interfaces/gus.interface';

export type TxnStatus = 0 | 1 | 2;

export interface UtilsContextType {
  getGUS: (taxId: string) => Promise<IGusData | null>;
  getTxnStatus: (txnHash: string) => Promise<TxnStatus>;
}

const useUtils = (): UtilsContextType => {
  const auth = useAuth();

  const getGUS = async (taxId: string): Promise<IGusData> => {
    try {
      const response = await api.get<IGusData>(`/gus?nip=${taxId}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return { ...response.data, taxId };
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getTxnStatus = async (txnHash: string): Promise<TxnStatus> => {
    try {
      const response = await api.get(`/txn?txnHash=${txnHash}`, {
        headers: {
          Authorization: `Bearer ${auth.projectToken || auth.getToken()}`,
        },
      });
      return response.data.status ?? 0;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return { getGUS, getTxnStatus };
};

export default useUtils;
