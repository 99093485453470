import { IconButton, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { COLORS } from '../utils/constants';

interface Props {
  tooltip: string;
}

export default function Annotation({ tooltip }: Props) {
  return (
    <Tooltip title={tooltip} placement="top">
      <IconButton>
        <HelpOutlineIcon style={{ color: `${COLORS.primaryColor}` }} />
      </IconButton>
    </Tooltip>
  );
}
