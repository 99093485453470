import api from '../api';
import { useAuth } from '../providers/AuthProvider';
import {
  IPermissionFlagResponse,
  IPermissionRequest,
  IPermissionGroup
} from '../interfaces/permissions.interface';

interface PermissionContextType {
  getPermissionGroups: () => Promise<IPermissionGroup[]>;
  getPermissionFlags: (
    pageIndex?: number,
    pageSize?: number,
  ) => Promise<string[]>;
  addPermissions: (item: IPermissionRequest) => Promise<void>;
  removePermissions: (item: IPermissionRequest) => Promise<void>;
}

const usePermissions = (): PermissionContextType => {
  const auth = useAuth();
  const getPermissionGroups = async (): Promise<IPermissionGroup[]> => {
    try {
      const response = await api.get('/permissions/groups', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const addPermissions = async (item: IPermissionRequest) => {
    try {
      await api.put('/permissions/add', item, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const removePermissions = async (item: IPermissionRequest) => {
    try {
      await api.put('/permissions/remove', item, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getPermissionFlags = async (
    pageIndex: number = 0,
    pageSize: number = 1000,
  ): Promise<string[]> => {
    try {
      const response = await api.get<IPermissionFlagResponse>(
        `/permissions/flags`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
          params: {
            pageIndex,
            pageSize,
          },
        },
      );
      return response.data.data.map((el) => el.flag);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getPermissionGroups,
    addPermissions,
    removePermissions,
    getPermissionFlags,
  };
};

export default usePermissions;
