import React, { ReactNode, useEffect } from 'react';
import api from '../api';
import { useAuth } from './AuthProvider';
import { useOperation } from './OperationProvider';
import { OPERATIONS } from '../utils/constants';

interface PermissionContextType {
  getPermissions: () => Promise<IPermission[]>;
  permissions: IPermission[];
  hasFlag: (flag: string, taxId: string) => boolean;
}

export interface IPermission {
  flag: string;
  userHash: string;
  taxId: string;
}

export interface IPermissionRequest {
  userHash: string;
  taxId: string;
  permissions: string[];
}

let PermissionContext = React.createContext<PermissionContextType>(null!);

const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const [permissions, setPermissions] = React.useState<IPermission[]>([]);
  const { setOperation } = useOperation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPermissions();
      } catch (error) {
        console.error('Error:', error);
        setOperation({
          severity: OPERATIONS.ERROR,
          message: error.response.data.details,
        });
      }
    };

    if (!auth.isLoading) fetchData();
  }, [auth.isLoading]);

  const getPermissions = async (): Promise<IPermission[]> => {
    try {
      const response = await api.get('/permissions', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      const items = response.data;
      setPermissions(items);
      return items;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const hasFlag = (flag: string, taxId: string): boolean => {
    return permissions.some(
      (permission) => permission.flag === flag && permission.taxId === taxId,
    );
  };

  const value = {
    getPermissions,
    permissions,
    hasFlag,
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

const usePermission = () => {
  return React.useContext(PermissionContext);
};

export { PermissionProvider, PermissionContext, usePermission };
