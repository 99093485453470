import './default.css';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import { AppBar, CssBaseline, IconButton, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { RequiredProvider } from '../index';
import { layoutTheme } from './styled/layout';

export default function SimpleLayout() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleBackButtonClick = () => {
    const previousUrl = state && state.previousPath ? state.previousPath : '/';
    navigate(previousUrl);
  };

  return (
    <RequiredProvider>
      <ThemeProvider theme={layoutTheme}>
        <CssBaseline />
        <div className="App">
          <Helmet>
            <title>Data Lake Application</title>
            <meta name="description" content="Data Lake Application" />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
          </Helmet>

          <AppBar position="static">
            <Toolbar
              disableGutters
              style={{ justifyContent: 'space-between', margin: 10 }}
            >
              <a href="/">
                <img
                  src={'/logo.svg'}
                  alt="logo"
                  style={{ width: '15rem', marginLeft: '1rem' }}
                />
              </a>
              <IconButton onClick={handleBackButtonClick} color="inherit">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <main style={{ background: '#FFF', minHeight: '95vh' }}>
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </RequiredProvider>
  );
}
