import { ReactNode } from 'react';
import { Box, Card, Chip, Grid, IconButton, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

interface Props {
  version?: number;
  title: string;
  owner?: string;
  description?: string;
  chip: string[];
  enterpriseLogo?: string;
  gradient?: string;
  children?: ReactNode;
  canBeLiked?: boolean;
  isLiked?: boolean;
  onLikeClick?: () => void;
}

export default function ElementItem({
  version,
  title,
  owner,
  description,
  chip,
  enterpriseLogo,
  gradient,
  children,
  canBeLiked,
  isLiked,
  onLikeClick,
}: Props) {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card elevation={3} sx={{ p: 2, minHeight: 250 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItem: 'center',
            justifyContent: 'center',
            height: canBeLiked ? '100px' : '80px',
          }}
        >
          {canBeLiked && (
            <IconButton
              onClick={onLikeClick}
              style={{ position: 'absolute', top: 5, right: 5 }}
            >
              {isLiked ? (
                <FavoriteIcon color="error" />
              ) : (
                <FavoriteBorderIcon />
              )}
            </IconButton>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: version ? 'space-between' : 'center',
              marginTop: canBeLiked ? '20px' : '0',
            }}
          >
            {owner && <Chip sx={{ maxWidth: '80%' }} label={owner} />}{' '}
            {version && <Chip label={`v.${version}`} />}
          </Box>

          {chip.length > 0 && (
            <Box
              sx={{
                padding: '5px 0',
                whiteSpace: 'nowrap',
                overflow: 'scroll',
              }}
            >
              {chip.map((label, index) => (
                <Chip key={index} label={label} />
              ))}
            </Box>
          )}
        </Box>
        <Typography
          variant="subtitle2"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>

        <div style={{ position: 'relative', width: '100%', margin: '3px 0' }}>
          <div
            style={{
              width: '100%',
              height: '100px',
              background: gradient,
              opacity: 0.75,
              borderRadius: '10px',
              margin: '3px 0',
            }}
          >
            <img
              src={'/pattern.png'}
              alt="Pattern"
              style={{
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
                opacity: 0.5,
              }}
            />
          </div>
          {enterpriseLogo && (
            <img
              src={enterpriseLogo}
              alt="Enterprise Logo"
              style={{
                display: 'block',
                position: 'absolute',
                bottom: '10%',
                left: '5%',
                maxWidth: '100%',
                maxHeight: '50%',
                objectFit: 'contain',
              }}
            />
          )}
        </div>
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'brake-space',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            height: '4rem',
          }}
        >
          {description}
        </Typography>
        {children}
      </Card>
    </Grid>
  );
}
