// BetaAccessBanner.tsx
import React from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../utils/constants';

const dappLink = process.env.REACT_APP_DAPP_LINK || 'https://dapp.data-lake.co';

interface BetaAccessBannerProps {
  isConnected: boolean;
  approved: boolean;
}
const BetaAccessBanner: React.FC<BetaAccessBannerProps> = ({
  isConnected,
  approved,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Container component="div" maxWidth="xs" sx={{ margin: 0 }}>
        <Box
          sx={{
            backgroundColor: isConnected
              ? approved
                ? 'rgba(144, 238, 144, 0.75)'
                : 'rgba(255, 0, 0, 0.75)'
              : COLORS.accentColor,
            color: 'white',
            padding: '10px',
            textAlign: 'center',
            borderRadius: '4px',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">
            {isConnected
              ? approved
                ? t('common.beta-access.success-title')
                : t('common.beta-access.failed-title')
              : t('common.beta-access.title')}
          </Typography>
          <Typography>
            {isConnected
              ? approved
                ? t('common.beta-access.success-message')
                : t('common.beta-access.failed-message')
              : t('common.beta-access.message')}
          </Typography>
          {(!isConnected || !approved) && (
            <Typography>
              <Link href={dappLink} underline="always">
                {t('common.beta-access.buy')}
              </Link>
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default BetaAccessBanner;
