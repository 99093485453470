import { useWeb3Modal } from '@web3modal/wagmi/react';
import { ClassicButton } from '../../layouts/styled/buttons';
import { useAccount, useSignMessage, useDisconnect, useBalance } from 'wagmi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SIGNING_MESSAGE } from '../../utils/constants';

interface ConnectButtonProps {
  onSignature: (data: { signature: string; address: string }) => void;
}

export const ConnectButton: React.FC<ConnectButtonProps> = ({
  onSignature,
}) => {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const { data, isSuccess, signMessage, reset } = useSignMessage({
    message: SIGNING_MESSAGE,
  });

  useEffect(() => {
    if (isSuccess)
      onSignature({ signature: data as string, address: address as string });
  }, [data]);

  useEffect(() => {
    if (!address) {
      setHover(false);
      reset();
    }
  }, [address]);

  return address ? (
    isSuccess ? (
      <ClassicButton
        fullWidth
        sx={{ mt: 0 }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => disconnect()}
      >
        {!hover ? address.slice(0, 16) : t('common.wallet.disconnect')}...
      </ClassicButton>
    ) : (
      <ClassicButton fullWidth sx={{ mt: 0 }} onClick={() => signMessage()}>
        {t('common.wallet.verify')}
      </ClassicButton>
    )
  ) : (
    <ClassicButton fullWidth sx={{ mt: 0 }} onClick={() => open()}>
      {t('common.wallet.connect')}
    </ClassicButton>
  );
};
