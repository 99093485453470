import Chip from '@mui/material/Chip';
import { styled, Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';

interface Status {
  status: number;
  label: string;
}

type Props = {
  status: Status;
};

const StatusChip = ({ status }: Props) => {
  const theme = createTheme();

  const getChipColor = (status: number, theme: Theme) => {
    return status === 2
      ? theme.palette.success.main
      : status === 0
      ? theme.palette.info.main
      : status === 1
      ? theme.palette.error.main
      : theme.palette.grey[500];
  };

  const StyledChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.getContrastText(getChipColor(status.status, theme)),
    backgroundColor: getChipColor(status.status, theme),
    marginLeft: '8px',
    marginTop: '16px',
    height: '40px',
    fontSize: '16px',
  }));

  return <StyledChip label={`${status.label}`} variant="outlined" />;
};

export default StatusChip;
