export const validatePolandVat = (vat?: string): boolean => {
  if (!vat || vat.length < 8) return false;
  const multipliers = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let total = 0;

  // Extract the next digit and multiply by the counter.
  for (let i = 0; i < 9; i++) {
    total += Number(vat.charAt(i)) * multipliers[i];
  }

  // Establish check digits by taking modulus 11.
  total = total % 11;
  if (total > 9) {
    total = 0;
  }

  // Compare it with the last character of the VAT number. If it's the same, then it's valid.
  const expect = Number(vat.slice(9, 10));
  return total === expect;
};

export const shortenName = (name: String, maxLength = 20) => {
  return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone: string) => {
  const phoneRegex = /^[+][0-9]{3}[0-9]{3}[0-9]{4,6}$/;
  return phoneRegex.test(phone);
};

export const compareArrays = (arr1: any[], arr2: any[]) => {
  //isEqual from Lodash? better handles nulls/undefineds
  return arr1.toString() === arr2.toString();
};
