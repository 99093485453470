import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './i18n';
import { RouterProvider } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { router } from './routes/router';
import { AuthProvider } from './providers/AuthProvider';
import { EnterpriseProvider } from './providers/EnterpriseProvider';
import { PermissionProvider } from './providers/PermissionProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { OperationProvider } from './providers/OperationProvider';
import OperationPopup from './components/utils/OperationPopup';
import { ChatProvider } from './providers/ChatProvider';
import { Web3ModalProvider } from './providers/Web3ModalProvider';

interface Props {
  children: ReactNode;
}
const Providers = ({ children }: Props) => (
  <Web3ModalProvider>
    <CookiesProvider>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ChatProvider>{children}</ChatProvider>
        </LocalizationProvider>
      </AuthProvider>
    </CookiesProvider>
  </Web3ModalProvider>
);

export const RequiredProvider = ({ children }: Props) => (
  <EnterpriseProvider>
    <PermissionProvider>{children}</PermissionProvider>
  </EnterpriseProvider>
);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
const app = (
  <OperationProvider>
    <Providers>
      <RouterProvider router={router} />
      <OperationPopup />
    </Providers>
  </OperationProvider>
);

root.render(app);
