import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClassicButton,
  ClassicInput,
  ClassicSelect,
} from '../../../layouts/styled/buttons';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import LoadingController from '../../../components/utils/LoadingController';
import EditIcon from '@mui/icons-material/Edit';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { usePermission } from '../../../providers/PermissionProvider';
import PermissionGuard from '../../../guards/PermissionGuard';
import { useOperation } from '../../../providers/OperationProvider';
import useTags from '../../../hooks/useTags';
import {
  COLORS,
  MAX_ITEMS_PER_PAGE,
  OPERATIONS,
} from '../../../utils/constants';
import {
  IConsent,
  IConsentAttachment,
  IOperationDetails,
} from '../../../interfaces/consents.interface';
import useConsents from '../../../hooks/useConsents';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ConsentPrint from './prints/ConsentPrint';
import PrintIcon from '@mui/icons-material/Print';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import StatusChip from '../../../components/chips/StatusChip';
import ConsentActivityTable from './ConsentActivityTable';
import {
  FILE_SIZE_UPLOAD_LIMIT,
  FILE_TYPE_LIMIT,
  MOBILE_DEVICE_SIGNATURE,
  SIGNATURE_TYPES,
  TRANSACTION_CHECK_TIMEOUT,
} from '../../../constants/common';
import { IBasicUser } from '../../../interfaces/profile.interface';
import CSVExportButton from '../../../components/builders/CSVExportButton';
import Annotation from '../../../components/Annotation';
import CloseIcon from '@mui/icons-material/Close';
import useFiles from '../../../hooks/useFiles';
import DeleteIcon from '@mui/icons-material/Delete';
import { CgFileDocument } from 'react-icons/cg';
import useUtils from '../../../hooks/useUtils';
import TransactionStatus from '../../../components/TransactionStatus';
import { IEnterprise } from '../../../interfaces/enterprise.interface';

const initialConsent: IConsent = {
  id: 0,
  taxId: '',
  consentHash: '',
  title: '',
  description: '',
  draft: true,
  activated: false,
  tags: [],
  createdAt: 0,
  gradient: 'default',
  signatureType: '',
  informationObligation: '',
  content: '',
  version: 1,
  totalSigns: 0,
  activeSigns: 0,
  prevVersion: '',
  nextVersion: '',
  timestamp: 0,
  attachments: [],
};

const initialError = {
  title: '',
  description: '',
  content: '',
  signatureType: '',
};

const csvHeaders = [
  { label: 'Consent Hash', key: 'consentHash' },
  { label: 'User Hash', key: 'userHash' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Personal Id', key: 'personalId' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Second Name', key: 'secondName' },
  { label: 'Verification Level', key: 'verificationLevel' },
  { label: 'Consent Title', key: 'consentTitle' },
  { label: 'Status', key: 'status' },
  { label: 'Txn Hash', key: 'txnHash' },
  { label: 'Notes', key: 'notes' },
  { label: 'Timestamp', key: 'timestamp' },
];

type Props = {
  consent?: IConsent;
  open: boolean;
  handleClose: () => void;
  setRefresh: (value: number) => void;
};

export default function ConsentDialog({
  consent,
  open,
  handleClose,
  setRefresh,
}: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getTags } = useTags();
  const {
    createDraftConsent,
    deleteDraftConsent,
    activeConsent,
    deactivateConsent,
    modifyConsent,
    getConsentVersion,
    getConsentsByHashes,
    withdrawConsents,
    signConsents,
    getConsentActivity,
    getConsentHistory,
    getConsentSignature,
  } = useConsents();
  const { getTxnStatus } = useUtils();

  const permissionsProvider = usePermission();
  const enterpriseProvider = useEnterprise();
  const filesProvider = useFiles();
  const [operations, setOperations] = useState<IOperationDetails[]>([]);
  const [csvData, setCsvData] = useState<Record<string, string>[]>([]);
  const [isCsvDataLoading, setIsCsvDataLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IBasicUser>();
  const [signature, setSignature] = useState<string>();
  const [isConsentActivityLoading, setIsConsentActivityLoading] =
    useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [originalTags, setOriginalTags] = useState<string[]>([]);
  const [originalAttachments, setOriginalAttachments] = useState<
    IConsentAttachment[]
  >([]);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [txnHash, setTxnHash] = useState<string>();
  const [isSignatureLoading, setIsSignatureLoading] = useState(false);
  const [areConsentVersionsLoading, setAreConsentVersionsLoading] =
    useState(false);
  const [areTagsLoading, setAreTagsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileError, setFileError] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [consentVersions, setConsentVersions] = useState<IConsent[]>([]);
  const [formData, setFormData] = useState<IConsent>(initialConsent);
  const [selectedEnterprise, setSelectedEnterprise] = useState<string>();
  const [availableEnterprises, setAvailableEnterprises] = useState<
    IEnterprise[]
  >([]);
  const [formErrors, setFormErrors] =
    useState<Record<string, string>>(initialError);
  const [activityRefresh, setActivityRefresh] = useState<number>(0);

  const handleFileChange = async (e: any) => {
    if (fileError) {
      setFileError('');
    }

    if (e.target.files[0].size > FILE_SIZE_UPLOAD_LIMIT) {
      setFileError(`${t('common.forms.errors.file-size')}!`);
      return;
    }
    if (!FILE_TYPE_LIMIT.includes(e.target.files[0].type)) {
      setFileError(`${t('common.forms.errors.file-type')}!`);
      return;
    }

    setIsFileUploading(true);
    try {
      const file = e.target.files[0];
      const uploadCache = await filesProvider.getUploadUrl(
        formData.taxId,
        file.name,
      );
      await filesProvider.uploadToAWS(uploadCache.url, file);
      setFormData((prevData) => ({
        ...prevData,
        attachments: [
          ...(prevData.attachments || []),
          {
            name: uploadCache.fileName,
            displayName: file.name,
            size: file.size,
          },
        ],
      }));
    } catch (e) {
      console.error('Error uploading file:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsFileUploading(false);
    }
  };

  const handleRemoveFile = async (index: number) => {
    const newFiles = formData.attachments.filter((_, i) => i !== index);
    await filesProvider.deleteFile(
      formData.taxId,
      formData.attachments[index].name,
    );
    setFormData((prevData) => ({
      ...prevData,
      attachments: newFiles,
    }));
  };

  const handleOpenAttachment = async (index: number) => {
    const file = formData.attachments[index];
    const downloadCache = await filesProvider.getReadUrl(
      formData.taxId,
      file.name,
    );
    window.open(downloadCache, '_blank');
  };

  useEffect(() => {
    consent ? setFormData(consent) : setEditMode(true);
  }, [consent]);

  useEffect(() => {
    if (formData.consentHash) {
      if (txnHash === '') {
        updateConsentData(formData.consentHash);
      } else if (!txnHash) {
        if (!formData.draft) {
          fetchConsentVersions(formData.consentHash, formData.taxId);
          fetchConsentActivity(formData.consentHash);
        }
      } else {
        checkTransaction(txnHash);
      }
    }
  }, [formData.consentHash, txnHash]);

  useEffect(() => {
    if (formData.consentHash) {
      fetchConsentActivity(formData.consentHash);
    }
  }, [activityRefresh]);

  useEffect(() => {
    if (editMode) {
      const availableEnterprises = enterpriseProvider.enterprises.data.filter(
        (ent) =>
          permissionsProvider.permissions.some(
            (permission) =>
              permission.taxId === ent.taxId &&
              permission.flag === 'ENTERPRISE_CREATE_CONSENT_DRAFT',
          ),
      );
      setAvailableEnterprises(availableEnterprises);
      if (availableEnterprises.length > 0 && !formData.taxId) {
        formData.taxId = availableEnterprises[0].taxId;
      }
    }
  }, [editMode]);

  useEffect(() => {
    if (formData.taxId) {
      if (editMode) {
        fetchTags(formData.taxId);
      } else {
        if (!selectedEnterprise || selectedEnterprise.length === 0) {
          const enterprise = enterpriseProvider.enterprises.data.find(
            (item) => item.taxId === formData.taxId,
          );
          setSelectedEnterprise(enterprise ? enterprise.name : '');
        }
      }
    }
  }, [formData.taxId, editMode]);

  useEffect(() => {
    if (operations.length > 0) {
      if (
        operations[0].isSigned &&
        consent &&
        consent.signatureType === MOBILE_DEVICE_SIGNATURE
      )
        fetchConsentSignature(
          operations[0].consentHash,
          operations[0].timestamp,
        );
    }
  }, [operations]);

  useEffect(() => {
    if (formData.attachments?.length > 0) {
      fetchAttachments(
        formData.taxId,
        formData.attachments.map((a) => a.name),
      );
    }
  }, [formData.attachments]);

  const checkTransaction = async (txnHash: string) => {
    try {
      const status = await getTxnStatus(txnHash);
      status === 2
        ? setTimeout(() => {
            checkTransaction(txnHash);
          }, TRANSACTION_CHECK_TIMEOUT)
        : setTxnHash('');
    } catch (e) {
      console.error('Cannot check transaction', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  const updateConsentData = async (consentHash: string): Promise<void> => {
    try {
      const consents = await getConsentsByHashes([consentHash]);
      if (consents.length > 0) {
        if (!consents[0].draft) {
          fetchConsentVersions(consentHash, consents[0].taxId);
          fetchConsentActivity(consentHash);
        }
        setFormData(consents[0]);
        setOriginalAttachments(consents[0].attachments);
        setOriginalTags(consents[0].tags);
      }
      setRefresh(Date.now());
    } catch (e) {
      console.error('Error fetching consent:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  const fetchConsentSignature = async (
    consentHash: string,
    timestamp: number,
  ) => {
    try {
      setIsSignatureLoading(true);
      setSignature(
        await getConsentSignature(consentHash, timestamp.toString()),
      );
    } catch (e) {
      console.error('Error fetching signature:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsSignatureLoading(false);
    }
  };

  const fetchAttachments = async (taxId: string, names: string[]) => {
    try {
      setIsSignatureLoading(true);
      setAttachments(
        await Promise.all(
          names.map(async (name) => {
            return await filesProvider.getReadUrl(taxId, name);
          }),
        ),
      );
    } catch (e) {
      console.error('Error fetching attachments:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsSignatureLoading(false);
    }
  };

  const fetchTags = async (taxId: string) => {
    try {
      setAreTagsLoading(true);
      const tags = (await getTags(taxId, { pageSize: MAX_ITEMS_PER_PAGE }))
        .data;
      setTags(tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreTagsLoading(false);
    }
  };

  const fetchConsentActivity = async (consentHash: string) => {
    try {
      setIsConsentActivityLoading(true);
      const consentActivity = await getConsentActivity(consentHash);
      setUser(consentActivity.user);
      setOperations(consentActivity.operations);
    } catch (error) {
      console.error('Error fetching:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsConsentActivityLoading(false);
    }
  };

  const fetchConsentVersions = async (consentHash: string, taxId: string) => {
    try {
      setAreConsentVersionsLoading(true);
      if (permissionsProvider.hasFlag('ENTERPRISE_GET_CONSENT_VERSIONS', taxId))
        setConsentVersions(await getConsentVersion(consentHash));
    } catch (error) {
      console.error('Error fetching consent versions:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreConsentVersionsLoading(false);
    }
  };

  const setValidationErrors = () => {
    const errors: Record<string, string> = {};
    errors.title =
      formData.title === '' ? t('common.forms.errors.field-required') : '';
    errors.description =
      formData.description === ''
        ? t('common.forms.errors.field-required')
        : '';
    errors.content =
      formData.content === '' ? t('common.forms.errors.field-required') : '';
    errors.signatureType =
      formData.signatureType === ''
        ? t('common.forms.errors.field-required')
        : '';
    setFormErrors(errors);
  };

  const isFormValid = () =>
    formData.title !== '' &&
    formData.description !== '' &&
    formData.content !== '' &&
    formData.signatureType !== '';

  const handleSubmit = async () => {
    if (!editMode) {
      setEditMode(true);
      return;
    }
    if (isFormValid()) {
      formData.consentHash ? await editConsent() : await createConsent();
      setEditMode(false);
      setRefresh(Date.now());
    } else {
      setValidationErrors();
    }
  };

  const onVersionChange = async (hash: string) => {
    try {
      setIsLoading(true);
      const fetchedConsents = await getConsentsByHashes([hash]);
      setFormData(fetchedConsents[0] || consent || initialConsent);
      setIsLoading(false);
    } catch (e) {
      console.error('An error occurred:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  const createConsent = async () => {
    setIsLoading(true);
    try {
      const consentHash = await createDraftConsent(formData);
      setFormData((prevData) => ({
        ...prevData,
        draft: true,
        consentHash,
      }));
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.consent-created'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  const editConsent = async () => {
    setIsLoading(true);
    try {
      if (
        (consent &&
          (formData.title !== consent.title ||
            formData.description !== consent.description ||
            formData.informationObligation !== consent.informationObligation ||
            formData.content !== consent.content ||
            formData.signatureType !== consent.signatureType ||
            !formData.attachments.every((attachment: IConsentAttachment) =>
              originalAttachments.includes(attachment),
            ) ||
            !originalAttachments.every((attachment: IConsentAttachment) =>
              formData.attachments.includes(attachment),
            ) ||
            !formData.tags.every((tag: string) => originalTags.includes(tag)) ||
            !originalTags.every((tag: string) =>
              formData.tags.includes(tag),
            ))) ||
        !consent
      ) {
        const { txnHash, hash } = await modifyConsent(formData);
        setTxnHash(txnHash || '');
        handleInputChange('consentHash', hash);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleTagsChange = (
    event: React.ChangeEvent<{}>,
    value: readonly string[],
  ) => {
    formData.tags = Array.from(value);
  };

  const handleDelete = async () => {
    try {
      await deleteDraftConsent(formData.consentHash);
      setRefresh(Date.now());
      handleClose();
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.consent-deleted'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleActive = async () => {
    setIsLoading(true);
    let txnHash;
    try {
      if (formData.activated) {
        txnHash = await deactivateConsent(formData.consentHash);
        setFormData((prevData) => ({
          ...prevData,
          activated: false,
        }));
      } else {
        txnHash = await activeConsent(formData.consentHash);
        setFormData((prevData) => ({
          ...prevData,
          activated: true,
          draft: false,
        }));
      }
      setTxnHash(txnHash);
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onSignConsentClick = async () => {
    try {
      setIsLoading(true);
      const txnHash = await signConsents([formData.consentHash]);
      setTxnHash(txnHash);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onRevokeConsentClick = async () => {
    setIsDialogOpen(false);
    setIsLoading(true);
    try {
      const txnHash = await withdrawConsents([formData.consentHash]);
      setTxnHash(txnHash);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onCsvClick = async () => {
    setIsCsvDataLoading(true);
    try {
      const consentHistory = await getConsentHistory(formData.consentHash);
      const csvData = consentHistory.map((el) => ({
        consentHash: formData.consentHash,
        ...el.user,
        invitedBy: '',
        consentTitle: formData.title,
        status: el.operations[0].isSigned ? 'signed' : 'withdrawn',
        txnHash: el.operations[0].txnHash,
        notes: el.operations[0].notes,
        timestamp: moment
          .unix(el.operations[0].timestamp)
          .format('DD-MM-YYYY HH:mm:ss'),
      }));
      setCsvData(csvData);
      if (csvData.length === 0)
        setOperation({
          severity: OPERATIONS.ERROR,
          message: t('common.no-data'),
        });
    } catch (e) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsCsvDataLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '1100px',
          },
        },
      }}
    >
      {!isConsentActivityLoading && formData.consentHash.length > 0 && (
        <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
          <StatusChip
            status={
              operations.length > 0
                ? operations[0].isSigned
                  ? { label: t('common.tables.content.signed'), status: 2 }
                  : {
                      label: t('common.tables.content.withdrawn'),
                      status: 1,
                    }
                : { label: t('common.tables.content.notSigned'), status: 0 }
            }
          />
        </Box>
      )}
      <Box
        sx={{
          textAlign: 'center',
          p: 2.5,
          borderBottom: `1px solid ${COLORS.primaryColor}`,
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: '15px',
            right: '5px',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant={'h5'}>
          {t('pages.consents.title-editor')}
        </Typography>
      </Box>
      <DialogContent>
        <Grid
          container
          sx={{
            minWidth: { xs: '300px', md: '500px' },
            justifyContent: 'center',
          }}
        >
          {isLoading || txnHash ? (
            <>
              {txnHash ? (
                <TransactionStatus txnHash={txnHash} />
              ) : (
                <LoadingController />
              )}
            </>
          ) : (
            <Grid container spacing={2} padding={2}>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                textAlign="left"
                alignItems="center"
              >
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.forms.labels.enterprise')} :
                  </Typography>
                  {editMode && (
                    <Annotation
                      tooltip={`${t('common.annotations.consent-enterprise')}`}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  {editMode &&
                  !formData.createdAt &&
                  availableEnterprises.length > 0 ? (
                    <ClassicSelect
                      fullWidth
                      labelId="enterprise-label"
                      id="enterprise-select"
                      value={formData.taxId || availableEnterprises[0].taxId}
                      onChange={(e) =>
                        handleInputChange('taxId', e.target.value as string)
                      }
                    >
                      {availableEnterprises.map(
                        (item: IEnterprise, index: number) => (
                          <MenuItem key={index} value={item.taxId}>
                            {item.name}
                          </MenuItem>
                        ),
                      )}
                    </ClassicSelect>
                  ) : (
                    <Box sx={{ padding: '5px 0' }}>
                      <Chip key={0} label={selectedEnterprise} />
                    </Box>
                  )}
                </Grid>
                {!editMode && consentVersions.length > 0 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.tables.header.version')} :
                      </Typography>
                      <Annotation
                        tooltip={`${t('common.annotations.consent-version')}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      {!areConsentVersionsLoading ? (
                        <ClassicSelect
                          sx={{ width: '90px', height: '40px' }}
                          fullWidth
                          labelId="enterprise-label"
                          id="enterprise-select"
                          value={formData.consentHash}
                          onChange={(e) =>
                            onVersionChange(e.target.value as string)
                          }
                          disabled={formData.draft}
                        >
                          {consentVersions.map(
                            (item: IConsent, index: number) => (
                              <MenuItem key={index} value={item.consentHash}>
                                {`v.${item.version}`}
                              </MenuItem>
                            ),
                          )}
                        </ClassicSelect>
                      ) : (
                        <CircularProgress />
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.forms.labels.name')} :{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {editMode ? (
                    <ClassicInput
                      required
                      label={t('common.forms.labels.name')}
                      fullWidth
                      value={formData.title}
                      onChange={(e) =>
                        handleInputChange('title', e.target.value)
                      }
                      error={formErrors.title !== ''}
                      helperText={formErrors.title}
                    />
                  ) : (
                    <Typography variant={'body1'}>{formData.title}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.forms.labels.consent-description')} :{' '}
                  </Typography>
                  {editMode && (
                    <Annotation
                      tooltip={`${t('common.annotations.consent-description')}`}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  {editMode ? (
                    <ClassicInput
                      required
                      label={t('common.forms.labels.consent-description')}
                      multiline
                      rows={5}
                      fullWidth
                      value={formData.description}
                      onChange={(e) =>
                        handleInputChange('description', e.target.value)
                      }
                      error={formErrors.description !== ''}
                      helperText={formErrors.description}
                    />
                  ) : (
                    <Typography
                      variant={'body1'}
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {formData.description}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.tables.header.content')} :{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {editMode ? (
                    <ClassicInput
                      required
                      label={t('common.tables.header.content')}
                      multiline
                      rows={9}
                      fullWidth
                      value={formData.content}
                      onChange={(e) =>
                        handleInputChange('content', e.target.value)
                      }
                      error={formErrors.content !== ''}
                      helperText={formErrors.content}
                    />
                  ) : (
                    <Typography
                      variant={'body1'}
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {formData.content}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.tables.header.informationObligation')}:
                  </Typography>
                  {editMode && (
                    <Annotation
                      tooltip={`${t(
                        'common.annotations.consent-information-obligation',
                      )}`}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  {editMode ? (
                    <div>
                      <ClassicInput
                        label={t('common.tables.header.informationObligation')}
                        multiline
                        rows={9}
                        fullWidth
                        value={formData.informationObligation}
                        onChange={(e) =>
                          handleInputChange(
                            'informationObligation',
                            e.target.value,
                          )
                        }
                        error={false}
                        helperText={formErrors.informationObligation}
                      />
                    </div>
                  ) : (
                    <Typography
                      variant={'body1'}
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {formData.informationObligation}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.tables.header.attachments')}:
                  </Typography>
                  {editMode && (
                    <Annotation
                      tooltip={`${t('common.annotations.consent-attachments')}`}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  {editMode ? (
                    <>
                      <div
                        style={{
                          maxWidth: '100%',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          id="fileInput"
                          accept={FILE_TYPE_LIMIT.join(', ')}
                        />
                        <label htmlFor="fileInput">
                          <Button
                            variant="outlined"
                            component="span"
                            disabled={isFileUploading}
                            sx={{ width: '100%' }}
                          >
                            {t('common.button.upload-btn')}
                          </Button>
                        </label>
                      </div>
                      {formData.attachments?.map((file, index) => (
                        <ListItem
                          key={index}
                          style={{
                            border: `1px ${COLORS.accentColor} solid`,
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '5px',
                            marginTop: '5px',
                          }}
                        >
                          <CgFileDocument style={{ margin: '5px' }} />
                          <ListItemText
                            primary={file.displayName}
                            secondary={
                              file.size &&
                              `Size: ${(file.size / 1024 / 1024).toFixed(2)} MB`
                            }
                            sx={{ marginLeft: '5px' }}
                          />
                          <IconButton
                            onClick={() => handleRemoveFile(index)}
                            edge="end"
                            aria-label="delete"
                            sx={{
                              color: COLORS.errorColor,
                              marginRight: '5px',
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      ))}

                      {isFileUploading && (
                        <ListItem
                          key={'loading'}
                          style={{
                            border: `1px ${COLORS.accentColor} solid`,
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '5px',
                            marginTop: '5px',
                          }}
                        >
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: '5px' }}
                          />
                        </ListItem>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {fileError && (
                          <Typography
                            variant={'caption'}
                            sx={{ color: COLORS.errorColor }}
                          >
                            {fileError}
                          </Typography>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {formData.attachments?.map((file, index) => (
                        <Chip
                          key={index}
                          label={file.displayName}
                          onClick={() => handleOpenAttachment(index)}
                        />
                      ))}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.tables.header.signatureType')} :{' '}
                  </Typography>
                  {editMode && (
                    <Annotation
                      tooltip={`${t(
                        'common.annotations.consent-signature-type',
                      )}`}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  {editMode ? (
                    <ClassicSelect
                      fullWidth
                      labelId="enterprise-label"
                      id="enterprise-select"
                      value={formData.signatureType}
                      onChange={(e) =>
                        handleInputChange(
                          'signatureType',
                          e.target.value as string,
                        )
                      }
                    >
                      {SIGNATURE_TYPES.map((item: string, index: number) => (
                        <MenuItem key={index} value={item}>
                          {t(`common.forms.labels.${item}`)}
                        </MenuItem>
                      ))}
                    </ClassicSelect>
                  ) : (
                    <Box sx={{ padding: '5px 0' }}>
                      <Chip
                        key={0}
                        label={t(
                          `common.forms.labels.${formData.signatureType}`,
                        )}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} md={4} display="flex" justifyContent="left">
                  <Typography variant={'h5'}>
                    {t('common.forms.labels.tag')} :{' '}
                  </Typography>
                </Grid>
                <Grid container item xs={12} md={8}>
                  {editMode ? (
                    <>
                      {areTagsLoading ? (
                        <CircularProgress />
                      ) : (
                        <Autocomplete
                          multiple
                          id="multiple-limit-tags"
                          options={tags}
                          defaultValue={formData.tags}
                          onChange={handleTagsChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('common.forms.labels.tag')}
                              placeholder="..."
                            />
                          )}
                          sx={{ width: '100%' }}
                        />
                      )}
                    </>
                  ) : (
                    <Box sx={{ padding: '5px 0' }}>
                      {formData.tags.map((label: string, index: number) => (
                        <Chip key={index} label={label} />
                      ))}
                    </Box>
                  )}
                </Grid>
                {!editMode && !!formData.createdAt && (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.tables.header.modified-at')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box sx={{ padding: '5px 0' }}>
                        <Chip
                          key={0}
                          label={moment
                            .unix(formData.createdAt)
                            .format('DD-MM-YYYY HH:mm:ss')}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
                {!editMode && !!formData.activeSigns && (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.forms.labels.activeSigns')} :{' '}
                      </Typography>
                      <Annotation
                        tooltip={`${t('common.annotations.consent-signs')}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant={'body1'}>
                        {formData.activeSigns}
                      </Typography>
                    </Grid>
                  </>
                )}
                {isConsentActivityLoading ? (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {!editMode && operations.length > 0 && user && consent && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          display="flex"
                          justifyContent="left"
                        >
                          <Typography variant={'h5'}>
                            {t('common.forms.labels.salt')} :{' '}
                          </Typography>
                          <Annotation
                            tooltip={`${t('common.annotations.salt')}`}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Typography variant={'body1'}>
                            {operations[0].salt}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          display="flex"
                          justifyContent="left"
                        >
                          <Typography variant={'h5'}>
                            {t('common.forms.labels.userSignature')} :{' '}
                          </Typography>
                          <Annotation
                            tooltip={`${t(
                              'common.annotations.user-signature',
                            )}`}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Typography variant={'body1'}>
                            {operations[0].userSignature}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ConsentActivityTable
                            data={operations}
                            user={user}
                            consent={consent}
                            setRefresh={setActivityRefresh}
                          />
                        </Grid>
                      </>
                    )}
                    {!editMode &&
                      formData.consentHash &&
                      !formData.draft &&
                      operations.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            {formData.signatureType !==
                              MOBILE_DEVICE_SIGNATURE &&
                            !operations[0].isSigned &&
                            formData.activated ? (
                              <ClassicButton
                                key="grant-revoke-button"
                                onClick={onSignConsentClick}
                                startIcon={
                                  isLoading || txnHash ? (
                                    <CircularProgress
                                      style={{
                                        color: '#FFF',
                                      }}
                                      size={20}
                                    />
                                  ) : null
                                }
                              >
                                {t('common.toggle.grant-consent')}
                              </ClassicButton>
                            ) : operations[0].isSigned ? (
                              <ClassicButton
                                key="grant-revoke-button"
                                onClick={() => setIsDialogOpen(true)}
                                startIcon={
                                  isLoading || txnHash ? (
                                    <CircularProgress
                                      style={{
                                        color: '#FFF',
                                      }}
                                      size={20}
                                    />
                                  ) : null
                                }
                              >
                                {t('common.toggle.revoke-consent')}
                              </ClassicButton>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </>
                      )}
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!(isLoading || txnHash) && (
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: { xs: 'center', md: 'flex-end' },
            width: '100%',
            p: 2.5,
          }}
        >
          {editMode && (
            <PermissionGuard
              permissions={['ENTERPRISE_CREATE_CONSENT_DRAFT']}
              taxId={formData.taxId}
            >
              <ClassicButton
                sx={{ maxWidth: '8rem' }}
                startIcon={<EditIcon />}
                onClick={() => handleSubmit()}
                disabled={!isFormValid()}
              >
                {t('common.button.save-btn')}
              </ClassicButton>
            </PermissionGuard>
          )}
          {!editMode && (
            <>
              <PDFDownloadLink
                document={
                  <ConsentPrint
                    consent={formData}
                    user={user}
                    operations={operations}
                    signature={signature}
                    attachments={attachments}
                  />
                }
                fileName="consent.pdf"
              >
                {() => (
                  <ClassicButton
                    sx={{ maxWidth: '8rem' }}
                    startIcon={<PrintIcon />}
                    disabled={isSignatureLoading || isConsentActivityLoading}
                  >
                    {t('common.button.print-btn')}
                  </ClassicButton>
                )}
              </PDFDownloadLink>
              {!formData.draft ? (
                <>
                  <PermissionGuard
                    permissions={['ENTERPRISE_EXPORT_CSV']}
                    taxId={formData.taxId}
                  >
                    <CSVExportButton
                      data={csvData}
                      headers={csvHeaders}
                      isLoading={isCsvDataLoading}
                      onClick={onCsvClick}
                      disabled={isCsvDataLoading || isConsentActivityLoading}
                      filename={`consent-${formData.consentHash}`}
                    />
                  </PermissionGuard>
                </>
              ) : (
                <PermissionGuard
                  permissions={['ENTERPRISE_REMOVE_CONSENT_DRAFT']}
                  taxId={formData.taxId}
                >
                  <ClassicButton
                    sx={{ maxWidth: '8rem' }}
                    key="delete-draft-button"
                    onClick={handleDelete}
                  >
                    {t('common.button.remove-btn')}
                  </ClassicButton>
                </PermissionGuard>
              )}
              {!(
                consentVersions.length > 0 &&
                formData.version !==
                  consentVersions[consentVersions.length - 1].version
              ) && (
                <PermissionGuard
                  permissions={['ENTERPRISE_MODIFY_CONSENTS']}
                  taxId={formData.taxId}
                >
                  <ClassicButton
                    sx={{ maxWidth: '8rem' }}
                    startIcon={<EditIcon />}
                    onClick={() => handleSubmit()}
                    disabled={!isFormValid()}
                  >
                    {editMode
                      ? t('common.button.save-btn')
                      : t('common.button.edit-btn')}
                  </ClassicButton>
                </PermissionGuard>
              )}
              <PermissionGuard
                permissions={[
                  'ENTERPRISE_ACTIVATE_CONSENT',
                  'ENTERPRISE_DEACTIVATE_CONSENT',
                ]}
                taxId={formData.taxId}
              >
                <ClassicButton sx={{ maxWidth: '8rem' }} onClick={handleActive}>
                  {formData.activated
                    ? t('common.toggle.deactivate')
                    : formData.draft
                    ? t('common.toggle.publish')
                    : t('common.toggle.activate')}
                </ClassicButton>
              </PermissionGuard>
            </>
          )}
        </DialogActions>
      )}
      <ConfirmationDialog
        open={isDialogOpen}
        description={t('common.confirm-consent-revoke')}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        onConfirmClick={onRevokeConsentClick}
      />
    </Dialog>
  );
}
