import ElementItem from '../../../../components/items/ElementItem';
import {
  ClassicButton,
  UnderlinedButton,
} from '../../../../layouts/styled/buttons';
import { useTranslation } from 'react-i18next';
import { useEnterprise } from '../../../../providers/EnterpriseProvider';
import Alert from '@mui/material/Alert';
import { IConsent } from '../../../../interfaces/consents.interface';
import { useState } from 'react';
import ConsentDialog from '../../consents/ConsentDialog';
import { Box } from '@mui/material';

interface Props {
  consent: IConsent;
  setRefresh(value: number): void;
}

export default function ConsentItem({ consent, setRefresh }: Props) {
  const { t } = useTranslation();
  const enterpriseProvider = useEnterprise();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const selectedEnterprise = enterpriseProvider.enterprises.data.find(
    (item) => {
      return item.taxId === consent.taxId;
    },
  );

  const onConsentClick = () => {
    setIsDialogOpen(true);
  };

  return (
    <ElementItem
      key={consent.id}
      owner={selectedEnterprise?.name}
      version={consent.version}
      title={consent.title}
      description={consent.description}
      chip={consent.tags.length > 0 ? [...consent.tags] : []}
      enterpriseLogo={selectedEnterprise?.logoUrl}
      gradient={consent?.gradient}
      children={
        <Box
          sx={{
            height: '185px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {!consent.draft && (
            <>
              {!consent.activated ? (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {t('common.error.consent-inactive')}
                </Alert>
              ) : (
                <>
                  {consent.state !== 2 && (
                    <ClassicButton
                      key="grant-revoke-button"
                      onClick={onConsentClick}
                      startIcon={null}
                    >
                      {t('common.toggle.grant-consent')}
                    </ClassicButton>
                  )}
                </>
              )}
              {consent.state === 2 && (
                <ClassicButton
                  key="grant-revoke-button"
                  onClick={onConsentClick}
                  startIcon={null}
                >
                  {t('common.toggle.revoke-consent')}
                </ClassicButton>
              )}
            </>
          )}
          <UnderlinedButton
            key="more-info-button"
            sx={{ paddingBottom: '10px' }}
            onClick={onConsentClick}
          >
            {t('common.button.more-info-btn')}
          </UnderlinedButton>

          {isDialogOpen && (
            <ConsentDialog
              consent={consent}
              open={isDialogOpen}
              handleClose={() => {
                setIsDialogOpen(false);
              }}
              setRefresh={(value) => {
                setRefresh(value);
              }}
            />
          )}
        </Box>
      }
    />
  );
}
