import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import EditIcon from '@mui/icons-material/Edit';
import { useOperation } from '../../../providers/OperationProvider';
import { COLORS, OPERATIONS } from '../../../utils/constants';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { validatePolandVat } from '../../../utils/validators';
import useUtils from '../../../hooks/useUtils';
import { IEnterprise } from '../../../interfaces/enterprise.interface';

const initialEnterprise: IEnterprise = {
  id: 0,
  ownerHash: '',
  name: '',
  taxId: '',
  createdAt: 0,
  activated: true,
  country: '',
  city: '',
  street: '',
  building: '',
  apartment: '',
  zip: '',
  logoUrl: '',
  lastActivity: 0,
  membersCount: 0,
};

const initialError = {
  name: '',
  taxId: '',
  ownerHash: '',
};

type Props = {
  enterprise?: IEnterprise;
  open: boolean;
  handleClose: () => void;
  setRefresh: (value: number) => void;
};

export default function EnterpriseDialog({
  enterprise,
  open,
  handleClose,
  setRefresh,
}: Props) {
  const { i18n, t } = useTranslation();
  const { setOperation } = useOperation();
  const { getGUS } = useUtils();
  const enterpriseProvider = useEnterprise();
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<IEnterprise>(initialEnterprise);
  const [formErrors, setFormErrors] =
    useState<Record<string, string>>(initialError);
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>(
    {},
  );

  useEffect(() => {
    enterprise ? setFormData(enterprise) : setEditMode(true);
  }, [enterprise]);

  const setValidationErrors = () => {
    const errors: Record<string, string> = {};
    errors.name =
      formData.name === '' ? t('common.forms.errors.field-required') : '';
    errors.taxId =
      formData.taxId === '' ? t('common.forms.errors.field-required') : '';
    errors.ownerHash =
      formData.ownerHash === '' ? t('common.forms.errors.field-required') : '';
    setFormErrors(errors);
  };

  const isFormValid = () =>
    formData.name !== '' && formData.taxId !== '' && formData.ownerHash !== '';

  const handleSubmit = async () => {
    if (!editMode) {
      setEditMode(true);
      return;
    }
    if (isFormValid()) {
      formData.taxId ? await editEnterprise() : await createEnterprise();
      setEditMode(false);
      setRefresh(Date.now());
    } else {
      setValidationErrors();
    }
  };

  const createEnterprise = async () => {
    setIsLoading(true);
    try {
      await enterpriseProvider.createEnterprise(formData);
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.consent-created'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const editEnterprise = async () => {
    setIsLoading(true);
    try {
      if (
        enterprise &&
        (formData.name !== enterprise.name ||
          formData.zip !== enterprise.zip ||
          formData.country !== enterprise.country ||
          formData.street !== enterprise.street ||
          formData.building !== enterprise.building ||
          formData.city !== enterprise.city ||
          formData.apartment !== enterprise.apartment)
      ) {
        await enterpriseProvider.modifyEnterprise(formData);
        setRefresh(Date.now());
      }

      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.enterprise-modified'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadGusData = async () => {
    if (!validatePolandVat(formData.taxId)) {
      return;
    }
    try {
      const gusData = await getGUS(formData.taxId);
      if (gusData)
        setFormData((prevState) => ({
          ...prevState,
          ...gusData,
        }));
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          textAlign: 'center',
          p: 2.5,
          borderBottom: `1px solid ${COLORS.primaryColor}`,
        }}
      >
        <Typography variant={'h5'}>
          {t('pages.enterprise.title-editor')}
        </Typography>
      </Box>
      <DialogContent>
        <Grid
          container
          sx={{
            minWidth: { xs: '300px', md: '500px' },
          }}
        >
          <Grid
            style={{ marginTop: 1 }}
            container
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
            >
              <Typography variant={'h5'}>
                {t('common.forms.labels.taxId')} :
              </Typography>
            </Grid>
            <Grid item xs={10} md={6}>
              <ClassicInput
                required
                fullWidth
                label={t('common.forms.labels.vat-number')}
                InputLabelProps={{ shrink: true }}
                value={formData.taxId}
                error={touchedFields.taxId && !!formErrors.taxId}
                helperText={touchedFields.taxId && !!formErrors.taxId}
                onChange={(e) => handleInputChange('taxId', e.target.value)}
              />
            </Grid>

            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <IconButton
                color="primary"
                onClick={() => loadGusData()}
                disabled={!formData.taxId && i18n.language !== 'pl'}
              >
                <RefreshOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            style={{ marginTop: 1 }}
            container
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid item xs={12} md={4} display="flex" justifyContent="left">
              <Typography variant={'h5'}>
                {t('common.forms.labels.name')} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ClassicInput
                required
                label={t('common.forms.labels.name')}
                fullWidth
                value={formData.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                error={formErrors.name !== ''}
                helperText={formErrors.name}
              />
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: 1 }}
            container
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid item xs={12} md={4} display="flex" justifyContent="left">
              <Typography variant={'h5'}>
                {t('common.profile.country')} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ClassicInput
                label={t('common.profile.country')}
                fullWidth
                value={formData.country}
                onChange={(e) => handleInputChange('country', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: 1 }}
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid item xs={12} md={4} display="flex" justifyContent="left">
              <Typography variant={'h5'}>
                {t('common.forms.labels.city')} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ClassicInput
                label={t('common.forms.labels.city')}
                fullWidth
                value={formData.city}
                onChange={(e) => handleInputChange('city', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: 1 }}
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid item xs={12} md={4} display="flex" justifyContent="left">
              <Typography variant={'h5'}>
                {t('common.forms.labels.street')} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ClassicInput
                label={t('common.forms.labels.street')}
                fullWidth
                value={formData.street}
                onChange={(e) => handleInputChange('street', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: 1 }}
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid item xs={12} md={4} display="flex" justifyContent="left">
              <Typography variant={'h5'}>
                {t('common.forms.labels.building')} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ClassicInput
                label={t('common.forms.labels.building')}
                fullWidth
                value={formData.building}
                onChange={(e) => handleInputChange('building', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: 1 }}
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid item xs={12} md={4} display="flex" justifyContent="left">
              <Typography variant={'h5'}>
                {t('common.profile.apartment')} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ClassicInput
                label={t('common.profile.apartment')}
                fullWidth
                value={formData.apartment}
                onChange={(e) => handleInputChange('apartment', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: 1 }}
            xs={12}
            spacing={2}
            textAlign="left"
            alignItems="center"
          >
            <Grid item xs={12} md={4} display="flex" justifyContent="left">
              <Typography variant={'h5'}>
                {t('common.forms.labels.zip')} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ClassicInput
                label={t('common.forms.labels.zip')}
                fullWidth
                value={formData.zip}
                onChange={(e) => handleInputChange('zip', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', md: 'flex-end' },
          width: '100%',
          p: 2.5,
        }}
      >
        <ClassicButton
          sx={{ maxWidth: '10rem' }}
          startIcon={<EditIcon />}
          onClick={() => handleSubmit()}
          disabled={!isFormValid()}
        >
          {t('common.button.save-btn')}
        </ClassicButton>
      </DialogActions>
    </Dialog>
  );
}
