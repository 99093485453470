import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useOperation } from '../../providers/OperationProvider';
import {
  COLORS,
  MESSAGE_TIMEOUT,
  OPERATIONS,
  INFO_MESSAGE_TIMEOUT,
} from '../../utils/constants';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

const OperationPopup: React.FC = () => {
  const { operation } = useOperation();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (operation) {
      setIsOpen(true);
      const timer = setTimeout(
        () => {
          setIsOpen(false);
        },
        operation.severity === OPERATIONS.INFO
          ? INFO_MESSAGE_TIMEOUT
          : MESSAGE_TIMEOUT,
      );
      return () => clearTimeout(timer);
    } else {
      setIsOpen(false);
    }
  }, [operation]);

  return (
    <>
      {operation && (
        <Snackbar
          open={isOpen}
          autoHideDuration={MESSAGE_TIMEOUT}
          sx={{
            width: { xs: '15rem', md: '21rem' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor:
                operation.severity === OPERATIONS.SUCCESS
                  ? COLORS.successColor
                  : operation.severity === OPERATIONS.INFO
                  ? COLORS.infoColor
                  : COLORS.errorColor,
              padding: 0.75,
              borderRadius: 3,
              color: '#FFF',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {operation.severity === OPERATIONS.SUCCESS ? (
                <CheckCircleOutlineIcon />
              ) : operation.severity === OPERATIONS.INFO ? (
                <HourglassEmptyIcon />
              ) : (
                <ErrorOutlineIcon />
              )}
              <Typography sx={{ ml: { xs: 0, md: 1 } }}>
                {operation.severity === OPERATIONS.SUCCESS
                  ? t('common.operation-status.success')
                  : operation.severity === OPERATIONS.INFO
                  ? t('common.operation-status.info')
                  : t('common.operation-status.error')}
              </Typography>
            </Box>
            {operation.message && (
              <Typography
                sx={{
                  whiteSpace: 'brake-space',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  maxHeight: '3rem',
                  mt: 0.5,
                }}
              >
                {operation.severity === OPERATIONS.ERROR
                  ? t('common.reason')
                  : ''}
                {operation.message}
              </Typography>
            )}
          </Box>
        </Snackbar>
      )}
    </>
  );
};

export default OperationPopup;
