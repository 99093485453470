import React, { ReactNode } from 'react';
import { usePermission } from '../providers/PermissionProvider';

interface Props {
  permissions: string[];
  taxId?: string;
  children: ReactNode;
}

const PermissionGuard = ({ permissions, taxId, children }: Props) => {
  const perms = usePermission();

  const checkPermission = (
    requiredPermissions: string[],
    taxId: string,
  ): boolean => {
    if (requiredPermissions.length === 0) {
      return false;
    }

    const uniqueFlags = [
      ...perms.permissions.map((permission) => ({
        flag: permission.flag,
        taxId: permission.taxId,
      })),
    ];
    for (const p of requiredPermissions) {
      if (taxId != '') {
        if (
          !uniqueFlags.some((flag) => flag.flag === p && flag.taxId === taxId)
        ) {
          return false;
        }
      } else {
        if (!uniqueFlags.some((flag) => flag.flag === p)) {
          return false;
        }
      }
    }

    return true;
  };

  const userHasPermission = checkPermission(permissions, taxId ?? '');

  return userHasPermission ? <>{children}</> : null;
};

export default PermissionGuard;
