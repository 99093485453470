export const ROUTE = {
  rootPage: '/',
  error404: '/404',
  widget: '/widget',
  registerPage: '/register',
  smsVerificationPage: '/2fa',
  projectFlowPage: {
    add: '/project/add',
    join: '/project/join',
  },
  dashboardPage: {
    rootPage: '/dashboard/projects',
    projects: {
      root: '/dashboard/projects',
      add: '/dashboard/projects/add',
      inviteParticipant: '/dashboard/projects/invite-participant/:projectHash',
    },
    userData: {
      root: '/dashboard/user-data',
    },
    consents: {
      root: '/dashboard/consents',
    },
    patients: {
      root: '/dashboard/patients',
      add: '/dashboard/patients/add',
    },
    questions: {
      root: '/dashboard/questions',
    },
    benefits: {
      root: '/dashboard/benefits',
      add: '/dashboard/benefits/add',
    },
    notification: {
      root: '/dashboard/notification',
      add: '/dashboard/notification/add',
    },
    operations: {
      root: '/dashboard/operations',
    },
    profile: {
      root: '/dashboard/settings',
      billing: '/dashboard/settings/billing',
    },
    enterprise: {
      root: '/dashboard/enterprise',
      users: '/dashboard/enterprise/users/:taxId',
      addUser: '/dashboard/enterprise/users/add/:taxId',
      tags: '/dashboard/enterprise/tags/:taxId',
      addTag: '/dashboard/enterprise/tags/add/:taxId',
    },
  },
};
