import React, { useState } from 'react';
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  Menu,
  MenuItem,
} from '@mui/material';
import i18n from 'i18next';
import { useCookies } from 'react-cookie';
import { LANGUAGES } from '../../utils/constants';

interface LanguageData {
  name: string;
  flag: string;
  code: string;
}

export default function FlagSelector() {
  const [cookies, setCookie] = useCookies(['language']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLanguageChange = (lang: LanguageData) => {
    setCookie('language', lang, { path: '/' });
    i18n.changeLanguage(lang.code);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleMenuToggle}>
        <Avatar
          alt="language"
          src={cookies.language?.flag}
          style={{ border: '4px solid lightgray', borderRadius: '50%' }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {LANGUAGES.map((lang) => (
          <MenuItem onClick={() => handleLanguageChange(lang)} key={lang.name}>
            <ListItemAvatar>
              <Avatar
                alt={lang.name}
                src={lang.flag}
                style={{
                  marginLeft: '10px',
                  border: '4px solid lightgray',
                  borderRadius: '50%',
                }}
              />
            </ListItemAvatar>
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
