import * as React from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { dashboardPage } from '../routes/router';
import { ThemeProvider } from '@mui/material/styles';
import Accordion from '../components/appbar/Accordion';
import FlagSelector from '../components/appbar/FlagSelector';
import ProfileSelector from '../components/appbar/ProfileSelector';
import PermissionGuard from '../guards/PermissionGuard';
import { RequiredProvider } from '../index';
import { layoutTheme } from './styled/layout';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../utils/constants';
import NotificationSelector from '../components/appbar/NotificationSelector';
import { useAuth } from '../providers/AuthProvider';

export default function DashboardLayout() {
  const auth = useAuth();
  const { i18n, t } = useTranslation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState<string | null>(
    'pages.dashboard.title',
  );
  const [drawerWidth, setDrawerWidth] = React.useState<number>(350);

  React.useEffect(() => {
    setDrawerWidth(mobileOpen ? 225 : 350);
  }, [mobileOpen]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onClick = (key: string | null) => {
    setActiveItem(key);
    setMobileOpen(false);
  };

  const openTerm = () => {
    const pdfUrl =
      i18n.language === 'pl'
        ? '/documents/tos-pl.pdf'
        : '/documents/tos-eng.pdf';

    window.open(pdfUrl, '_blank');
  };

  const openPrivacyPolicy = () => {
    const pdfUrl =
      i18n.language === 'pl' ? '/documents/pp-pl.pdf' : '/documents/pp-eng.pdf';

    window.open(pdfUrl, '_blank');
  };

  const drawer = (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Link
        to="/"
        style={{
          margin: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Box
          component="img"
          alt="Data Lake Application"
          src={'/logo.svg'}
          style={{ width: '15rem' }}
        />
      </Link>
      <Typography variant={'subtitle1'} align={'center'}>
        {t('common.public-beta').toUpperCase()}
      </Typography>
      <List>
        {dashboardPage.map((item, index) => (
          <PermissionGuard key={index} permissions={item.permissions}>
            <Accordion
              item={item}
              activeItem={activeItem}
              setActiveItem={onClick}
            />
          </PermissionGuard>
        ))}
      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          margin: 'auto',
          padding: '10px',
          textAlign: 'center',
          position: 'absolute',
          bottom: '0',
        }}
      >
        <Typography
          component="span"
          variant="body2"
          color="primary"
          onClick={openTerm}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            mb: '10px',
          }}
        >
          {t('common.terms-of-service.part2')}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          color="primary"
          onClick={openPrivacyPolicy}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {t('common.privacy-policy.file-name')}
        </Typography>
      </Box>
    </div>
  );

  return (
    <RequiredProvider>
      <ThemeProvider theme={layoutTheme}>
        <Box sx={{ display: 'flex', background: 'white' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { md: `calc(100% - ${drawerWidth}px)` },
              ml: { xs: `${drawerWidth}px` },
              boxShadow: 0,
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  color: COLORS.greyColor,
                  opacity: 0.75,
                  marginRight: '20px',
                }}
              >
                {t('common.motto')}
              </Typography>
              {auth.sessionToken && <NotificationSelector />}
              <FlagSelector />
              <ProfileSelector />
            </Toolbar>
          </AppBar>

          <Box
            component="nav"
            sx={{ width: { md: drawerWidth } }}
            aria-label="mailbox folders"
          >
            <Drawer
              className={'drawer'}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  overflow: 'hidden',
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              className={'drawer'}
              variant="permanent"
              sx={{
                display: { xs: 'none', md: 'block' },
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
    </RequiredProvider>
  );
}
