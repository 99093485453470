import { Button, CircularProgress, styled, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import CardContainer from '../../../components/forms/CardContainer';
import { ClassicButton } from '../../../layouts/styled/buttons';
import { ROUTE } from '../../../utils/route';
import { useOperation } from '../../../providers/OperationProvider';
import { OPERATIONS } from '../../../utils/constants';
import useNotifications from '../../../hooks/useNotifications';
import { INotification } from '../../../interfaces/logs.interface';
import React from 'react';

const StyledTextField = styled(TextField)({
  marginBottom: 10,
});

const StyledButton = styled(Button)({
  marginTop: 10,
});

export default function NotificationAddPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { previousUrl } = state;
  const { setOperation } = useOperation();

  const navigate = useNavigate();
  const notification = useNotifications();
  const [formData, setFormData] = useState<INotification>({
    users: [],
    notification: '',
  });
  const [error, setError] = useState({ users: false, notification: false });
  const [isLoading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      if (formData.users.length === 0 || formData.notification === '') {
        setError({
          users: formData.users.length === 0,
          notification: formData.notification === '',
        });
        return;
      }
      setLoading(true);
      await notification.sendNotification(formData);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
      setFormData({ users: [], notification: '' });
      navigate(ROUTE.dashboardPage.notification.root, {
        state: { previousUrl: previousUrl },
      });
    }
  };

  const handleUsersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const usersArray = event.target.value.split(',').map((user) => user.trim());
    setFormData((prevState) => ({ ...prevState, users: usersArray }));
    setError({ ...error, users: false });
  };

  const handleNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      notification: event.target.value,
    }));
    setError({ ...error, notification: false });
  };

  return (
    <CardContainer
      title={t('pages.notification.title') as string}
      actions={[
        isLoading ? (
          <CircularProgress />
        ) : (
          <ClassicButton onClick={handleSave}>
            {t('common.button.send-btn')}
          </ClassicButton>
        ),
      ]}
    >
      <StyledTextField
        label={t('pages.notification.form.user-id')}
        value={formData.users.join(', ')}
        onChange={handleUsersChange}
        error={error.users}
        helperText={error.users && t('common.error.required')}
        fullWidth
      />
      <StyledTextField
        label={t('pages.notification.form.message')}
        value={formData.notification}
        onChange={handleNotificationChange}
        error={error.notification}
        helperText={error.notification && t('common.error.required')}
        fullWidth
      />
    </CardContainer>
  );
}
