import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useProjects from '../../hooks/useProjects';
import LoadingController from '../../components/utils/LoadingController';
import Questions from './steps/Questions';
import Congratulations from './steps/Congratulations';
import UserDataAndConsents from './steps/UserDataAndConsents';
import { ROUTE } from '../../utils/route';
import { useOperation } from '../../providers/OperationProvider';
import { OPERATIONS } from '../../utils/constants';
import { IProject } from '../../interfaces/projects.interface';
import { useAuth } from '../../providers/AuthProvider';
import i18next from 'i18next';
import useUtils from '../../hooks/useUtils';
import { TRANSACTION_CHECK_TIMEOUT } from '../../constants/common';
import TransactionStatus from '../../components/TransactionStatus';
import { SignatureProps } from '../../interfaces/signature.interface';
import { IAnswerBasicData } from '../../interfaces/questions.interface';

enum FlowSteps {
  Loading = 0,
  Questions = 1,
  Invite = 2,
  Congratulations = 3,
}

export default function ProjectStep() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getProject, joinProject, addUserToProject } = useProjects();
  const { getTxnStatus } = useUtils();
  const { setOperation } = useOperation();
  const auth = useAuth();
  const [step, setStep] = useState<FlowSteps>(FlowSteps.Loading);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [txnHash, setTxnHash] = useState<string | undefined>('');
  const [project, setProject] = useState<IProject>();
  const [questionResponses, setQuestionResponses] = useState<
    IAnswerBasicData[]
  >([]);

  useEffect(() => {
    if (!auth.isLoading && (auth.hasProjectAccess || auth.isLoggedIn))
      fetchProject();
  }, [auth.isLoading, auth.hasProjectAccess, auth.isLoggedIn]);

  useEffect(() => {
    if (project)
      if (!txnHash) {
        if (isOnline) {
          setIsLoading(false);
          setStep(FlowSteps.Congratulations);
        } else {
          navigate(ROUTE.dashboardPage.projects.root);
        }
      } else {
        checkTransaction(txnHash);
      }
  }, [txnHash]);

  const checkTransaction = async (txnHash: string) => {
    try {
      const status = await getTxnStatus(txnHash);
      if (isOnline) window.parent.postMessage({ status }, '*');
      status === 2
        ? setTimeout(() => {
            checkTransaction(txnHash);
          }, TRANSACTION_CHECK_TIMEOUT)
        : setTxnHash(undefined);
    } catch (error) {
      console.error('Cannot check transaction', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      window.parent.postMessage({ error: error.response.data.details }, '*');
    }
  };

  const fetchProject = async () => {
    setIsLoading(true);
    try {
      setIsOnline(!!auth.projectToken);
      const hash = auth.projectHash || '';
      const fetchedProject = await getProject(hash);
      if (fetchedProject) {
        setProject(fetchedProject);

        setStep(
          fetchedProject.questions.length > 0
            ? FlowSteps.Questions
            : FlowSteps.Invite,
        );
      }
    } catch (error) {
      console.error('Error: ', error);
      navigate(ROUTE.error404);
    } finally {
      setIsLoading(false);
    }
  };

  const handleParticipantData = async (
    formData: Record<string, string>,
    consentHashes: string[],
    signature?: SignatureProps,
  ) => {
    setIsLoading(true);
    try {
      const data = {
        projectHash: auth.projectHash || '',
        answers: questionResponses || [],
        email: formData.email,
        firstName: formData.firstName,
        secondName: formData.secondName,
        phone: formData.phone,
        personalId: formData.personalId,
        language: i18next.resolvedLanguage,
        signature,
        checkbox: isOnline ? true : !!formData.checkbox,
        consentHashes,
      };
      if (isOnline) window.parent.postMessage({ formData }, '*');
      let txnHash = isOnline
        ? await joinProject({
            ...data,
            invitedBy: searchParams.get('referral') || undefined,
          })
        : await addUserToProject(data);
      setTxnHash(txnHash);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      window.parent.postMessage({ error: error.response.data.details }, '*');
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onLoginClick = () => {
    auth.signOut();
    navigate(ROUTE.rootPage);
  };

  const handleQuestionResponses = (responses: IAnswerBasicData[]) => {
    setQuestionResponses(responses);
    setStep(FlowSteps.Invite);
  };

  const stepComponentsMap: Record<FlowSteps, ReactNode> = {
    [FlowSteps.Loading]: <LoadingController />,
    [FlowSteps.Invite]: project && (
      <UserDataAndConsents
        isOnline={isOnline}
        project={project}
        onButtonClick={handleParticipantData}
      />
    ),
    [FlowSteps.Questions]: project && project.questions.length > 0 && (
      <Questions
        isOnline={isOnline}
        questions={project.questions}
        onButtonClick={handleQuestionResponses}
      />
    ),
    [FlowSteps.Congratulations]: (
      <Congratulations onButtonClick={onLoginClick} />
    ),
  };

  return (
    <Container maxWidth="lg">
      <Card>
        {isLoading || txnHash ? (
          <>
            {txnHash ? (
              <TransactionStatus txnHash={txnHash} />
            ) : (
              <LoadingController />
            )}
          </>
        ) : (
          stepComponentsMap[step]
        )}
      </Card>
    </Container>
  );
}
