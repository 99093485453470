import React, { ReactNode, useEffect } from 'react';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { LAKE_TOKEN_ADDRESS } from '../utils/constants';

import { mainnet } from 'viem/chains';
import { WagmiConfig } from 'wagmi';

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '';

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['/logo.svg'],
};

const chains = [mainnet];
const token = LAKE_TOKEN_ADDRESS;
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  tokens: {
    1: { address: token },
  },
});

export const Web3ModalProvider = ({ children }: { children: ReactNode }) => {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};
