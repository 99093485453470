import { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/bootstrap.css';
import SignatureCanvas from 'react-signature-canvas';
import Pressure from 'pressure';
import {
  SignaturePoints,
  SignatureProps,
} from './../../interfaces/signature.interface';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { COLORS } from '../../utils/constants';

interface Props {
  onButtonClick: (signature: SignatureProps) => void;
}

export default function SignaturePad({ onButtonClick }: Props) {
  const { t } = useTranslation();
  const [isSignatureStarted, setIsSignatureStarted] = useState<boolean>(false);
  const signatureDataRef = useRef<SignaturePoints[][]>([]);
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    Pressure.set(
      '#signature-pad',
      {
        change: function (force: number) {
          logData(force);
        },
      },
      { polyfill: false },
    );
  }, []);

  const onClearSignatureClick = () => {
    signatureCanvasRef.current?.clear();
    signatureDataRef.current = [];
    setIsSignatureStarted(false);
  };

  const logData = (force: number) => {
    const refs = signatureCanvasRef.current?.toData() || [];
    if (refs.length === 0) return;
    let newData = [...signatureDataRef.current];
    if (refs.length > newData.length) newData = [...newData, []];

    const lastStroke = refs[refs.length - 1];
    const lastSavedStroke = newData[newData.length - 1] || [];
    const lastPoint = lastStroke[lastStroke.length - 1];
    const lastSavedPoint = lastSavedStroke[lastSavedStroke.length - 1];
    if (
      !lastSavedPoint ||
      (lastSavedPoint && lastPoint.time !== lastSavedPoint.time)
    ) {
      newData[newData.length - 1] = [
        ...lastSavedStroke,
        {
          ...lastPoint,
          pressure: force,
        },
      ];
      signatureDataRef.current = newData;
    }
  };

  const onSaveClick = () => {
    onButtonClick({
      strokes: signatureDataRef.current.length
        ? signatureDataRef.current
        : signatureCanvasRef.current
            ?.toData()
            .map((el) => [...el.map((point) => ({ ...point, pressure: 1 }))]) ||
          [],
      image: signatureCanvasRef.current?.toDataURL() || '',
    });
  };

  const handleEnd = () => {
    setIsSignatureStarted(!signatureCanvasRef.current?.isEmpty());
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: 'var(--vh)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        overflow: 'hidden',
        zIndex: 9999,
        padding: '60px',
      }}
    >
      {isSmScreen ? (
        <Box
          sx={{
            width: '100%',
            maxWidth: '500px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            component="span"
            variant="body1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {t('userflow.sign.info')}
          </Typography>
          <SignatureCanvas
            ref={signatureCanvasRef}
            onEnd={handleEnd}
            canvasProps={{
              className: 'signature-canvas',
              id: 'signature-pad',
              style: {
                border: '1px solid #000',
                width: '500px',
                height: '200px',
                margin: '15px auto',
              },
            }}
            clearOnResize={false}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <IconButton
              disabled={!isSignatureStarted}
              onClick={onClearSignatureClick}
            >
              <HighlightOffIcon
                sx={{ width: '2.5rem', height: '2.5rem' }}
                style={{
                  color: `${
                    !isSignatureStarted ? COLORS.lightGrey : COLORS.errorColor
                  }`,
                }}
              />
            </IconButton>
            <IconButton disabled={!isSignatureStarted} onClick={onSaveClick}>
              <CheckCircleOutlineIcon
                sx={{ width: '2.5rem', height: '2.5rem' }}
                style={{
                  color: `${
                    !isSignatureStarted ? COLORS.lightGrey : COLORS.successColor
                  }`,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Typography
          component="span"
          variant="body1"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {t('common.unsupported-device')}
        </Typography>
      )}
    </Box>
  );
}
