import { Button, Select, styled, TextField } from '@mui/material';
import { COLORS } from '../../utils/constants';

export const ClassicButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '40px',
  color: '#FFF',
  fontWeight: 700,
  fontFamily: "'Nunito', sans-serif",
  borderRadius: theme.spacing(10),
  outline: '1px solid #3D518C',
  background: COLORS.primaryColor,
  marginTop: theme.spacing(2),
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 25px',
  '& .MuiSvgIcon-root': {
    fontSize: 32,
  },
  '&:hover': {
    background: COLORS.secondaryColor,
    cursor: 'pointer',
  },
}));

export const OutlineButton = styled(Button)(({ theme }) => ({
  color: COLORS.primaryColor,
  fontWeight: 700,
  fontFamily: "'Nunito', sans-serif",
  borderColor: COLORS.primaryColor,
  borderRadius: theme.spacing(10),
  marginTop: theme.spacing(2),
  padding: '5px 20px',
  outline: '1px solid #3D518C',
  '&:hover': {
    borderColor: COLORS.secondaryColor,
    cursor: 'pointer',
  },
}));

export const UnderlinedButton = styled(Button)(({ theme }) => ({
  color: '#484848',
  fontWeight: 500,
  fontSize: 14,
  fontFamily: "'Nunito', sans-serif",
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(10),
  textDecoration: 'underline',
  background: 'none',
  border: 'none',
  '&:hover': {
    border: 'none',
    cursor: 'pointer',
  },
}));

export const ClassicInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontWeight: 500,
    color: '#000',
  },
  '& .MuiOutlinedInput-root': {
    color: COLORS.primaryColor,
    '& fieldset': {
      borderColor: COLORS.accentColor,
      borderRadius: '20px',
    },
    '&:hover fieldset': {
      borderColor: COLORS.accentColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.accentColor,
    },
  },
}));

export const ClassicSelect = styled(Select)(({ theme }) => ({
  color: COLORS.primaryColor,
  fontWeight: 700,
  fontFamily: "'Nunito', sans-serif",
  borderColor: COLORS.accentColor,
  borderRadius: theme.spacing(10),
  marginTop: theme.spacing(2),
  padding: '2px 10px',
  outline: '1px solid',
  outlineColor: COLORS.accentColor,
  '&:hover': {
    borderColor: COLORS.secondaryColor,
    cursor: 'pointer',
  },
}));
