import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SortListIcon from '@mui/icons-material/Sort';
import { OutlineButton } from '../../layouts/styled/buttons';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ISorting } from '../../interfaces/sorting.interface';

type Props = {
  sortOptions: ISorting[];
  onSelectSort: (sort: ISorting) => void;
  selectedSort: ISorting;
};

const SortButton = ({ sortOptions, onSelectSort, selectedSort }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortSelect = (sort: ISorting) => {
    onSelectSort(sort);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ mx: 1 }}>
      <OutlineButton
        sx={{ mt: 0, whiteSpace: 'nowrap' }}
        onClick={handleClick}
        startIcon={<SortListIcon />}
      >
        {t(selectedSort.title)}
      </OutlineButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {sortOptions.map((sort) => (
          <MenuItem
            key={sort.value}
            onClick={() => handleSortSelect(sort)}
            selected={sort === selectedSort}
          >
            {t(sort.title)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SortButton;
