import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  Grid,
  Typography,
  Box,
  MenuItem,
  Chip,
} from '@mui/material';
import CardContainer from '../../../components/forms/CardContainer';
import {
  ClassicButton,
  ClassicInput,
  ClassicSelect,
} from '../../../layouts/styled/buttons';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import EditIcon from '@mui/icons-material/Edit';
import LoadingController from '../../../components/utils/LoadingController';
import { usePermission } from '../../../providers/PermissionProvider';
import { useLocation } from 'react-router-dom';
import { useOperation } from '../../../providers/OperationProvider';
import useBenefits from '../../../hooks/useBenefits';
import { IBenefit } from '../../../interfaces/benefits.interface';
import { OPERATIONS } from '../../../utils/constants';
import { IEnterprise } from '../../../interfaces/enterprise.interface';

export default function BenefitAddPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { benefitHash } = state;
  const { setOperation } = useOperation();

  const enterpriseProvider = useEnterprise();
  const permissionsProvider = usePermission();
  const { getBenefit, createBenefit } = useBenefits();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<IBenefit>({
    activated: true,
    benefitHash: benefitHash ?? '',
    taxId: '',
    title: '',
    description: '',
    pointsPrice: 1,
    minPointsToClaim: 1,
    isCodeUnique: false,
    allowedClaims: 1,
    codes: [],
    gradient: 'default',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setEditMode(!formData.benefitHash);
    if (formData.benefitHash) {
      fetchBenefit(formData.benefitHash);
    }
  }, []);

  const fetchBenefit = async (benefitHash: string) => {
    setLoading(true);
    try {
      const benefitResponse = await getBenefit(benefitHash);
      if (benefitResponse) {
        setFormData(benefitResponse);
      }
    } catch (error) {
      console.error('Error:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field: string, value: string | boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setEditMode((prevEditMode) => !prevEditMode);
      if (editMode) {
        const benefitHash = await createBenefit(formData);
        await fetchBenefit(benefitHash);
        setOperation({
          severity: OPERATIONS.SUCCESS,
          message: t('pages.operations.messages.benefit-added'),
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setEditMode(false);
    }
  };

  const filteredEnterprise = enterpriseProvider.enterprises.data.filter(
    (item) => {
      const matchingPermission = permissionsProvider.permissions.find(
        (permission) => {
          return (
            permission.taxId === item.taxId &&
            permission.flag === 'ENTERPRISE_GET_TAGS'
          );
        },
      );
      return matchingPermission !== undefined;
    },
  );

  const selectedEnterprise = enterpriseProvider.enterprises.data.find(
    (item) => {
      return item.taxId === formData.taxId;
    },
  );

  return (
    <CardContainer
      title={t('pages.benefits.title-editor') as string}
      subheader={
        !isLoading &&
        editMode && [
          <ClassicButton
            startIcon={<EditIcon />}
            onClick={() => handleSubmit()}
          >
            {t('common.button.save-btn')}
          </ClassicButton>,
        ]
      }
    >
      <Container
        maxWidth={false}
        sx={{
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Grid alignItems="center" justifyContent="center">
          {isLoading ? (
            <LoadingController />
          ) : (
            <Grid container item xs={12} spacing={2} textAlign="left">
              {/* Enterprise selector */}
              {filteredEnterprise.length > 0 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.enterprise')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <ClassicSelect
                        fullWidth
                        labelId="enterprise-label"
                        id="enterprise-select"
                        defaultValue={filteredEnterprise[0].taxId}
                        value={formData.taxId}
                        onChange={(e) =>
                          handleInputChange('taxId', e.target.value as string)
                        }
                      >
                        {filteredEnterprise.map(
                          (item: IEnterprise, index: number) => (
                            <MenuItem key={index} value={item.taxId}>
                              {item.name}
                            </MenuItem>
                          ),
                        )}
                      </ClassicSelect>
                    ) : (
                      <Box sx={{ padding: '5px 0' }}>
                        <Chip key={0} label={selectedEnterprise?.name} />
                      </Box>
                    )}
                  </Grid>
                </>
              )}

              {selectedEnterprise &&
                Object.keys(formData).map(
                  (field, index) =>
                    field !== 'taxId' &&
                    field !== 'id' &&
                    field !== 'benefitHash' &&
                    field !== 'activated' &&
                    field !== 'createdAt' &&
                    field !== 'gradient' && (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          xs={12}
                          md={4}
                          display="flex"
                          justifyContent="left"
                        >
                          <Typography variant={'h5'}>
                            {t(`common.forms.labels.${field}`)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          {editMode ? (
                            <Grid item xs={12}>
                              {field === 'isCodeUnique' ? (
                                <RadioGroup
                                  aria-label={field}
                                  name={field}
                                  value={formData.isCodeUnique.toString()} // Zamiana boolean na string
                                  onChange={(e) =>
                                    handleInputChange(
                                      field,
                                      e.target.value === 'true',
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label={t('common.forms.labels.yes')}
                                  />
                                  <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label={t('common.forms.labels.no')}
                                  />
                                </RadioGroup>
                              ) : field === 'codes' ? (
                                <ClassicInput
                                  label={t(`common.forms.labels.codes`)}
                                  fullWidth
                                  value={formData.codes.join(', ')}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      codes: e.target.value
                                        .split(',')
                                        .map((code) => code.trim()),
                                    });
                                  }}
                                />
                              ) : (
                                <ClassicInput
                                  label={t(`common.forms.labels.${field}`)}
                                  fullWidth
                                  defaultValue={
                                    formData[field as keyof IBenefit]
                                  }
                                  onChange={(e) =>
                                    handleInputChange(field, e.target.value)
                                  }
                                  error={!!errors[field]}
                                  helperText={errors[field]}
                                />
                              )}
                            </Grid>
                          ) : field === 'isCodeUnique' ? (
                            <Grid item xs={12} md={8}>
                              <Box sx={{ padding: '5px 0' }}>
                                {formData.isCodeUnique
                                  ? t(`common.forms.labels.yes`)
                                  : t(`common.forms.labels.no`)}
                              </Box>
                            </Grid>
                          ) : (
                            <Box sx={{ padding: '5px 0' }}>
                              {formData[field as keyof IBenefit]}
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    ),
                )}
            </Grid>
          )}
        </Grid>
      </Container>
    </CardContainer>
  );
}
