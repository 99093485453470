import { useState } from 'react';
import {
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import useProjects from '../../hooks/useProjects';
import { useOperation } from '../../providers/OperationProvider';
import { OPERATIONS } from '../../utils/constants';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ClassicButton, ClassicInput } from '../../layouts/styled/buttons';
import HCaptcha from '@hcaptcha/react-hcaptcha';

type Props = {
  projectHash: string;
  accessKey: string;
};

const siteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY || '';

export default function EmailStep({ projectHash, accessKey }: Props) {
  const { inviteUserToProject } = useProjects();
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const [formData, setFormData] = useState({
    email: '',
    emailError: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (validateEmail(formData.email)) {
        setLoading(true);
        const inviteLink = await inviteUserToProject(
          projectHash,
          formData.email,
          i18next.resolvedLanguage,
          accessKey,
          token,
        );
        window.location.href = inviteLink;
      } else {
        setFormData({
          ...formData,
          emailError: t('pages.signup.error-msg') as string,
        });
      }
    } catch (error) {
      console.error(error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CardHeader
        title={
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '2.5rem', md: '3rem' } }}
            >
              {t('userflow.email.title')}
            </Typography>
          </Box>
        }
      />

      <CardContent>
        <Grid
          container
          sx={{ my: 10 }}
          component="form"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container sx={{ px: 5 }}>
            <Grid item xs={12}>
              <ClassicInput
                required
                fullWidth
                id="email"
                label={t('pages.signup.email-form')}
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleInputChange}
                error={!!formData.emailError}
                helperText={formData.emailError}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ px: 5 }}>
            <Box sx={{ mt: 2 }}>
              <HCaptcha sitekey={siteKey} onVerify={setToken} />
            </Box>

            {loading ? (
              <CircularProgress sx={{ m: 2 }} />
            ) : (
              <ClassicButton type="submit" disabled={!token}>
                {t('common.button.next-btn')}
              </ClassicButton>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}
