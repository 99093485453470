import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import BenefitItem from './items/BenefitItem';
import { Box, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ClassicButton } from '../../../layouts/styled/buttons';
import { ROUTE } from '../../../utils/route';
import {
  ITEMS_PER_PAGE,
  SORTING,
  SORTING_OPTIONS,
} from '../../../utils/constants';
import SortButton from '../../../components/actions/SortButton';
import LoadingController from '../../../components/utils/LoadingController';
import NoData from '../../../components/utils/NoData';
import Pagination from '@mui/material/Pagination';
import PermissionGuard from '../../../guards/PermissionGuard';
import { IBenefitResponse } from '../../../interfaces/benefits.interface';
import { ISorting } from '../../../interfaces/sorting.interface';
import { DEFAULT_PAGE_INDEX, EMPTY_DATA } from '../../../constants/common';

export default function BenefitsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [benefits, setBenefits] = useState<IBenefitResponse>(EMPTY_DATA);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sorting, setSorting] = useState<ISorting>(SORTING.CREATED_AT_DESC);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      SORTING_OPTIONS.find((el) => el.value === sorting) || SORTING.NAME_ASC,
    );
  }, [searchParams]);

  const onSortClick = (sorting: ISorting) => {
    setSearchParams({ sorting: sorting.value });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      sorting: sorting.value,
    });
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.benefits.title')}
        subtitle={t('common.beta-access.disclaimer')}
        actions={
          <>
            <SortButton
              sortOptions={[
                SORTING.CREATED_AT_DESC,
                SORTING.CREATED_AT_ASC,
                SORTING.TITLE_ASC,
              ]}
              onSelectSort={onSortClick}
              selectedSort={sorting}
            />
            <PermissionGuard permissions={['ENTERPRISE_CREATE_BENEFIT']}>
              <Box>
                <ClassicButton
                  sx={{ mt: 0 }}
                  startIcon={<AddIcon />}
                  onClick={() =>
                    navigate(ROUTE.dashboardPage.benefits.add, {
                      state: {
                        previousPath: ROUTE.dashboardPage.benefits.root,
                        benefitHash: null,
                      },
                    })
                  }
                >
                  {t('common.button.add-btn')}
                </ClassicButton>
              </Box>
            </PermissionGuard>
          </>
        }
      >
        {isLoading ? (
          <LoadingController />
        ) : benefits.total === 0 ? (
          <NoData />
        ) : (
          <Grid container spacing={2} sx={{ py: 5 }}>
            {benefits.data
              .sort((a, b) => (b.activated as any) - (a.activated as any))
              .map((benefit) => (
                <BenefitItem key={benefit.id} benefit={benefit} />
              ))}
          </Grid>
        )}

        {benefits.total > 0 && (
          <Pagination
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            count={Math.ceil(benefits.total / ITEMS_PER_PAGE)}
            page={pageIndex}
            onChange={handlePageChange}
            style={{ margin: '10px 0' }}
          />
        )}
      </NodeContainer>
    </header>
  );
}
