import React, { useState } from 'react';
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { RouterPage } from '../../routes/router';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../utils/constants';

interface AccordionProps {
  item: RouterPage;
  activeItem: string | null;
  setActiveItem: (key: string | null) => void;
}

const Accordion: React.FC<AccordionProps> = ({
  item,
  activeItem,
  setActiveItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleItemClick = (key: string, path: string) => {
    setActiveItem(key);
    navigate(path);

    handleToggle();
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <>
      <ListItem>
        <ListItemButton
          key={t(item.key)}
          onClick={() => handleItemClick(item.key, item.path)}
          sx={{
            color: activeItem === item.key ? '#fff' : COLORS.primaryColor,
            background: activeItem === item.key ? COLORS.primaryColor : '#fff',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon sx={{ color: 'inherit', marginRight: 1 }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={t(item.key)}
              primaryTypographyProps={{
                fontSize: 22,
                color: activeItem === item.key ? '#fff' : COLORS.neutralColor,
              }}
            />
          </div>
          {item.subpages &&
            item.subpages.some((subpage) => subpage.key.length > 0) && (
              <IconButton
                sx={{
                  color: 'inherit',
                  marginLeft: 'auto',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggle();
                }}
              >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
        </ListItemButton>
      </ListItem>

      {item.subpages && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {item.subpages
            .filter((subpage) => subpage.key !== '')
            .map((subpage, index) => (
              <ListItemButton
                key={t(subpage.key)}
                onClick={() => navigate(subpage.path)}
              >
                <ListItemIcon sx={{ color: 'inherit', marginRight: 1 }}>
                  {subpage.icon}
                </ListItemIcon>
                <ListItemText
                  primary={t(subpage.key)}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: 'medium',
                  }}
                />
              </ListItemButton>
            ))}
        </Collapse>
      )}
    </>
  );
};

export default Accordion;
