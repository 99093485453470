import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { Box, Grid, Tab } from '@mui/material';
import EnterpriseItem from './items/EnterpriseItem';
import Pagination from '@mui/material/Pagination';
import {
  ITEMS_PER_PAGE,
  SORTING,
  OPERATIONS,
  Tabs,
  SORTING_OPTIONS,
} from '../../../utils/constants';
import LoadingController from '../../../components/utils/LoadingController';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import AddIcon from '@mui/icons-material/Add';
import { ROUTE } from '../../../utils/route';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SortButton from '../../../components/actions/SortButton';
import { ISorting } from '../../../interfaces/sorting.interface';
import { useOperation } from '../../../providers/OperationProvider';
import NoData from '../../../components/utils/NoData';
import { TabContext, TabList } from '@mui/lab';
import EnterpriseDialog from './EnterpriseDialog';
import {
  DEFAULT_PAGE_INDEX,
  EMPTY_DATA,
  SEARCH_INPUT_DELAY,
} from '../../../constants/common';
import usePermissions from '../../../hooks/usePermissions';
import { IPermissionGroup } from '../../../interfaces/permissions.interface';
import {
  IEnterprise,
  IEnterpriseResponse,
} from '../../../interfaces/enterprise.interface';

export default function EnterprisePage() {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const navigate = useNavigate();
  const enterpriseProvider = useEnterprise();
  const { getPermissionGroups } = usePermissions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(1);
  const [sorting, setSorting] = useState<ISorting>(SORTING.NAME_ASC);
  const [tab, setTab] = useState<Tabs>(Tabs.ACTIVATED);
  const [enterprises, setEnterprises] =
    useState<IEnterpriseResponse>(EMPTY_DATA);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState<number>(0);
  const [selectedEnterprise, setSelectedEnterprise] = useState<IEnterprise>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [permissionGroups, setPermissionGroups] = useState<IPermissionGroup[]>(
    [],
  );

  useEffect(() => {
    fetchEnterprises(pageIndex, sorting, tab, searchQuery);
  }, [refresh]);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    const tab = searchParams.get('tab');
    setSearchQuery(searchParams.get('searchQuery') || '');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      SORTING_OPTIONS.find((el) => el.value === sorting) || SORTING.NAME_ASC,
    );
    setTab(tab === Tabs.DEACTIVATED ? Tabs.DEACTIVATED : Tabs.ACTIVATED);
    setRefresh(Date.now());
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      const groups = await getPermissionGroups();
      setPermissionGroups(groups);
    })();
  }, []);

  const fetchEnterprises = async (
    pageIndex: number,
    sorting: ISorting,
    tab: Tabs,
    searchQuery: string,
  ) => {
    setIsLoading(true);
    try {
      setEnterprises(
        await enterpriseProvider.getEnterprises({
          activated: tab === Tabs.ACTIVATED,
          pageIndex: pageIndex - 1,
          sorting: sorting.value,
          searchQuery,
        }),
      );
    } catch (error) {
      console.error('Error fetching enterprises:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSortClick = async (sorting: ISorting) => {
    setSearchParams({
      tab,
      searchQuery,
      sorting: sorting.value,
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      tab,
      sorting: sorting.value,
      searchQuery,
    });
  };

  const onTabClick = (tab: Tabs) => {
    setSearchParams({ tab });
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({
        tab,
        sorting: sorting.value,
        searchQuery,
      });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.enterprises.title')}
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.enterprises')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
        subtitle={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              justifyContent: { xs: 'center', md: 'space-between' },
            }}
          >
            <TabContext value={tab}>
              <Box>
                <TabList onChange={(event, newValue) => onTabClick(newValue)}>
                  <Tab
                    label={t('common.profile.activated')}
                    value={Tabs.ACTIVATED}
                  />
                  <Tab
                    label={t('common.profile.deactivated')}
                    value={Tabs.DEACTIVATED}
                  />
                </TabList>
              </Box>
            </TabContext>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <SortButton
                sortOptions={[
                  SORTING.NAME_ASC,
                  SORTING.CREATED_AT_DESC,
                  SORTING.CREATED_AT_ASC,
                  SORTING.ENTERPRISE_MEMBERS_DESC,
                  SORTING.ACTIVITY_DESC,
                ]}
                onSelectSort={onSortClick}
                selectedSort={sorting}
              />
              <Box>
                <ClassicButton
                  sx={{ mt: 0 }}
                  startIcon={<AddIcon />}
                  onClick={() => navigate(ROUTE.registerPage)}
                >
                  {t('common.button.add-btn')}
                </ClassicButton>
              </Box>
            </Box>
          </Box>
        }
      >
        {isLoading ? (
          <LoadingController />
        ) : enterprises.total === 0 ? (
          <NoData />
        ) : (
          <>
            <Grid container spacing={2} sx={{ py: 5 }}>
              {enterprises.data.map((item, index) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ display: 'flex' }}
                >
                  <EnterpriseItem
                    key={index}
                    enterprise={item}
                    onEditClick={() => {
                      setSelectedEnterprise(item);
                      setIsDialogOpen(true);
                    }}
                    setRefresh={(value) => setRefresh(value)}
                    permissionGroups={permissionGroups}
                  />
                </Grid>
              ))}
            </Grid>
            <Pagination
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              count={Math.ceil(enterprises.total / ITEMS_PER_PAGE)}
              style={{ margin: '10px 0' }}
              onChange={handlePageChange}
            />
          </>
        )}
      </NodeContainer>

      {isDialogOpen && (
        <EnterpriseDialog
          enterprise={selectedEnterprise!}
          open={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
            setSelectedEnterprise(undefined);
          }}
          setRefresh={(value) => {
            setRefresh(value);
          }}
        />
      )}
    </header>
  );
}
