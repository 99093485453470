import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
const Scrollbar = ({ children }: Props) => {
  return (
    <div
      style={{
        overflowX: 'scroll',
        scrollbarWidth: 'thin',
        scrollbarColor: 'gray lightgray',
        WebkitOverflowScrolling: 'touch',
        border: '1px solid lightgray',
        boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.1)',
      }}
    >
      {children}
    </div>
  );
};

export default Scrollbar;
