import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useAuth } from './AuthProvider';
import useProfile from '../hooks/useProfile';

interface TawkMessengerReactRef {
  setAttributes: (attributes: object, callback: (error: any) => void) => void;
}

const WIDGET_ID_EN = process.env.REACT_APP_TAWK_WIDGET_ID_EN;
const PROPERTY_ID = process.env.REACT_APP_TAWK_PROPERTY_ID;

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const tawkMessengerRef = useRef<TawkMessengerReactRef | null>(null);
  const [loaded, setLoaded] = useState(false);
  const auth = useAuth();
  const profile = useProfile();

  useEffect(() => {
    if (auth.isLoggedIn && profile.currentProfile && loaded)
      setTawkAttributes();
  }, [auth.isLoggedIn, profile.currentProfile]);

  const setTawkAttributes = async () => {
    try {
      if (auth.isLoggedIn && profile.currentProfile) {
        tawkMessengerRef.current?.setAttributes(
          {
            name: `${profile.currentProfile.firstName || ''} ${
              profile.currentProfile.secondName || ''
            }`,
            email: profile.currentProfile.email || '',
            userHash: profile.currentProfile.userHash || '',
          },
          (error: Error) => {
            if (error) console.error('Error setting chat attributes:', error);
          },
        );
      }
    } catch (e) {
      console.error('Error setting chat attributes: ', e);
    }
  };

  const onLoad = async () => {
    await setTawkAttributes();
    setLoaded(true);
  };

  return (
    <div className="App">
      <TawkMessengerReact
        propertyId={PROPERTY_ID}
        widgetId={WIDGET_ID_EN}
        onLoad={onLoad}
        ref={tawkMessengerRef}
      />
      {children}
    </div>
  );
};
