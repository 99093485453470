import api from '../api';
import { useAuth } from '../providers/AuthProvider';
import {
  IConsent,
  IConsentBasicData,
  IConsentOperation,
  IConsentResponse,
} from '../interfaces/consents.interface';
import { IParams } from '../interfaces/request.interface';
import { EMPTY_DATA } from '../constants/common';
import { IModificationResponse } from '../interfaces/response.interface';

export interface ConsentContextType {
  createDraftConsent: (requestBody: IConsentBasicData) => Promise<string>;
  getConsentDrafts: (
    taxIds: string[],
    params: IParams,
  ) => Promise<IConsentResponse>;
  deleteDraftConsent: (consentHash: string) => Promise<void>;
  getConsentsByHashes: (consentHashes: string[]) => Promise<IConsent[]>;
  getConsentVersion: (consentHash: string) => Promise<IConsent[]>;
  getConsents: (taxIds: string[], params: IParams) => Promise<IConsentResponse>;
  getConsentActivity: (consentHash: string) => Promise<IConsentOperation>;
  getOwnConsents: (params: IParams) => Promise<IConsentResponse>;
  getConsentHistory: (consentHash: string) => Promise<IConsentOperation[]>;
  activeConsent: (consentHash: string) => Promise<string>;
  deactivateConsent: (consentHash: string) => Promise<string>;
  signConsents: (consentHashes: string[]) => Promise<string>;
  signConsentsForSomeone(
    userHash: string,
    consentHashes: string[],
    notes: string,
  ): Promise<string>;
  withdrawConsents: (consentHashes: string[]) => Promise<string>;
  withdrawConsentsForSomeone(
    userHash: string,
    consentHashes: string[],
    notes: string,
  ): Promise<string>;
  modifyConsent: (consent: IConsentBasicData) => Promise<IModificationResponse>;
  getConsentSignatureByUserHash: (
    consentHash: string,
    userHash: string,
    timestamp: string,
    rawData?: boolean,
  ) => Promise<string>;
  getConsentSignature: (
    consentHash: string,
    timestamp: string,
  ) => Promise<string>;
}

const useConsents = (): ConsentContextType => {
  const auth = useAuth();

  const createDraftConsent = async (
    requestBody: IConsentBasicData,
  ): Promise<string> => {
    try {
      const response = await api.post('/consents/draft/create', requestBody, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return response.data.consentHash;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const deleteDraftConsent = async (consentHash: string): Promise<void> => {
    try {
      await api.delete(
        `/enterprise/consents/draft?consentHash=${consentHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsentDrafts = async (
    taxIds: string[],
    params: IParams,
  ): Promise<IConsentResponse> => {
    try {
      const response = await api.get<IConsentResponse>(
        `/enterprise/consents/draft`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
          params: {
            taxIds,
            ...params,
          },
        },
      );
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsentsByHashes = async (
    consentHashes: string[],
  ): Promise<IConsent[]> => {
    try {
      const response = await api.get(`/consents`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          consentHashes,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsents = async (
    taxIds: string[],
    params: IParams,
  ): Promise<IConsentResponse> => {
    try {
      const response = await api.get(`/enterprise/consents`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          taxIds,
          ...params,
        },
      });
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsentVersion = async (
    consentHash: string,
  ): Promise<IConsent[]> => {
    try {
      const response = await api.get(`/consents/versions`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          consentHash,
        },
      });

      return response.data ?? [];
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsentActivity = async (
    consentHash: string,
  ): Promise<IConsentOperation> => {
    try {
      const response = await api.get(
        `/consents/activity?consentHash=${consentHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getOwnConsents = async (params: IParams): Promise<IConsentResponse> => {
    try {
      const response = await api.get(`/consents/own`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params,
      });
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsentHistory = async (
    consentHash: string,
  ): Promise<IConsentOperation[]> => {
    try {
      const response = await api.get(
        `/enterprise/consents/history?consentHash=${consentHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const activeConsent = async (consentHash: string): Promise<string> => {
    try {
      const resp = await api.put(
        `/consents/activate`,
        { consentHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const deactivateConsent = async (consentHash: string): Promise<string> => {
    try {
      const resp = await api.put(
        `/consents/deactivate`,
        { consentHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const signConsents = async (consentHashes: string[]): Promise<string> => {
    try {
      const resp = await api.put(
        `/consents/sign`,
        { consentHashes },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const signConsentsForSomeone = async (
    userHash: string,
    consentHashes: string[],
    notes: string,
  ): Promise<string> => {
    try {
      const resp = await api.put(
        `/enterprise/consents/sign`,
        {
          userHash,
          consentHashes,
          notes,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const withdrawConsents = async (consentHashes: string[]): Promise<string> => {
    try {
      const resp = await api.put(
        `/consents/withdraw`,
        { consentHashes },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const withdrawConsentsForSomeone = async (
    userHash: string,
    consentHashes: string[],
    notes: string,
  ): Promise<string> => {
    try {
      const resp = await api.put(
        `/enterprise/consents/withdraw`,
        { userHash, consentHashes, notes },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const modifyConsent = async (
    consent: IConsentBasicData,
  ): Promise<IModificationResponse> => {
    try {
      const resp = await api.put(`/consents/modify`, consent, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsentSignatureByUserHash = async (
    consentHash: string,
    userHash: string,
    timestamp: string,
    rawData?: boolean,
  ): Promise<string> => {
    try {
      const response = await api.get(`/enterprise/consents/signature`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          consentHash,
          userHash,
          timestamp,
          rawData,
        },
      });
      return response.data ? response.data.base64 : '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getConsentSignature = async (
    consentHash: string,
    timestamp: string,
  ): Promise<string> => {
    try {
      const response = await api.get(`/consents/signature`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          consentHash,
          timestamp,
        },
      });
      return response.data ? response.data.base64 : '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getConsentDrafts,
    createDraftConsent,
    deleteDraftConsent,
    getConsentsByHashes,
    getConsents,
    getConsentVersion,
    getConsentActivity,
    getOwnConsents,
    getConsentHistory,
    activeConsent,
    deactivateConsent,
    signConsents,
    signConsentsForSomeone,
    withdrawConsents,
    withdrawConsentsForSomeone,
    modifyConsent,
    getConsentSignatureByUserHash,
    getConsentSignature,
  };
};

export default useConsents;
