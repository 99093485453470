import { useTranslation } from 'react-i18next';
import NoData from '../../../components/utils/NoData';
import {
  IConsent,
  IOperationDetails,
} from '../../../interfaces/consents.interface';
import DynamicTable from '../../../components/builders/DynamicTable';
import moment from 'moment';
import {
  Dialog,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Chip,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PermissionGuard from '../../../guards/PermissionGuard';
import WithdrawIcon from '@mui/icons-material/Cancel';
import SignIcon from '@mui/icons-material/Signpost';
import useConsents from '../../../hooks/useConsents';
import { useEffect, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ConsentPrint from './prints/ConsentPrint';
import { ClassicInput, OutlineButton } from '../../../layouts/styled/buttons';
import { IBasicUser } from '../../../interfaces/profile.interface';
import LoadingController from '../../../components/utils/LoadingController';
import { useOperation } from '../../../providers/OperationProvider';
import { OPERATIONS } from '../../../utils/constants';
import { usePermission } from '../../../providers/PermissionProvider';
import {
  MOBILE_DEVICE_SIGNATURE,
  TRANSACTION_CHECK_TIMEOUT,
} from '../../../constants/common';
import useFiles from '../../../hooks/useFiles';
import useUtils from '../../../hooks/useUtils';
import TransactionStatus from '../../../components/TransactionStatus';
import { Fingerprint } from '@mui/icons-material';
import { getBlockExplorer } from '../../../utils/getLink';

type Props = {
  data: IOperationDetails[];
  consent: IConsent;
  user: IBasicUser;
  setRefresh: (value: number) => void;
};

export default function ConsentActivityTable({
  data,
  consent,
  user,
  setRefresh,
}: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const filesProvider = useFiles();
  const { getTxnStatus } = useUtils();

  const {
    signConsentsForSomeone,
    withdrawConsentsForSomeone,
    getConsentSignature,
    getConsentSignatureByUserHash,
  } = useConsents();
  const permissionsProvider = usePermission();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [txnHash, setTxnHash] = useState<string>();
  const [signature, setSignature] = useState<string>();
  const [attachments, setAttachments] = useState<string[]>([]);
  const [isSign, setIsSign] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IOperationDetails>();
  const [note, setNote] = useState('');
  const [errorBlank, setErrorBlank] = useState('');
  const [documentToPrint, setDocumentToPrint] = useState<IOperationDetails>();

  useEffect(() => {
    if (documentToPrint) {
      fetchSignature();
      fetchAttachmentLinks();
    }
  }, [documentToPrint]);

  useEffect(() => {
    if (!txnHash) {
      setIsDialogOpen(false);
    } else {
      checkTransaction(txnHash);
    }
  }, [txnHash]);

  const checkTransaction = async (txnHash: string) => {
    try {
      const status = await getTxnStatus(txnHash);
      if (status === 2) {
        setTimeout(() => {
          checkTransaction(txnHash);
        }, TRANSACTION_CHECK_TIMEOUT);
      } else {
        setTxnHash(undefined);
        setRefresh(Date.now());
      }
    } catch (e) {
      console.error('Cannot check transaction', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  const fetchSignature = async () => {
    setLoading(true);
    setSignature(
      permissionsProvider.hasFlag(
        'ENTERPRISE_GET_CONSENT_SIGNATURE',
        consent.taxId,
      ) && consent.signatureType === MOBILE_DEVICE_SIGNATURE
        ? await getConsentSignatureByUserHash(
            documentToPrint!.consentHash,
            user.userHash,
            documentToPrint!.timestamp.toString(),
          )
        : await getConsentSignature(
            documentToPrint!.consentHash,
            documentToPrint!.timestamp.toString(),
          ),
    );
    setLoading(false);
  };

  const fetchAttachmentLinks = async () => {
    if (consent.attachments) {
      for (const attachment of consent.attachments) {
        const file = await filesProvider.getReadUrl(
          consent.taxId,
          attachment.name,
        );
        setAttachments((prev) => [...prev, file]);
      }
    }
  };

  const openDialog = async (item: IOperationDetails) => {
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const handleDownloadSignature = async (item: IOperationDetails) => {
    const file = await getConsentSignatureByUserHash(
      item.consentHash,
      user.userHash,
      item.timestamp.toString(),
      true,
    );
    const link = document.createElement('a');
    link.href = file;
    link.download = `signature-${user.userHash}.json`;
    link.click();
  };

  const onConfirmClick = async () => {
    try {
      if (note.trim() === '') {
        setErrorBlank(t('common.forms.errors.field-required') as string);
      } else {
        const txnHash = isSign
          ? await signConsentsForSomeone(
              user.userHash,
              [selectedItem!.consentHash],
              note,
            )
          : await withdrawConsentsForSomeone(
              user.userHash,
              [selectedItem!.consentHash],
              note,
            );
        setTxnHash(txnHash);
      }
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  return (
    <>
      {data.length === 0 ? (
        <NoData />
      ) : (
        <>
          <DynamicTable
            title={''}
            data={data}
            columns={[
              {
                label: t('common.tables.header.operation'),
                accessor: 'isSigned',
                type: 'isSigned',
              },
              {
                label: t('common.tables.header.operationBy'),
                accessor: 'operationBy',
                type: 'operationBy',
              },
              {
                label: t('common.tables.header.userSignature'),
                accessor: 'userSignature',
                type: 'userSignature',
              },
              {
                label: t('common.tables.header.salt'),
                accessor: 'salt',
                type: 'salt',
              },
              {
                label: t('common.tables.header.notes'),
                accessor: 'notes',
                type: 'notes',
              },
              {
                label: t('common.tables.header.operationLog'),
                accessor: (item: IOperationDetails) => {
                  return item && item.txnHash ? (
                    <Typography variant={'body1'}>
                      <a
                        href={`${getBlockExplorer(item.timestamp)}${
                          item.txnHash
                        }`}
                        target="_blank"
                      >
                        {item.txnHash.slice(0, 4)}...
                        {item.txnHash.slice(-3)}
                      </a>
                    </Typography>
                  ) : (
                    <Chip
                      label={t('common.no-data')}
                      color="default"
                      variant="outlined"
                      size="small"
                    />
                  );
                },
                type: 'txnHash',
              },
              {
                label: t('common.tables.header.created-at'),
                accessor: 'timestamp',
                type: 'timestamp',
                formatter: (value: number) => (
                  <span>
                    {moment.unix(value).format('DD-MM-YYYY HH:mm:ss')}
                  </span>
                ),
              },
            ]}
            onHeaderClick={() => {}}
            onItemClick={() => {}}
            onExpandClick={() => {}}
            actions={[
              (item) => (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setDocumentToPrint(item);
                    setIsPrintDialogOpen(true);
                  }}
                >
                  <PrintIcon />
                </IconButton>
              ),
              (item) =>
                consent.signatureType === MOBILE_DEVICE_SIGNATURE && (
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleDownloadSignature(item);
                    }}
                  >
                    <Fingerprint />
                  </IconButton>
                ),
              (item) =>
                item === data[0] &&
                item.isSigned && (
                  <PermissionGuard
                    permissions={['ENTERPRISE_WITHDRAW_CONSENT']}
                    taxId={consent.taxId}
                  >
                    <IconButton
                      onClick={() => {
                        setIsSign(false);
                        openDialog(item);
                      }}
                      color="primary"
                    >
                      <WithdrawIcon />
                    </IconButton>
                  </PermissionGuard>
                ),
              (item) =>
                item === data[0] &&
                !item.isSigned && (
                  <PermissionGuard
                    permissions={['ENTERPRISE_SIGN_CONSENT']}
                    taxId={consent.taxId}
                  >
                    <IconButton
                      onClick={() => {
                        setIsSign(true);
                        openDialog(item);
                      }}
                      color="secondary"
                    >
                      <SignIcon />
                    </IconButton>
                  </PermissionGuard>
                ),
            ]}
          />

          {documentToPrint && (
            <Dialog
              open={isPrintDialogOpen}
              onClose={() => setIsPrintDialogOpen(false)}
            >
              <DialogTitle>
                <Typography variant={'h5'}>
                  {t('common.document-generation')}
                </Typography>
              </DialogTitle>
              <DialogContent>
                {!isLoading ? (
                  <PDFDownloadLink
                    document={
                      <ConsentPrint
                        consent={consent}
                        user={user}
                        operations={[documentToPrint]}
                        attachments={attachments}
                        signature={signature}
                      />
                    }
                    fileName={`consent-${user!.userHash}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (
                      <OutlineButton
                        sx={{ margin: '0 auto', marginTop: '10px' }}
                        disabled={loading}
                        onClick={() => {
                          setIsPrintDialogOpen(false);
                        }}
                      >
                        {t('common.button.download-btn')}
                      </OutlineButton>
                    )}
                  </PDFDownloadLink>
                ) : (
                  <LoadingController />
                )}
              </DialogContent>
            </Dialog>
          )}

          <Dialog
            open={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
              setNote('');
            }}
          >
            {!(isLoading || txnHash) && (
              <DialogTitle>
                <Typography variant={'h5'}>
                  {isSign
                    ? t('common.confirm-consent-sign')
                    : t('common.confirm-consent-revoke')}
                </Typography>
              </DialogTitle>
            )}
            <DialogContent>
              {isLoading || txnHash ? (
                <>
                  {txnHash ? (
                    <TransactionStatus txnHash={txnHash} />
                  ) : (
                    <LoadingController />
                  )}
                </>
              ) : (
                <ClassicInput
                  label={
                    isSign
                      ? t('common.sign-reason')
                      : t('common.withdraw-reason')
                  }
                  multiline
                  margin="normal"
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                    setErrorBlank('');
                  }}
                  error={!!errorBlank}
                  helperText={errorBlank}
                />
              )}
            </DialogContent>
            {!(isLoading || txnHash) && (
              <DialogActions>
                <OutlineButton
                  sx={{ width: '150px' }}
                  onClick={() => {
                    setIsDialogOpen(false);
                    setNote('');
                  }}
                >
                  {t('common.button.cancel-btn')}
                </OutlineButton>
                <OutlineButton sx={{ width: '150px' }} onClick={onConfirmClick}>
                  {t('common.button.confirm-btn')}
                </OutlineButton>
              </DialogActions>
            )}
          </Dialog>
        </>
      )}
    </>
  );
}
