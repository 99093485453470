import { useAuth } from '../providers/AuthProvider';
import api from '../api';
import {
  IProjectStatistics,
  IProjectUserData,
  IProjectResponse,
  IAddUserToProjectRequest,
  IProjectStatisticsResponse,
  IProject,
} from '../interfaces/projects.interface';
import { IParams } from '../interfaces/request.interface';
import { EMPTY_DATA } from '../constants/common';
import { IModificationResponse } from '../interfaces/response.interface';

interface ProjectContextType {
  getProjectStatistics: (projectHash: string) => Promise<IProjectStatistics>;
  getProjects: (params: IParams) => Promise<IProjectResponse>;
  getDeactivatedProjects: (
    taxIds: string[],
    params: IParams,
  ) => Promise<IProjectResponse>;
  getProject: (projectHash: string) => Promise<IProject | null>;
  getProjectOverview: (projectHash: string) => Promise<IProjectUserData[]>;
  getProjectActivity: (projectHash: string) => Promise<IProjectUserData[]>;
  generateAccessKey: (projectHash: string) => Promise<string>;
  deleteAccessKey: (projectHash: string) => Promise<void>;
  createProject: (project: IProject) => Promise<string>;
  modifyProject: (project: IProject) => Promise<IModificationResponse>;
  addUserToProject: (requestBody: IAddUserToProjectRequest) => Promise<string>;
  inviteUserToProject: (
    projectHash: string,
    email: string,
    language?: string,
    accessKey?: string,
    captchaToken?: string,
  ) => Promise<string>;
  joinProject: (requestBody: IAddUserToProjectRequest) => Promise<string>;
  removeUserFromProject: (
    projectHash: string,
    userHash: string,
  ) => Promise<void>;
  activateProject: (projectHash: string) => Promise<void>;
  deactivateProject: (projectHash: string) => Promise<void>;
  likeProject: (projectHash: string) => Promise<void>;
  dislikeProject: (projectHash: string) => Promise<void>;
  getProjectVersions: (projectHash: string) => Promise<IProject[]>;
}

export const DEFAULT_STATISTICS = {
  today: 0,
  yesterday: 0,
  thisWeek: 0,
  thisMonth: 0,
  lastMonth: 0,
  all: 0,
};

const useProjects = (): ProjectContextType => {
  const auth = useAuth();

  const getProjectStatistics = async (
    projectHash: string,
  ): Promise<IProjectStatistics> => {
    try {
      const response = await api.get<IProjectStatisticsResponse>(
        `/projects/users/statistics?projectHash=${projectHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data.statistics ?? DEFAULT_STATISTICS;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getProjects = async (params: IParams): Promise<IProjectResponse> => {
    try {
      const response = await api.get<IProjectResponse>(`/projects`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params,
      });
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const modifyProject = async (
    project: IProject,
  ): Promise<IModificationResponse> => {
    try {
      const resp = await api.put('/projects/modify', project, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getDeactivatedProjects = async (
    taxIds: string[],
    params: IParams,
  ): Promise<IProjectResponse> => {
    try {
      const response = await api.get<IProjectResponse>(
        `/projects/deactivated`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
          params: {
            taxIds,
            ...params,
          },
        },
      );
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getProject = async (projectHash: string): Promise<IProject | null> => {
    try {
      const response = await api.get<IProject>(
        `/project?projectHash=${projectHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.projectToken || auth.getToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getProjectOverview = async (
    projectHash: string,
  ): Promise<IProjectUserData[]> => {
    try {
      const response = await api.get<IProjectUserData[]>(
        `/projects/overview?projectHash=${projectHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getProjectActivity = async (
    projectHash: string,
  ): Promise<IProjectUserData[]> => {
    try {
      const response = await api.get<IProjectUserData[]>(
        `/projects/activity?projectHash=${projectHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const createProject = async (project: IProject) => {
    try {
      const response = await api.post('/projects/create', project, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return response.data.projectHash;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const generateAccessKey = async (projectHash: string): Promise<string> => {
    try {
      const response = await api.post(
        '/projects/key/create',
        { projectHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data.accessKey;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const deleteAccessKey = async (projectHash: string): Promise<void> => {
    try {
      await api.delete(`/projects/key?projectHash=${projectHash}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const addUserToProject = async (
    requestBody: IAddUserToProjectRequest,
  ): Promise<string> => {
    try {
      const resp = await api.put('/projects/users/add', requestBody, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const inviteUserToProject = async (
    projectHash: string,
    email: string,
    language: string = 'en',
    accessKey?: string,
    captchaToken?: string,
  ): Promise<string> => {
    try {
      let headers = {};
      if (!accessKey)
        headers = {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        };

      const resp = await api.put(
        '/projects/users/invite',
        {
          projectHash,
          email,
          language,
          accessKey,
          captchaToken,
        },
        headers,
      );
      return resp.data.inviteLink || '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const joinProject = async (
    requestBody: IAddUserToProjectRequest,
  ): Promise<string> => {
    try {
      const resp = await api.put('/projects/users/join', requestBody, {
        headers: {
          Authorization: `Bearer ${auth.projectToken}`,
        },
      });
      return resp.data.txnHash ?? '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const removeUserFromProject = async (
    projectHash: string,
    userHash: string,
  ): Promise<void> => {
    try {
      await api.put(
        '/projects/users/remove',
        { projectHash, userHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const activateProject = async (projectHash: string): Promise<void> => {
    try {
      await api.put(
        '/projects/activate',
        { projectHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const deactivateProject = async (projectHash: string): Promise<void> => {
    try {
      await api.put(
        '/projects/deactivate',
        { projectHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const likeProject = async (projectHash: string): Promise<void> => {
    try {
      await api.put(
        '/projects/like',
        { projectHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const dislikeProject = async (projectHash: string): Promise<void> => {
    try {
      await api.put(
        '/projects/dislike',
        { projectHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getProjectVersions = async (
    projectHash: string,
  ): Promise<IProject[]> => {
    try {
      const response = await api.get(`/projects/versions`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: { projectHash },
      });
      return response.data ?? [];
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getProjects,
    getDeactivatedProjects,
    getProject,
    getProjectOverview,
    getProjectActivity,
    createProject,
    modifyProject,
    addUserToProject,
    inviteUserToProject,
    joinProject,
    getProjectStatistics,
    removeUserFromProject,
    activateProject,
    deactivateProject,
    likeProject,
    dislikeProject,
    getProjectVersions,
    generateAccessKey,
    deleteAccessKey,
  };
};

export default useProjects;
