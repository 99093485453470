import './default.css';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import FlagSelector from '../components/appbar/FlagSelector';
import ContactSupport from '../components/appbar/ContactSupport';
import { ThemeProvider } from '@mui/material/styles';
import { ReactNode } from 'react';
import { layoutTheme } from './styled/layout';

interface Props {
  children?: ReactNode;
}

export default function DefaultLayout({ children }: Props) {
  return (
    <ThemeProvider theme={layoutTheme}>
      <div className="App">
        <Helmet>
          <title>Data Lake Application</title>
          <meta name="description" content="Data Lake Application" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </Helmet>

        <AppBar position="static" className={'drawer'}>
          <Toolbar
            disableGutters
            style={{ justifyContent: 'space-between', margin: 10 }}
          >
            <a href="/">
              <img
                src={'/logo.svg'}
                alt="logo"
                style={{ width: '15rem', marginLeft: '1rem' }}
              />
            </a>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <ContactSupport />
              </Box>
              <FlagSelector />
            </div>
          </Toolbar>
        </AppBar>

        <main>
          <Outlet /> {children}
        </main>
      </div>
    </ThemeProvider>
  );
}
