import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Chip,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { IBasicUser, IProfile } from '../../../../interfaces/profile.interface';
import useProfile from '../../../../hooks/useProfile';
import {
  ClassicButton,
  ClassicInput,
} from '../../../../layouts/styled/buttons';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { useOperation } from '../../../../providers/OperationProvider';
import { COLORS, OPERATIONS } from '../../../../utils/constants';
import { ContentCopy } from '@mui/icons-material';
import { FaKey } from 'react-icons/fa';
import { useAuth } from '../../../../providers/AuthProvider';
import { validatePhone } from '../../../../utils/validators';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

const emptyProfile: IBasicUser = {
  userHash: '',
  phone: '',
  email: '',
  firstName: '',
  secondName: '',
  personalId: '',
};

interface Props {
  profile: IProfile;
}

export default function ProfileItem({ profile }: Props) {
  const { t } = useTranslation();
  const profileProvider = useProfile();
  const [editMode, setEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<IBasicUser>(emptyProfile);
  const [smsCode, setSmsCode] = useState<string[]>(['', '', '', '', '', '']);
  const [smsConfirmation, setSmsConfirmation] = useState(false);
  const [modificationField, setModificationField] = useState<string>();
  const authProvider = useAuth();
  const { setOperation } = useOperation();

  useEffect(() => {
    setFormData({
      userHash: profile?.userHash || '',
      phone: profile?.phone || '',
      email: profile?.email || '',
      firstName: profile?.firstName || '',
      secondName: profile?.secondName || '',
      personalId: profile?.personalId || '',
    });
  }, [profile]);

  const handleInputChange = (field: keyof IBasicUser, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleCodeChange = (index: number, value: string) => {
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 1);
    const newSmsCode = [...smsCode];
    newSmsCode[index] = sanitizedValue;
    setSmsCode(newSmsCode);
    if (sanitizedValue && index < 5) {
      document.getElementById(`codeInput${index + 1}`)?.focus();
    }
  };

  useEffect(() => {
    handleValidate();
  }, [formData]);

  useEffect(() => {
    if (!editMode) setModificationField('');
  }, [editMode]);

  const handleValidate = () => {
    const newErrors: Record<string, string> = {};
    (Object.keys(formData) as Array<keyof IBasicUser>)
      .filter((field) => field === 'email' || field === 'phone')
      .forEach((field) => {
        if (formData[field] === '') {
          newErrors[field] = t('common.forms.errors.field-required');
        }
        if (formData.phone !== '' && !validatePhone(formData.phone)) {
          newErrors.phone = t('common.forms.errors.invalid-phone');
        }
      });
    setFormErrors(newErrors);
  };

  const handleCodeKeyPress = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (event.key === 'Backspace' && index > 0) {
      document.getElementById(`codeInput${index - 1}`)?.focus();
    } else if (event.key === 'ArrowLeft' && index > 0) {
      document.getElementById(`codeInput${index - 1}`)?.focus();
    } else if (event.key === 'ArrowRight' && index < 5) {
      document.getElementById(`codeInput${index + 1}`)?.focus();
    }
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        if (Object.keys(formErrors).length > 0) {
          return;
        }
        if (modificationField === 'phone') {
          if (smsConfirmation) {
            await authProvider.verifySmsCode(smsCode.join(''));
            profileProvider.setRefresh(Date.now());
            setSmsConfirmation(false);
            setEditMode(false);
            setOperation({
              severity: OPERATIONS.SUCCESS,
              message: t('pages.operations.messages.user-modified'),
            });
          } else {
            setSmsConfirmation(true);
            await authProvider.sendSms(formData.phone);
          }
        } else {
          await profileProvider.modifyProfile(formData);
          profileProvider.setRefresh(Date.now());
          setEditMode(false);
          setOperation({
            severity: OPERATIONS.SUCCESS,
            message: t('pages.operations.messages.user-modified'),
          });
        }
      } else {
        setEditMode(true);
      }
    } catch (error) {
      console.error('Cannot Submit Update:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  return (
    <Card sx={{ p: 5 }}>
      <Typography variant={'h3'} sx={{ paddingBottom: '10px' }}>
        {t('pages.profile.header')}
      </Typography>

      {smsConfirmation ? (
        <>
          <Box
            component={FaKey}
            sx={{
              width: '10%',
              height: 'auto',
              color: 'primary.main',
              marginY: '20px',
            }}
          />
          <Typography variant="body1" sx={{ paddingBottom: '10px' }}>
            {t('common.enter-code')}: <br />
            <b>{profile.phone || ''}</b>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            {smsCode.map((code, index) => (
              <TextField
                key={index}
                id={`codeInput${index}`}
                variant="outlined"
                value={code}
                onChange={(event) =>
                  handleCodeChange(index, event.target.value)
                }
                onKeyUp={(event) => handleCodeKeyPress(index, event)}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: 'center', fontSize: '1.5em' },
                }}
                sx={{ margin: '0 8px' }}
              />
            ))}
          </Box>
          <Container
            maxWidth={false}
            sx={{
              height: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '20px',
            }}
          >
            <ClassicButton onClick={() => handleSubmit()}>
              {t('common.button.verify-btn')}
            </ClassicButton>
          </Container>
        </>
      ) : (
        <Grid container spacing={2} padding={2} textAlign={'left'}>
          {Object.keys(formData).map((field) => (
            <React.Fragment key={field}>
              {field === 'userHash' ? (
                <>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Typography variant="h4">
                      {t(`common.profile.${field}`)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {formData.userHash.slice(0, 7)}...
                        {formData.userHash.slice(-6)}
                      </Typography>
                      <ContentCopy
                        style={{
                          color: COLORS.primaryColor,
                          cursor: 'pointer',
                          marginLeft: '10px',
                          transition: 'color 0.2s',
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(formData.userHash);
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.color = COLORS.accentColor;
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.color = COLORS.primaryColor;
                        }}
                      />
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Typography variant="h4">
                      {t(`common.profile.${field}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {modificationField === field ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {field === 'phone' ? (
                          <Box>
                            <PhoneInput
                              value={formData.phone}
                              onChange={(e) =>
                                handleInputChange(
                                  'phone',
                                  e.length > 0 ? '+' + e : e,
                                )
                              }
                              isValid={!!formErrors.phone}
                              country={'pl'}
                              inputStyle={{
                                width: '100%',
                                color: COLORS.primaryColor,
                                borderColor: formErrors.phone
                                  ? COLORS.fieldErrorColor
                                  : COLORS.accentColor,
                                borderRadius: '20px',
                              }}
                              dropdownStyle={{ height: '300px' }}
                            />
                            {formErrors.phone && (
                              <Typography
                                variant="caption"
                                sx={{
                                  color: formErrors.phone
                                    ? COLORS.fieldErrorColor
                                    : COLORS.accentColor,
                                  marginLeft: '15px',
                                  marginTop: '2.5px',
                                  textAlign: 'left',
                                }}
                              >
                                {formErrors.phone}
                              </Typography>
                            )}
                          </Box>
                        ) : (
                          <ClassicInput
                            fullWidth
                            label={t(`common.profile.${field}`)}
                            value={formData[field as keyof IBasicUser] || ''}
                            error={!!formErrors[field]}
                            helperText={formErrors[field]}
                            onChange={(e) =>
                              handleInputChange(
                                field as keyof IBasicUser,
                                e.target.value,
                              )
                            }
                          />
                        )}
                        <IconButton
                          sx={{
                            mt: 0,
                            ml: 3,
                            cursor: 'pointer',
                            color: COLORS.primaryColor,
                          }}
                          onClick={handleSubmit}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            mt: 0,
                            ml: 2,
                            cursor: 'pointer',
                            color: COLORS.primaryColor,
                          }}
                          onClick={() => setEditMode(false)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">
                          {formData[field as keyof IBasicUser] !== '' ? (
                            formData[field as keyof IBasicUser]
                          ) : (
                            <Chip
                              label={t('common.no-data')}
                              color="default"
                              variant="outlined"
                              size="small"
                              style={{ marginLeft: '8px' }}
                            />
                          )}
                        </Typography>
                        <IconButton
                          sx={{
                            mt: 0,
                            ml: 3,
                            cursor: 'pointer',
                            color: COLORS.primaryColor,
                          }}
                          onClick={() => {
                            setEditMode(true);
                            setModificationField(field);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Card>
  );
}
