import { useEffect, useState } from 'react';
import {
  Box,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  ClassicButton,
  ClassicInput,
  OutlineButton,
} from '../../../layouts/styled/buttons';
import { useTranslation } from 'react-i18next';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { IProject } from '../../../interfaces/projects.interface';
import { COLORS, REQUIRED_FIELDS } from '../../../utils/constants';
import { validateEmail, validatePhone } from '../../../utils/validators';
import { MOBILE_DEVICE_SIGNATURE } from '../../../constants/common';
import { IConsentAttachment } from '../../../interfaces/consents.interface';
import { ROUTE } from '../../../utils/route';
import { useNavigate } from 'react-router-dom';
import useFiles from '../../../hooks/useFiles';
import { useAuth } from '../../../providers/AuthProvider';
import { SignatureProps } from '../../../interfaces/signature.interface';
import SignaturePad from './../../../components/actions/SignaturePad';

interface Props {
  isOnline: boolean;
  project: IProject;
  onButtonClick: (
    formData: Record<string, string>,
    consentHashes: string[],
    signature?: SignatureProps,
  ) => void;
}

const initData = {
  email: '',
  firstName: '',
  secondName: '',
  phone: '',
  personalId: '',
  checkbox: 'false',
};

export default function UserDataAndConsents({
  isOnline,
  project,
  onButtonClick,
}: Props) {
  const navigate = useNavigate();
  const authProvider = useAuth();
  const { i18n, t } = useTranslation();
  const [isSignatureRequired, setIsSignatureRequired] =
    useState<boolean>(false);
  const [isSignaturePad, setIsSignaturePad] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, string>>(initData);
  const [switchStates, setSwitchStates] = useState<boolean[]>([]);
  const filesProvider = useFiles();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>(
    {},
  );
  const [signature, setSignature] = useState<SignatureProps>();
  useEffect(() => {
    setSwitchStates(project.consents.map(() => false));
    setIsSignatureRequired(
      project.consents.some(
        (el) => el.signatureType === MOBILE_DEVICE_SIGNATURE && el.isObligatory,
      ),
    );
  }, []);

  useEffect(() => {
    let newErrors: Record<string, string> = {};
    project.requiredFields.forEach((field) => {
      if (field === REQUIRED_FIELDS[4]) {
        if (formData.checkbox === 'false' && !isOnline) {
          newErrors = {
            ...newErrors,
            checkbox: t('common.forms.errors.field-required'),
          };
        }
      } else if (field === REQUIRED_FIELDS[2]) {
        if (!isOnline && formData[field] === '') {
          newErrors = {
            ...newErrors,
            email: t('common.forms.errors.field-required'),
          };
        }
      } else if (formData[field] === '') {
        newErrors = {
          ...newErrors,
          [field]: t('common.forms.errors.field-required'),
        };
      }
    });

    if (formData.phone.length > 0 && !validatePhone(formData.phone)) {
      newErrors = {
        ...newErrors,
        phone: t('common.forms.errors.invalid-phone'),
      };
    }
    if (formData.email.length > 0 && !validateEmail(formData.email)) {
      newErrors = {
        ...newErrors,
        email: t('common.forms.errors.invalid-email'),
      };
    }
    if (
      switchStates.some(
        (el, index) => !el && project.consents[index].isObligatory,
      )
    ) {
      newErrors = {
        ...newErrors,
        switchStates: t('common.forms.errors.consents-required'),
      };
    }
    if (isSignatureRequired && !signature) {
      newErrors = {
        ...newErrors,
        signature: t('common.forms.errors.signature-required'),
      };
    }
    setErrors(newErrors);
  }, [formData, switchStates, isSignaturePad]);

  const handleOpenAttachment = async (
    taxId: string,
    attachment: IConsentAttachment,
  ) => {
    const downloadCache = await filesProvider.getReadUrl(
      taxId,
      attachment.name,
    );
    window.open(downloadCache, '_blank');
  };

  const handleSwitchChange = (index: number) => () => {
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index] = !updatedSwitchStates[index];
    setSwitchStates(updatedSwitchStates);
    setTouchedFields((prev) => ({
      ...prev,
      switchStates: true,
    }));
  };

  const onNextClick = () => {
    onButtonClick(
      formData,
      project.consents
        .filter((el, index) => switchStates[index])
        .map((el) => el.consentHash),
      signature,
    );
  };

  const handleInputChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  const isFormValid = (): boolean =>
    !Object.entries(errors).some((el) => el[0] !== '');

  const openTerm = () => {
    const pdfUrl =
      i18n.language === 'pl'
        ? '/documents/tos-pl.pdf'
        : '/documents/tos-eng.pdf';

    window.open(pdfUrl, '_blank');
  };

  const openPP = () => {
    const pdfUrl =
      i18n.language === 'pl' ? '/documents/pp-pl.pdf' : '/documents/pp-eng.pdf';

    window.open(pdfUrl, '_blank');
  };

  const onCancelClick = () => {
    if (isOnline) authProvider.signOut();
    navigate(ROUTE.dashboardPage.projects.root);
  };

  return (
    <>
      <CardHeader
        title={
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '2.5rem', md: '3rem' } }}
            >
              {t('common.forms.titles.user-data')}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Container
          maxWidth={false}
          sx={{
            height: '100%',
            textAlign: 'center',
          }}
        >
          <Grid alignItems="center" justifyContent="center">
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {project.requiredFields.includes(REQUIRED_FIELDS[0]) && (
                <Grid item xs={10}>
                  <ClassicInput
                    label={t(`common.profile.firstName`)}
                    required
                    fullWidth
                    onChange={(e) =>
                      handleInputChange(REQUIRED_FIELDS[0], e.target.value)
                    }
                    error={touchedFields.firstName && !!errors.firstName}
                    helperText={touchedFields.firstName && errors.firstName}
                  />
                </Grid>
              )}
              {project.requiredFields.includes(REQUIRED_FIELDS[1]) && (
                <Grid item xs={10}>
                  <ClassicInput
                    label={t(`common.profile.secondName`)}
                    required
                    fullWidth
                    onChange={(e) =>
                      handleInputChange(REQUIRED_FIELDS[1], e.target.value)
                    }
                    error={touchedFields.secondName && !!errors.secondName}
                    helperText={touchedFields.secondName && errors.secondName}
                  />
                </Grid>
              )}

              {project.requiredFields.includes(REQUIRED_FIELDS[5]) && (
                <Grid item xs={10}>
                  <ClassicInput
                    label={t(`common.profile.personalId`)}
                    required
                    fullWidth
                    onChange={(e) =>
                      handleInputChange(REQUIRED_FIELDS[5], e.target.value)
                    }
                    error={touchedFields.personalId && !!errors.personalId}
                    helperText={touchedFields.personalId && errors.personalId}
                  />
                </Grid>
              )}
              {!isOnline && (
                <Grid item xs={10}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                    }}
                  >
                    <ClassicInput
                      label={t(`common.profile.email`)}
                      required={project.requiredFields.includes(
                        REQUIRED_FIELDS[2],
                      )}
                      fullWidth
                      onChange={(e) =>
                        handleInputChange(REQUIRED_FIELDS[2], e.target.value)
                      }
                      error={touchedFields.email && !!errors.email}
                      helperText={touchedFields.email && errors.email}
                    />
                    {!project.requiredFields.includes(REQUIRED_FIELDS[2]) && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: COLORS.accentColor,
                          marginLeft: '15px',
                          marginTop: '2.5px',
                          textAlign: 'left',
                        }}
                      >
                        {!errors.email &&
                          t('common.profile.phone-email-statement')}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )}
              <Grid item xs={10} key={'phone'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}
                >
                  <PhoneInput
                    onChange={(value, data: CountryData) => {
                      handleInputChange(
                        REQUIRED_FIELDS[3],
                        value.length !== data.dialCode.length
                          ? '+' + value
                          : '',
                      );
                    }}
                    country={'pl'}
                    countryCodeEditable={false}
                    inputStyle={{
                      width: '100%',
                      color: COLORS.primaryColor,
                      borderColor:
                        touchedFields.phone && errors.phone
                          ? COLORS.errorColor
                          : COLORS.accentColor,
                      borderRadius: '20px',
                    }}
                    dropdownStyle={{ height: '300px' }}
                  />
                  {touchedFields.phone && errors.phone && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: COLORS.errorColor,
                        marginLeft: '15px',
                        marginTop: '2.5px',
                        textAlign: 'left',
                      }}
                    >
                      {errors.phone}
                    </Typography>
                  )}

                  {!project.requiredFields.includes(REQUIRED_FIELDS[3]) &&
                    !isOnline && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: COLORS.accentColor,
                          marginLeft: '15px',
                          marginTop: '2.5px',
                          textAlign: 'left',
                        }}
                      >
                        {!errors.phone &&
                          t('common.profile.phone-email-statement')}
                      </Typography>
                    )}
                </Box>
              </Grid>

              {project.requiredFields.includes(REQUIRED_FIELDS[4]) &&
                !isOnline && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.checkbox === 'true'}
                          onChange={() =>
                            handleInputChange(
                              'checkbox',
                              formData.checkbox === 'true' ? 'false' : 'true',
                            )
                          }
                          color="primary"
                        />
                      }
                      label={t('common.forms.labels.sub-account-statement')}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Container>
      </CardContent>
      <CardHeader
        title={
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '2.5rem', md: '3rem' } }}
            >
              {t('userflow.consent.title')}
            </Typography>
          </Box>
        }
        sx={{ borderTop: `1px solid ${COLORS.accentColor}` }}
      />
      <CardContent>
        {project.consents.map((consent, index) => (
          <Grid container xs={12} sx={{ mb: 4 }} key={index}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'start',
              }}
            >
              <Typography
                variant={'subtitle1'}
                sx={{ fontSize: '1.25rem', textAlign: 'center' }}
              >
                {consent.title}
              </Typography>
              <Typography
                variant={'body2'}
                sx={{ mt: '5px', whiteSpace: 'pre-line' }}
              >
                <strong>{t('common.forms.labels.consent-content')}:</strong>{' '}
                {consent.content}
              </Typography>
              {consent.informationObligation && (
                <Typography
                  variant="body2"
                  sx={{
                    mt: '5px',
                    whiteSpace: 'pre-line',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  <strong>
                    {t('common.forms.labels.information-obligation')}:
                  </strong>{' '}
                  {consent.informationObligation}
                </Typography>
              )}
              {consent.attachments?.length > 0 && (
                <Typography
                  variant={'body2'}
                  sx={{ mt: '5px', whiteSpace: 'pre-line' }}
                >
                  <strong>
                    {consent.attachments?.length > 0
                      ? t('common.forms.labels.attachments') + ':'
                      : ''}
                  </strong>{' '}
                  {consent.attachments?.map((attachment, index) => (
                    <>
                      <a
                        key={index}
                        onClick={() =>
                          handleOpenAttachment(consent.taxId, attachment)
                        }
                        style={{
                          cursor: 'pointer',
                          color: 'blue',
                          textDecoration: 'underline',
                        }}
                      >
                        {attachment.displayName}
                      </a>
                      <a>
                        {index < consent.attachments?.length - 1 ? ', ' : ''}
                      </a>
                    </>
                  ))}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant={'body1'}>
                  {t('common.forms.labels.no')}
                </Typography>
                <Switch
                  checked={switchStates[index]}
                  onChange={handleSwitchChange(index)}
                />
                <Typography variant={'body1'}>
                  {t('common.forms.labels.yes')}
                </Typography>
              </Box>
              {touchedFields.switchStates &&
                errors.switchStates &&
                project.consents[index].isObligatory &&
                !switchStates[index] && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: COLORS.errorColor,
                      textAlign: 'left',
                    }}
                  >
                    {errors.switchStates}
                  </Typography>
                )}
              {!consent.isObligatory && (
                <Typography variant={'body1'}>
                  {t('common.optional')}
                </Typography>
              )}
            </Grid>
          </Grid>
        ))}

        {project.consents.some(
          (el) => el.signatureType === MOBILE_DEVICE_SIGNATURE,
        ) &&
          (signature ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '80%',
                maxWidth: '500px',
                margin: '0 auto',
              }}
            >
              <img src={signature.image} width={'100%'} />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <OutlineButton
                sx={{
                  width: '10rem',
                  mb: 5,
                }}
                onClick={() => setIsSignaturePad(true)}
              >
                {t(`common.button.sign-btn`)}
              </OutlineButton>
              {touchedFields.signature && errors.signature && (
                <Typography
                  variant="caption"
                  sx={{
                    color: COLORS.errorColor,
                    marginLeft: '15px',
                    marginTop: '2.5px',
                    textAlign: 'center',
                    minWidth: { xs: 'auto', md: '500px' },
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {errors.signature}
                </Typography>
              )}
            </Box>
          ))}

        <Typography component="p" variant="body1">
          {isOnline
            ? t('common.annotations.user-flow.online.part1')
            : t('common.annotations.user-flow.visit')}
          <Typography
            component="span"
            variant="body2"
            color="primary"
            onClick={openTerm}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {t('common.annotations.user-flow.terms')}
          </Typography>
          {t('common.annotations.user-flow.online.part2')}
          <Typography
            component="span"
            variant="body2"
            color="primary"
            onClick={openPP}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {t('common.annotations.user-flow.online.part3')}
          </Typography>
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Tooltip
            title={`${t('common.annotations.data-wont-be-saved')}`}
            placement="bottom"
          >
            <OutlineButton
              sx={{
                width: '10rem',
                borderColor: COLORS.errorColor,
                color: COLORS.errorColor,
                outline: `1px solid ${COLORS.errorColor}`,
                ml: { xs: 0, md: 3 },
              }}
              onClick={onCancelClick}
            >
              {t('common.button.cancel-btn')}
            </OutlineButton>
          </Tooltip>

          <Tooltip
            title={`${t('common.annotations.data-will-be-saved')}`}
            placement="bottom"
          >
            <span
              onMouseOver={() =>
                [
                  ...project.requiredFields,
                  'switchStates',
                  ...(isSignatureRequired ? ['signature'] : []),
                ].forEach((field) => {
                  setTouchedFields((prev) => ({
                    ...prev,
                    [field]: true,
                  }));
                })
              }
            >
              <ClassicButton
                disabled={!isFormValid()}
                sx={{
                  width: '10rem',
                  ml: { xs: 0, md: 3 },
                }}
                onClick={onNextClick}
              >
                {t(`common.button.confirm-btn`)}
              </ClassicButton>
            </span>
          </Tooltip>
        </Box>
      </CardContent>

      {isSignaturePad && (
        <SignaturePad
          onButtonClick={(value) => {
            setSignature(value);
            setIsSignaturePad(false);
          }}
        />
      )}
    </>
  );
}
