import { ReactInviteButton, IFormData, IStatus } from 'datalake-widget';
import 'datalake-widget/dist/style.css';
import { Box } from '@mui/material';
import {
  DEMO_PROJECT_ACCESS_KEY_1,
  DEMO_PROJECT_ACCESS_KEY_2,
  DEMO_PROJECT_EMAIL,
  DEMO_PROJECT_HASH_1,
  DEMO_PROJECT_HASH_2,
  DEMO_PROJECT_TITLE,
} from '../constants/common';

export default function WidgetPage() {
  const onFormSubmit = (formData: IFormData) => {
    console.log('Form Submitted with:');
    console.log(formData);
  };

  const onTransactionStatusUpdate = (status: IStatus) => {
    console.log('Transaction status:');
    console.log(status);
  };

  const onError = (error: string) => {
    console.log('Something has gone wrong:');
    console.log(error);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 5,
        bgcolor: '#efefef',
        width: 'fit-content',
        margin: '100px auto',
        borderRadius: '10px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 0 }}>
          <ReactInviteButton
            accessKey={DEMO_PROJECT_ACCESS_KEY_1}
            projectHash={DEMO_PROJECT_HASH_1}
            title={DEMO_PROJECT_TITLE}
            onFormSubmit={onFormSubmit}
            onTransactionStatusUpdate={onTransactionStatusUpdate}
            onError={onError}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            m: 0,
            my: 'auto',
          }}
        >
          <ReactInviteButton
            accessKey="your-access-key"
            projectHash="your-project-hash"
            email={DEMO_PROJECT_EMAIL}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 0 }}>
          <ReactInviteButton
            accessKey={DEMO_PROJECT_ACCESS_KEY_2}
            projectHash={DEMO_PROJECT_HASH_2}
            onFormSubmit={onFormSubmit}
            onTransactionStatusUpdate={onTransactionStatusUpdate}
            onError={onError}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 0 }}>
          <ReactInviteButton
            accessKey="your-project-hash"
            projectHash="your-project-hash"
            title={DEMO_PROJECT_TITLE}
            email={DEMO_PROJECT_EMAIL}
          />
        </Box>
      </Box>
    </Box>
  );
}
