import ReactPDF, { StyleSheet } from '@react-pdf/renderer';
import Font = ReactPDF.Font;

Font.registerHyphenationCallback((word) => [word]);
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const PDFStyle = StyleSheet.create({
  page: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: 'Roboto',
  },
  section: {
    margin: 10,
    padding: 1,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 20,
  },
  text: {
    fontSize: 12,
    marginBottom: 20,
  },
  separator: {
    borderBottom: '1px solid #000',
    marginBottom: 20,
  },
  signatureTitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  infoTitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  infoDescription: {
    fontSize: 12,
    marginBottom: 20,
  },
  signatureImage: {
    width: 300,
    height: 50,
  },
  table: {
    width: '50%',
    marginBottom: 20,
    borderCollapse: 'collapse',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCell: {
    width: '100%',
    border: '1px solid #000',
    padding: 5,
  },
  titleSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  userDataSection: {
    flexDirection: 'column',
    margin: 10,
  },
  userDataLine: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  userDataTitle: {
    fontSize: 12,
    marginRight: 10,
  },
  userDataValue: {
    fontSize: 12,
    marginBottom: 20,
  },
  questionSection: {
    flexDirection: 'column',
    margin: 10,
  },
  questionTitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  question: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  questionText: {
    fontSize: 12,
    marginBottom: 5,
  },
  answersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  answerText: {
    fontSize: 12,
    marginRight: 10,
  },
  consentContentText: {
    fontSize: 12,
    marginBottom: 10,
  },
  consentTitleText: {
    fontSize: 14,
    marginBottom: 5,
  },
  consentsSection: {
    flexDirection: 'column',
    margin: 10,
  },
  consentTitle: {
    fontSize: 16,
    marginBottom: 5,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  checkbox: {
    width: 10,
    height: 10,
    marginRight: 5,
    border: '1px solid #000',
  },
  selectedCheckbox: {
    width: 5,
    height: 5,
    backgroundColor: 'black',
    borderRadius: 2,
    margin: 'auto',
  },
  signatureSection: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 15,
    marginHorizontal: 'auto',
  },
  signatureContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 10,
  },
  consentStatus: {
    fontSize: 16,
    marginBottom: 10,
  },
  signatureLabel: {
    textAlign: 'center',
    fontSize: 12,
    marginBottom: 5,
  },
  signatureField: {
    width: '70%',
    borderBottom: '1px solid #000',
    paddingBottom: 5,
    paddingTop: 40,
  },
  dataField: {
    fontSize: 12,
    textAlign: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  footerRow: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 5,
  },
  footerText: {
    fontSize: 10,
    color: 'grey',
  },
  link: {
    color: 'blue',
  },
});

export default PDFStyle;
