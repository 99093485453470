import '../default.css';
import {
  Box,
  Card,
  Container,
  CssBaseline,
  Divider,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  title: string;
  subtitle?: string | null;
  children?: ReactNode;
  footer?: ReactNode;
  bottom?: ReactNode;
}
export default function PopupContainer({
  title,
  subtitle,
  children,
  footer,
  bottom,
}: Props) {
  return (
    <>
      <div className="Popup-container">
        <Container component="main" maxWidth="xs" sx={{ margin: 5 }}>
          <CssBaseline />
          <Card variant={'outlined'} sx={{ overflow: 'visible' }}>
            <Box
              sx={{
                margin: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h3" variant="h3">
                {title}
              </Typography>

              {subtitle && (
                <Typography component="p" variant="body1">
                  {subtitle}
                </Typography>
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                margin: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {children}
            </Box>

            {footer && (
              <>
                <Divider />
                <div>{footer}</div>
              </>
            )}
          </Card>
          <Box sx={{ mt: 2 }}>
            {bottom && (
              <>
                <div>{bottom}</div>
              </>
            )}
          </Box>
        </Container>
      </div>
    </>
  );
}
