import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { CSVLink } from 'react-csv';
import { Headers } from 'react-csv/lib/core';
import { useEffect, useRef } from 'react';
import { ClassicButton } from '../../layouts/styled/buttons';

interface CSVExportButtonProps<T> {
  isLoading: boolean;
  headers: Headers;
  data: Record<string, string>[];
  disabled: boolean;
  filename: string;
  onClick: () => Promise<void>;
}

export default function CSVExportButton<T>({
  isLoading,
  headers,
  data,
  disabled,
  filename,
  onClick,
}: CSVExportButtonProps<T>) {
  const ref = useRef<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (data.length > 0 && !isLoading) {
      ref.current!.link.click();
    }
  }, [data]);

  return (
    <Box style={{ display: 'flex' }}>
      <ClassicButton
        sx={{ maxWidth: '14rem' }}
        onClick={onClick}
        disabled={disabled}
      >
        {isLoading
          ? t('common.csv-generation')
          : t('common.button.export-csv-btn')}
      </ClassicButton>
      <CSVLink data={data} headers={headers} ref={ref} filename={filename} />
    </Box>
  );
}
