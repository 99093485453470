import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AMOY_URL } from '../constants/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  txnHash: string;
}

export default function TransactionStatus({ txnHash }: Props) {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 95 ? prevProgress : prevProgress + 3,
      );
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          margin: '2rem 0',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <CircularProgress
          size={150}
          thickness={5}
          variant="determinate"
          value={progress}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3">{`${Math.round(progress)}%`}</Typography>
        </Box>
      </Box>
      <Typography variant="h5">
        {t('common.pending-transaction.info')}
      </Typography>
      <Typography variant={'body1'}>
        {t('common.pending-transaction.link')}
        <a href={`${AMOY_URL}${txnHash}`} target="_blank">
          {t('common.here')}
        </a>
      </Typography>
    </Box>
  );
}
