import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import useProjects from '../../../hooks/useProjects';
import {
  ClassicButton,
  ClassicInput,
  ClassicSelect,
  OutlineButton,
} from '../../../layouts/styled/buttons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from '@mui/material';
import useQuestions from '../../../hooks/useQuestions';
import LoadingController from '../../../components/utils/LoadingController';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import UsersInProjectTable from './views/UsersInProjectTable';
import {
  COLORS,
  MAX_ITEMS_PER_PAGE,
  REQUIRED_FIELDS,
} from '../../../utils/constants';
import AddIcon from '@mui/icons-material/Add';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PermissionGuard from '../../../guards/PermissionGuard';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { usePermission } from '../../../providers/PermissionProvider';
import ProjectPrint from './prints/ProjectPrint';
import UsersInProjectStatisticsTable from './views/UsersInProjectStatisticsTable';
import { useOperation } from '../../../providers/OperationProvider';
import useTags from '../../../hooks/useTags';
import {
  IProjectUserData,
  IProject,
} from '../../../interfaces/projects.interface';
import { IConsent } from '../../../interfaces/consents.interface';
import useConsents from '../../../hooks/useConsents';
import { IQuestion } from '../../../interfaces/questions.interface';
import moment, { Moment } from 'moment';
import StatusChip from '../../../components/chips/StatusChip';
import { OPERATIONS } from '../../../utils/constants';
import {
  MAX_PROJECT_USERS,
  MOBILE_DEVICE_SIGNATURE,
  YES_NO,
} from '../../../constants/common';
import CSVExportButton from '../../../components/builders/CSVExportButton';
import { Headers, LabelKeyObject } from 'react-csv/lib/core';
import Annotation from '../../../components/Annotation';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import QuestionItem from '../userData/items/QuestionItem';
import QuestionDialog from '../questions/QuestionDialog';
import ConsentDialog from '../consents/ConsentDialog';
import ConsentItem from './items/ConsentItem';
import { IEnterprise } from '../../../interfaces/enterprise.interface';

const initialProject: IProject = {
  id: 0,
  taxId: '',
  projectHash: '',
  consents: [],
  questions: [],
  title: '',
  description: '',
  privateDescription: '',
  contact: '',
  endTimestamp: moment().add(1, 'month').unix(),
  maxUsers: 250,
  tags: [],
  requiredFields: ['firstName', 'secondName', 'email'],
  isProjectMember: false,
  activated: true,
  createdAt: 0,
  isLiked: false,
  currentUsers: 0,
  gradient: '',
  version: 1,
  prevVersion: '',
  nextVersion: '',
  accessKey: '',
};

const initErrors = {
  title: '',
  description: '',
  maxUsers: '',
};

const csvBasicHeaders = [
  { label: 'User Hash', key: 'userHash' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Personal Id', key: 'personalId' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Second Name', key: 'secondName' },
];

type Props = {
  project?: IProject;
  open: boolean;
  handleClose: () => void;
  setRefreshProjects: (value: number) => void;
};

export default function ProjectDialog({
  project,
  open,
  handleClose,
  setRefreshProjects,
}: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getTags } = useTags();
  const { getConsents, getConsentSignatureByUserHash, getConsentSignature } =
    useConsents();
  const { getQuestions } = useQuestions();
  const {
    createProject,
    modifyProject,
    getProjectActivity,
    getProjectOverview,
    activateProject,
    deactivateProject,
    getProject,
    getProjectVersions,
    generateAccessKey,
    deleteAccessKey,
  } = useProjects();
  const enterpriseProvider = useEnterprise();
  const permissionsProvider = usePermission();

  const [isAccessKeyLoading, setIsAccessKeyLoading] = useState<boolean>(false);
  const [areSignaturesLoading, setAreSignaturesLoading] =
    useState<boolean>(false);
  const [projectActivity, setProjectActivity] = useState<IProjectUserData>();
  const [isProjectActivityLoading, setIsProjectActivityLoading] =
    useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [areTagsLoading, setAreTagsLoading] = useState(false);
  const [originalTags, setOriginalTags] = useState<string[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [questionSearchQuery, setQuestionSearchQuery] = useState('');
  const [consentsSearchQuery, setConsentsSearchQuery] = useState('');
  const [availableQuestions, setAvailableQuestions] = useState<IQuestion[]>([]);
  const [availableConsents, setAvailableConsents] = useState<IConsent[]>([]);
  const [projectQuestions, setProjectQuestions] = useState<IQuestion[]>([]);
  const [projectConsents, setProjectConsents] = useState<IConsent[]>([]);
  const [areProjectConsentsLoading, setAreProjectConsentsLoading] =
    useState<boolean>(false);
  const [availableQuestionsLoading, setAvailableQuestionsLoading] =
    useState<boolean>(false);
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] =
    useState<boolean>(false);
  const [isConsentsDialogOpen, setIsConsentsDialogOpen] =
    useState<boolean>(false);
  const [formErrors, setFormErrors] =
    useState<Record<string, string>>(initErrors);
  const [formData, setFormData] = useState<IProject>(initialProject);
  const [csvData, setCsvData] = useState<Record<string, string>[]>([]);
  const [csvHeaders, setCsvHeaders] = useState<Headers>([]);
  const [isCsvDataLoading, setIsCsvDataLoading] = useState<boolean>(false);
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState<string>();
  const [availableEnterprises, setAvailableEnterprises] = useState<
    IEnterprise[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectVersions, setProjectVersions] = useState<IProject[]>([]);
  const [areProjectVersionsLoading, setAreProjectVersionsLoading] =
    useState(false);
  const [originalRequiredFields, setOriginalRequiredFields] = useState<
    string[]
  >([]);
  const [refresh, setRefresh] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCreateConsentDialogOpen, setIsCreateConsentDialogOpen] =
    useState(false);

  useEffect(() => {
    project
      ? handleInputChange('projectHash', project.projectHash)
      : setEditMode(true);
  }, [project]);

  useEffect(() => {
    if (formData.projectHash) {
      updateProjectData(formData.projectHash);
    }
  }, [formData.projectHash, refresh]);

  useEffect(() => {
    if (editMode) {
      const availableEnterprises = enterpriseProvider.enterprises.data.filter(
        (ent) =>
          permissionsProvider.permissions.some(
            (permission) =>
              permission.taxId === ent.taxId &&
              permission.flag === 'ENTERPRISE_CREATE_PROJECT',
          ),
      );
      setAvailableEnterprises(availableEnterprises);
      if (availableEnterprises.length > 0 && !formData.taxId) {
        formData.taxId = availableEnterprises[0].taxId;
      }
    }
  }, [editMode]);

  useEffect(() => {
    if (formData.taxId) {
      if (editMode) {
        fetchTags(formData.taxId);
        fetchAvailableConsents(formData.taxId);
        fetchAvailableQuestions(formData.taxId);
      } else {
        if (!selectedEnterprise || selectedEnterprise.length === 0) {
          const enterprise = enterpriseProvider.enterprises.data.find(
            (item) => item.taxId === formData.taxId,
          );
          setSelectedEnterprise(enterprise ? enterprise.name : '');
        }
      }
    }
  }, [formData.taxId, editMode]);

  const updateProjectData = async (projectHash: string) => {
    try {
      setIsLoading(true);
      const project = await getProject(projectHash);
      if (project) {
        setFormData(project);
        setOriginalTags(project.tags);
        setOriginalRequiredFields(project.requiredFields);
        setProjectQuestions(project.questions);
        setProjectConsents(project.consents);
        if (project.isProjectMember) {
          fetchProjectActivity(projectHash);
        }
        fetchProjectVersions(projectHash, project.taxId);
      }
    } catch (e) {
      console.error('Error fetching project:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjectVersions = async (projectHash: string, taxId: string) => {
    try {
      setAreProjectVersionsLoading(true);
      if (permissionsProvider.hasFlag('ENTERPRISE_GET_PROJECT_VERSIONS', taxId))
        setProjectVersions(await getProjectVersions(projectHash));
    } catch (error) {
      console.error('Error fetching project versions:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreProjectVersionsLoading(false);
    }
  };

  useEffect(() => {
    if (isPrintDialogOpen && projectActivity) {
      fetchSignatures(projectActivity);
    }
  }, [isPrintDialogOpen]);

  const handleTagsChange = (
    event: React.ChangeEvent<{}>,
    value: readonly string[],
  ) => {
    formData.tags = Array.from(value);
  };

  const fetchProjectActivity = async (projectHash: string) => {
    try {
      setIsProjectActivityLoading(true);
      const projectActivity = await getProjectActivity(projectHash);
      setProjectActivity(projectActivity[0]);
    } catch (e) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
      console.error('Error fetching project activity:', e);
    } finally {
      setIsProjectActivityLoading(false);
    }
  };

  const fetchSignatures = async (projectActivity: IProjectUserData) => {
    try {
      setAreSignaturesLoading(true);
      const operations = await Promise.all(
        projectActivity.operations.map(async (el) => ({
          ...el,
          signature:
            el.signatureType === MOBILE_DEVICE_SIGNATURE
              ? permissionsProvider.hasFlag(
                  'ENTERPRISE_GET_CONSENT_SIGNATURE',
                  project!.taxId,
                )
                ? await getConsentSignatureByUserHash(
                    el.consentHash,
                    projectActivity.user.userHash,
                    `${el.timestamp}`,
                  )
                : await getConsentSignature(
                    el.consentHash,
                    el.timestamp.toString(),
                  )
              : undefined,
        })),
      );
      setProjectActivity({ ...projectActivity, operations });
    } catch (e) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
      console.error('Error fetching signature:', e);
    } finally {
      setAreSignaturesLoading(false);
    }
  };

  const setValidationErrors = () => {
    const errors: Record<string, string> = {};
    if (formData.title.trim() === '') {
      errors.title = t('common.forms.errors.field-required');
    }
    if (formData.description.trim() === '') {
      errors.description = t('common.forms.errors.field-required');
    }
    if (formData.maxUsers <= 0 || formData.maxUsers > MAX_PROJECT_USERS) {
      errors.maxUsers = t('common.forms.errors.max-users');
    }
    setFormErrors(errors);
  };

  const isFormValid = () =>
    formData.title !== '' &&
    formData.description !== '' &&
    formData.maxUsers > 0 &&
    formData.maxUsers <= MAX_PROJECT_USERS &&
    formData.consents.length > 0 &&
    formData.requiredFields.length > 0;

  const fetchAvailableQuestions = async (taxId: string) => {
    setAvailableQuestionsLoading(true);
    try {
      setAvailableQuestions(
        (
          await getQuestions([taxId], {
            pageSize: MAX_ITEMS_PER_PAGE,
          })
        ).data,
      );
    } catch (e) {
      console.error('Error fetching available questions:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setAvailableQuestionsLoading(false);
    }
  };

  const fetchAvailableConsents = async (taxId: string) => {
    setAreProjectConsentsLoading(true);
    try {
      setAvailableConsents(
        (
          await getConsents([taxId], {
            pageSize: MAX_ITEMS_PER_PAGE,
            activated: true,
          })
        ).data,
      );
    } catch (e) {
      console.error('Error fetching available consents:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setAreProjectConsentsLoading(false);
    }
  };

  const fetchTags = async (taxId: string) => {
    try {
      setAreTagsLoading(true);
      const tags = (await getTags(taxId, { pageSize: MAX_ITEMS_PER_PAGE }))
        .data;
      setTags(tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreTagsLoading(false);
    }
  };

  const onGenerateClick = async () => {
    try {
      setIsAccessKeyLoading(true);
      const accessKey = await generateAccessKey(formData.projectHash);
      handleInputChange('accessKey', accessKey);
      setRefreshProjects(Date.now());
    } catch (error) {
      console.error('Error generating project access key:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsAccessKeyLoading(false);
    }
  };

  const handleInputChange = async (
    field: keyof IProject,
    value: string[] | string | number | IConsent[] | IQuestion[],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: Array.isArray(value) ? [...value] : value,
    }));
  };

  const onMaxClick = () => {
    setFormData((prevData) => ({
      ...prevData,
      maxUsers: MAX_PROJECT_USERS,
    }));
  };

  const handleSubmit = async () => {
    if (!editMode) {
      setEditMode(true);
      return;
    }
    if (isFormValid()) {
      formData.projectHash ? await handleEdit() : await handleSave();
      setEditMode(false);
      setRefreshProjects(Date.now());
    } else {
      setValidationErrors();
    }
  };

  const handleEdit = async () => {
    try {
      if (
        (project &&
          (formData.title !== project.title ||
            formData.description !== project.description ||
            formData.privateDescription !== project.privateDescription ||
            formData.contact !== project.contact ||
            formData.endTimestamp !== project.endTimestamp ||
            formData.maxUsers !== project.maxUsers ||
            JSON.stringify(formData.questions) !==
              JSON.stringify(projectQuestions) ||
            !formData.tags.every((tag: string) => originalTags.includes(tag)) ||
            !originalTags.every((tag: string) => formData.tags.includes(tag)) ||
            JSON.stringify(formData.consents) !==
              JSON.stringify(projectConsents) ||
            !formData.requiredFields.every((field: string) =>
              originalRequiredFields.includes(field),
            ) ||
            !originalRequiredFields.every((field: string) =>
              formData.requiredFields.includes(field),
            ))) ||
        !project
      ) {
        const { hash } = await modifyProject({
          ...formData,
          questions: formData.questions.map((el, index) => ({
            ...el,
            orderNumber: index,
          })),
          consents: formData.consents.map((el, index) => ({
            ...el,
            orderNumber: index,
          })),
        });
        handleInputChange('projectHash', hash);
        setRefresh(Date.now());
      }
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.project-edited'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const projectHash = await createProject({
        ...formData,
        questions: formData.questions.map((el, index) => ({
          ...el,
          orderNumber: index,
        })),
        consents: formData.consents.map((el, index) => ({
          ...el,
          orderNumber: index,
        })),
      });
      setFormData((prevData) => ({
        ...prevData,
        projectHash,
      }));
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.project-saved'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number,
  ): any[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formData.questions,
      result.source.index,
      result.destination.index,
    );

    handleInputChange('questions', [...items]);
  };

  const onConsentDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formData.consents,
      result.source.index,
      result.destination.index,
    );

    handleInputChange('consents', [...items]);
  };

  const handleDeleteQuestion = (index: number) => {
    const newQuestions = formData.questions.filter((el, i) => i !== index);
    handleInputChange('questions', newQuestions);
  };

  const handleDeleteConsent = (index: number) => {
    const newConsents = formData.consents.filter((el, i) => i !== index);
    handleInputChange('consents', newConsents);
  };

  const updateAvailableQuestions = (
    answerIndex: number,
    questionIndex: number,
  ) => {
    const newQuestions = formData.questions.map((question, qIndex) =>
      qIndex === questionIndex
        ? {
            ...question,
            answers: question.answers.map((answer, aIndex) =>
              aIndex === answerIndex
                ? { ...answer, isObligatory: !answer.isObligatory }
                : answer,
            ),
          }
        : question,
    );
    handleInputChange('questions', newQuestions);
  };

  const updateObligatoryStatus = (index: number) => {
    const newConsents = formData.consents.map((consent, idx) =>
      idx === index
        ? { ...consent, isObligatory: !consent.isObligatory }
        : consent,
    );
    handleInputChange('consents', newConsents);
  };

  const handleActive = async () => {
    try {
      if (formData.activated) {
        await deactivateProject(formData.projectHash);
        setFormData((prevData) => ({
          ...prevData,
          activated: false,
        }));
      } else {
        await activateProject(formData.projectHash);
        setFormData((prevData) => ({
          ...prevData,
          activated: true,
        }));
      }
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: formData.activated
          ? t('pages.operations.messages.project-deactivated')
          : t('pages.operations.messages.project-activated'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleFields = (
    event: React.ChangeEvent<{}>,
    value: readonly string[],
  ) => {
    formData.requiredFields = value.map((item) => item);
  };

  const handleDateChange = (selectedDate: Moment | null) => {
    setFormData({
      ...formData,
      endTimestamp: selectedDate
        ? selectedDate.endOf('day').unix()
        : moment().add(1, 'month').endOf('day').unix(),
    });
  };

  const onDeleteAccessKeyClick = async () => {
    try {
      setIsAccessKeyLoading(true);
      await deleteAccessKey(formData.projectHash);
      handleInputChange('accessKey', '');
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsAccessKeyLoading(false);
    }
  };

  const onCsvClick = async () => {
    setIsCsvDataLoading(true);
    try {
      const projectOverview = await getProjectOverview(formData.projectHash);
      const questionColumns =
        project?.questions.map((el) => ({
          label: el.question,
          key: el.question,
        })) || [];
      let consentColumns: LabelKeyObject[] = [];
      project?.consents
        .map((el) => el.title)
        .forEach((title) => {
          consentColumns.push({ label: title, key: `${title}-status` });
          consentColumns.push({ label: 'Notes', key: `${title}-notes` });
          consentColumns.push({ label: 'Txn Hash', key: `${title}-txn-hash` });
          consentColumns.push({
            label: 'Timestamp',
            key: `${title}-timestamp`,
          });
        });
      setCsvHeaders(
        csvBasicHeaders.concat(questionColumns).concat(consentColumns),
      );
      const csvData = projectOverview.map((el) => {
        const questionData: Record<string, string> = {};
        project?.questions.forEach((question) => {
          const answer = el.answers.find(
            (a) => a.question === question.question,
          );
          questionData[question.question] = answer
            ? answer.type === YES_NO && answer.answer
              ? t(`common.forms.labels.${answer.answer}`)
              : answer.answer
            : '';
        });

        const consentsData: Record<string, string> = {};
        project?.consents.forEach((item) => {
          const operation =
            el.operations && el.operations.find((a) => a.title === item.title);
          consentsData[`${item.title}-status`] = !operation
            ? 'not signed'
            : operation.isSigned
            ? 'signed'
            : 'withdrawn';
          consentsData[`${item.title}-notes`] = operation?.notes || '';
          consentsData[`${item.title}-txn-hash`] = operation?.txnHash || '';
          consentsData[`${item.title}-timestamp`] = operation?.timestamp
            ? moment.unix(operation.timestamp).format('DD-MM-YYYY HH:mm:ss')
            : '';
        });
        return {
          userHash: el.user.userHash,
          email: el.user.email,
          phone: el.user.phone,
          firstName: el.user.firstName,
          secondName: el.user.secondName,
          invitedBy: el.invitedBy.email,
          ...questionData,
          ...consentsData,
        };
      });
      setCsvData(csvData);
      if (csvData.length === 0)
        setOperation({
          severity: OPERATIONS.ERROR,
          message: t('common.no-data'),
        });
    } catch (e) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response?.data.details || '',
      });
    } finally {
      setIsCsvDataLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '1100px',
            },
          },
        }}
      >
        {formData.projectHash && (
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <StatusChip
              status={
                formData.isProjectMember
                  ? { label: t('common.tables.project.joined'), status: 2 }
                  : {
                      label: t('common.tables.project.notJoined'),
                      status: 0,
                    }
              }
            />
          </Box>
        )}
        <Box
          sx={{
            textAlign: 'center',
            p: 2.5,
            borderBottom: `1px solid ${COLORS.primaryColor}`,
          }}
        >
          <Typography variant={'h5'}>
            {t('pages.project.title-editor')}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '15px',
              right: '5px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid
            container
            sx={{
              minWidth: { xs: '300px', md: '500px' },
              justifyContent: 'center',
            }}
          >
            {isLoading ? (
              <LoadingController />
            ) : (
              <Grid container spacing={2} padding={2}>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  textAlign="left"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.enterprise')} :
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t(
                          'common.annotations.project-enterprise',
                        )}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode &&
                    !formData.projectHash &&
                    availableEnterprises.length > 0 ? (
                      <ClassicSelect
                        fullWidth
                        labelId="enterprise-label"
                        id="enterprise-select"
                        value={formData.taxId || availableEnterprises[0].taxId}
                        onChange={(e) =>
                          handleInputChange('taxId', e.target.value as string)
                        }
                      >
                        {availableEnterprises.map(
                          (item: IEnterprise, index: number) => (
                            <MenuItem key={index} value={item.taxId}>
                              {item.name}
                            </MenuItem>
                          ),
                        )}
                      </ClassicSelect>
                    ) : (
                      <Box sx={{ padding: '5px 0' }}>
                        <Chip key={0} label={selectedEnterprise} />
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.name')} :{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <ClassicInput
                        required
                        label={t('common.forms.labels.name')}
                        fullWidth
                        value={formData.title}
                        onChange={(e) =>
                          handleInputChange('title', e.target.value)
                        }
                        error={formErrors.title !== ''}
                        helperText={formErrors.title}
                      />
                    ) : (
                      <Typography variant={'body1'}>
                        {formData.title}
                      </Typography>
                    )}
                  </Grid>
                  {!editMode &&
                    formData.projectHash &&
                    projectVersions.length > 0 && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          display="flex"
                          justifyContent="left"
                        >
                          <Typography variant={'h5'}>
                            {t('common.tables.header.version')} :
                          </Typography>
                          <Annotation
                            tooltip={`${t(
                              'common.annotations.consent-version',
                            )}`}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          {!areProjectVersionsLoading ? (
                            <ClassicSelect
                              sx={{ width: '90px', height: '40px' }}
                              fullWidth
                              labelId="enterprise-label"
                              id="enterprise-select"
                              value={formData.projectHash}
                              onChange={(e) =>
                                handleInputChange(
                                  'projectHash',
                                  e.target.value as string,
                                )
                              }
                            >
                              {projectVersions.map(
                                (item: IProject, index: number) => (
                                  <MenuItem
                                    key={index}
                                    value={item.projectHash}
                                  >
                                    {`v.${item.version}`}
                                  </MenuItem>
                                ),
                              )}
                            </ClassicSelect>
                          ) : (
                            <CircularProgress />
                          )}
                        </Grid>
                      </>
                    )}
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.description')} :{' '}
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t(
                          'common.annotations.project-description',
                        )}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <ClassicInput
                        required
                        label={t('common.forms.labels.description')}
                        multiline
                        rows={5}
                        fullWidth
                        value={formData.description}
                        onChange={(e) =>
                          handleInputChange('description', e.target.value)
                        }
                        error={formErrors.description !== ''}
                        helperText={formErrors.description}
                      />
                    ) : (
                      <Typography
                        variant={'body1'}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {formData.description}
                      </Typography>
                    )}
                  </Grid>
                  {formData.privateDescription !== undefined && (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="left"
                      >
                        <Typography variant={'h5'}>
                          {t('common.forms.labels.note')} :{' '}
                        </Typography>
                        {editMode && (
                          <Annotation
                            tooltip={`${t('common.annotations.project-note')}`}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} md={8}>
                        {editMode ? (
                          <ClassicInput
                            label={t('common.forms.labels.note')}
                            multiline
                            rows={5}
                            fullWidth
                            value={formData.privateDescription}
                            onChange={(e) =>
                              handleInputChange(
                                'privateDescription',
                                e.target.value,
                              )
                            }
                          />
                        ) : (
                          <Typography
                            variant={'body1'}
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            {formData.privateDescription}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.tag')} :
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t('common.annotations.project-tags')}`}
                      />
                    )}
                  </Grid>
                  <Grid container item xs={12} md={8}>
                    {areTagsLoading ? (
                      <CircularProgress />
                    ) : editMode ? (
                      <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        options={tags}
                        defaultValue={formData.tags}
                        onChange={handleTagsChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('common.forms.labels.tag')}
                            placeholder="..."
                          />
                        )}
                        sx={{ width: '100%' }}
                      />
                    ) : (
                      <Box sx={{ padding: '5px 0' }}>
                        {formData.tags.map((label: string, index: number) => (
                          <Chip key={index} label={label} />
                        ))}
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.consents')} :
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t('common.annotations.project-consents')}`}
                      />
                    )}
                  </Grid>
                  <Grid container item xs={12} md={8}>
                    {editMode ? (
                      <Box
                        sx={{
                          padding: '5px 0',
                          overflowX: 'scroll',
                          border: `1px solid ${COLORS.accentColor}`,
                          borderRadius: '20px',
                          width: '100%',
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <DragDropContext onDragEnd={onConsentDragEnd}>
                            <Droppable droppableId="droppableConsent">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {formData.consents.map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={`consent-${item.id}`}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <Grid
                                          container
                                          item
                                          xs={12}
                                          spacing={1}
                                          sx={{ p: 1, alignItems: 'center' }}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Grid item xs={1} className="handle">
                                            <IconButton disabled>
                                              <DragIndicatorOutlinedIcon />
                                            </IconButton>
                                          </Grid>
                                          <Grid item xs={9} md={10}>
                                            <ConsentItem
                                              consent={item}
                                              isEditMode={true}
                                              showCheckboxes={true}
                                              updateObligatoryStatus={() => {
                                                updateObligatoryStatus(index);
                                              }}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={1}
                                            style={{ textAlign: 'right' }}
                                          >
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteConsent(index)
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>

                          <IconButton
                            onClick={() => setIsConsentsDialogOpen(true)}
                            sx={{ width: '3rem', margin: 'auto' }}
                          >
                            <ControlPointIcon
                              sx={{ width: '2rem', height: '2rem' }}
                            />
                          </IconButton>
                        </Grid>
                      </Box>
                    ) : (
                      <Box sx={{ padding: '5px 0', width: '100%' }}>
                        {formData.consents.map((consent) => (
                          <Box sx={{ mb: 2 }}>
                            <ConsentItem
                              consent={consent}
                              isEditMode={false}
                              showCheckboxes={permissionsProvider.hasFlag(
                                'ENTERPRISE_GET_PROJECT_STATISTICS',
                                consent.taxId,
                              )}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.questions')} :
                    </Typography>

                    {editMode && (
                      <Annotation
                        tooltip={`${t('common.annotations.project-question')}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <Box
                        sx={{
                          padding: '5px 0',
                          overflowX: 'scroll',
                          border: `1px solid ${COLORS.accentColor}`,
                          borderRadius: '20px',
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {formData.questions.map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={`question-${item.id}`}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <Grid
                                          container
                                          item
                                          xs={12}
                                          spacing={1}
                                          sx={{ p: 1, alignItems: 'center' }}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Grid item xs={1} className="handle">
                                            <IconButton disabled>
                                              <DragIndicatorOutlinedIcon />
                                            </IconButton>
                                          </Grid>
                                          <Grid item xs={9} md={10}>
                                            <QuestionItem
                                              question={item}
                                              isEditMode={true}
                                              showCheckboxes={true}
                                              updateQuestion={(answerIndex) => {
                                                updateAvailableQuestions(
                                                  answerIndex,
                                                  index,
                                                );
                                              }}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={1}
                                            style={{ textAlign: 'right' }}
                                          >
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteQuestion(index)
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>

                          <IconButton
                            onClick={() => setIsQuestionDialogOpen(true)}
                            sx={{ width: '3rem', margin: 'auto' }}
                          >
                            <ControlPointIcon
                              sx={{ width: '2rem', height: '2rem' }}
                            />
                          </IconButton>
                        </Grid>
                      </Box>
                    ) : (
                      <>
                        {formData.questions.length > 0 && (
                          <Box sx={{ padding: '5px 0' }}>
                            {formData.questions.map((question) => (
                              <Box sx={{ mb: 2 }}>
                                <QuestionItem
                                  question={question}
                                  showCheckboxes={permissionsProvider.hasFlag(
                                    'ENTERPRISE_GET_PROJECT_STATISTICS',
                                    question.taxId,
                                  )}
                                />
                              </Box>
                            ))}
                          </Box>
                        )}
                      </>
                    )}
                  </Grid>
                  <PermissionGuard
                    permissions={['ENTERPRISE_GET_PROJECT_STATISTICS']}
                    taxId={formData.taxId}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                      alignItems="center"
                    >
                      <Typography variant={'h4'}>
                        {t('common.forms.labels.contact')} :
                      </Typography>
                      {editMode && (
                        <Annotation
                          tooltip={`${t('common.annotations.project-contact')}`}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      {editMode ? (
                        <ClassicInput
                          label={t('common.forms.labels.contact')}
                          multiline
                          rows={3}
                          fullWidth
                          value={formData.contact}
                          onChange={(e) =>
                            handleInputChange('contact', e.target.value)
                          }
                        />
                      ) : (
                        <Typography
                          variant={'body1'}
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {formData.contact}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                      alignItems="center"
                    >
                      <Typography variant={'h4'}>
                        {t('common.forms.labels.end-date')} :
                      </Typography>
                      {editMode && (
                        <Annotation
                          tooltip={`${t(
                            'common.annotations.project-end-date',
                          )}`}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      {editMode ? (
                        <DatePicker
                          value={moment.unix(formData.endTimestamp)}
                          disablePast
                          onChange={handleDateChange}
                        />
                      ) : (
                        <Typography variant={'body1'}>
                          {moment
                            .unix(formData.endTimestamp)
                            .format('DD-MM-YYYY HH:mm:ss')}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                      alignItems="center"
                    >
                      <Typography variant={'h4'}>
                        {t('common.forms.labels.requiredFields')} :
                      </Typography>
                      {editMode && (
                        <Annotation
                          tooltip={`${t(
                            'common.annotations.project-required-fields',
                          )}`}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      {editMode ? (
                        <Autocomplete
                          multiple
                          options={REQUIRED_FIELDS}
                          defaultValue={formData.requiredFields}
                          onChange={handleFields}
                          getOptionLabel={(label) =>
                            t(`common.labels.${label}`)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('common.forms.labels.requiredFields')}
                              placeholder="..."
                              required
                            />
                          )}
                        />
                      ) : (
                        <Box sx={{ padding: '5px 0' }}>
                          {formData.requiredFields.map((label, index) => (
                            <Chip
                              key={index}
                              label={t(`common.labels.${label}`)}
                            />
                          ))}
                        </Box>
                      )}
                    </Grid>
                    {((!editMode && formData.currentUsers !== undefined) ||
                      editMode) && (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="left"
                        alignItems="center"
                      >
                        <Typography variant={'h4'}>
                          {t('common.forms.labels.max-patients')}:
                        </Typography>
                        {editMode && (
                          <Annotation
                            tooltip={`${t(
                              'common.annotations.project-max-users',
                            )}`}
                          />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} md={8}>
                      {editMode ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <ClassicInput
                            fullWidth
                            type="number"
                            inputProps={{ min: 1, max: MAX_PROJECT_USERS }}
                            label={t('common.forms.labels.max-patients')}
                            value={formData.maxUsers}
                            error={formErrors.maxUsers !== ''}
                            helperText={formErrors.maxUsers}
                            onChange={(e) =>
                              handleInputChange(
                                'maxUsers',
                                parseInt(e.target.value, 10),
                              )
                            }
                          />
                          <OutlineButton
                            sx={{ width: '5rem', margin: '0 1rem' }}
                            onClick={onMaxClick}
                          >
                            MAX
                          </OutlineButton>
                        </Box>
                      ) : (
                        formData.currentUsers != undefined &&
                        formData.maxUsers != undefined && (
                          <Typography variant={'body1'}>
                            {formData.currentUsers} / {formData.maxUsers}
                          </Typography>
                        )
                      )}
                    </Grid>
                    {!editMode && formData.accessKey !== undefined && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          display="flex"
                          justifyContent="left"
                        >
                          <Typography variant={'h5'}>
                            {t('common.tables.header.accessKey')} :
                          </Typography>
                          <Annotation
                            tooltip={`${t('common.annotations.access-key')}`}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          {isAccessKeyLoading ? (
                            <CircularProgress />
                          ) : formData.accessKey ? (
                            <Box>
                              <Chip label={formData.accessKey} />
                              <IconButton onClick={onDeleteAccessKeyClick}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Box>
                          ) : permissionsProvider.hasFlag(
                              'ENTERPRISE_CREATE_PROJECT_ACCESS_KEY',
                              formData.taxId,
                            ) ? (
                            <OutlineButton
                              sx={{ maxWidth: '10rem', marginTop: 0 }}
                              onClick={onGenerateClick}
                            >
                              {t('common.button.generate-btn')}
                            </OutlineButton>
                          ) : (
                            <Chip label={t(`common.no-data`)} />
                          )}
                        </Grid>
                      </>
                    )}
                  </PermissionGuard>
                </Grid>
                {formData.projectHash && !editMode && (
                  <>
                    <PermissionGuard
                      permissions={['ENTERPRISE_GET_PROJECT_STATISTICS']}
                      taxId={formData.taxId}
                    >
                      <Box sx={{ p: 2, width: '100%' }}>
                        <UsersInProjectStatisticsTable
                          projectHash={formData.projectHash}
                        />
                      </Box>
                    </PermissionGuard>

                    <PermissionGuard
                      permissions={['ENTERPRISE_GET_PROJECT_OVERVIEW']}
                      taxId={formData.taxId}
                    >
                      <Box sx={{ p: 2, width: '100%' }}>
                        <UsersInProjectTable
                          projectHash={formData.projectHash}
                          projectTitle={formData.title}
                          questions={formData.questions}
                          consents={formData.consents}
                          taxId={formData.taxId}
                          refreshData={setRefreshProjects}
                        />
                      </Box>
                    </PermissionGuard>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: { xs: 'center', md: 'flex-end' },
            width: '100%',
            p: 2.5,
          }}
        >
          <PermissionGuard
            permissions={['ENTERPRISE_GET_PROJECT_OVERVIEW']}
            taxId={formData.taxId}
          >
            <ClassicButton
              sx={{ maxWidth: '10rem' }}
              startIcon={<EditIcon />}
              onClick={() => handleSubmit()}
              disabled={!isFormValid()}
            >
              {editMode
                ? t('common.button.save-btn')
                : t('common.button.edit-btn')}
            </ClassicButton>
          </PermissionGuard>
          {!editMode && (
            <>
              <PermissionGuard
                permissions={[
                  'ENTERPRISE_ACTIVATE_PROJECT',
                  'ENTERPRISE_DEACTIVATE_PROJECT',
                ]}
                taxId={formData.taxId}
              >
                <ClassicButton
                  sx={{ maxWidth: '10rem' }}
                  disabled={
                    moment.unix(formData.endTimestamp).isBefore(moment()) ||
                    formData.currentUsers === formData.maxUsers
                  }
                  onClick={handleActive}
                >
                  {formData.activated
                    ? t('common.toggle.deactivate')
                    : t('common.toggle.activate')}
                </ClassicButton>
              </PermissionGuard>
              {formData.projectHash &&
                formData.consents.length > 0 &&
                !isProjectActivityLoading && (
                  <>
                    <ClassicButton
                      sx={{ maxWidth: '10rem' }}
                      startIcon={<PrintIcon />}
                      disabled={areSignaturesLoading}
                      onClick={() => {
                        setIsPrintDialogOpen(true);
                      }}
                    >
                      {t('common.button.print-btn')}
                    </ClassicButton>
                    <PermissionGuard
                      permissions={['ENTERPRISE_EXPORT_CSV']}
                      taxId={formData.taxId}
                    >
                      <CSVExportButton
                        data={csvData}
                        headers={csvHeaders}
                        isLoading={isCsvDataLoading}
                        onClick={onCsvClick}
                        disabled={
                          isCsvDataLoading ||
                          isProjectActivityLoading ||
                          availableQuestionsLoading ||
                          areProjectConsentsLoading
                        }
                        filename={`project-${formData.projectHash}`}
                      />
                    </PermissionGuard>
                  </>
                )}
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={isPrintDialogOpen}
        onClose={() => setIsPrintDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant={'h5'}>
            {t('common.document-generation')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {!areSignaturesLoading ? (
            <PDFDownloadLink
              document={
                <ProjectPrint
                  projectTitle={formData.title}
                  questions={formData.questions}
                  consents={formData.consents}
                  projectOverview={projectActivity}
                />
              }
              fileName={`project-${project?.projectHash}.pdf`}
            >
              {() => (
                <OutlineButton
                  sx={{ margin: '0 auto', marginTop: '10px' }}
                  onClick={() => {
                    setIsPrintDialogOpen(false);
                  }}
                >
                  {t('common.button.download-btn')}
                </OutlineButton>
              )}
            </PDFDownloadLink>
          ) : (
            <LoadingController />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isQuestionDialogOpen}
        onClose={() => setIsQuestionDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant={'h5'}>{t('pages.questions.title')}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ minWidth: { xs: '20rem', md: '35rem', minHeight: '600px' } }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicInput
              fullWidth
              label={t('common.search-label.questions')}
              value={questionSearchQuery}
              onChange={(e) => setQuestionSearchQuery(e.target.value)}
            />
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <PermissionGuard permissions={['ENTERPRISE_CREATE_QUESTION']}>
                <Box sx={{ ml: '10px' }}>
                  <ClassicButton
                    sx={{ mt: 0 }}
                    startIcon={<AddIcon />}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    {t('common.button.add-btn')}
                  </ClassicButton>
                </Box>
              </PermissionGuard>
            </Box>
          </Box>

          {availableQuestions
            .filter(
              (el) =>
                el.question
                  .toLowerCase()
                  .includes(questionSearchQuery.toLowerCase()) &&
                !formData.questions.some(
                  (question) => question.questionHash === el.questionHash,
                ),
            )
            .map((el, index) => (
              <Box sx={{ width: '100%', marginBottom: 1.5 }}>
                <QuestionItem
                  question={el}
                  showAnswers={false}
                  onClick={() => {
                    handleInputChange('questions', [...formData.questions, el]);
                    setIsQuestionDialogOpen(false);
                  }}
                />
              </Box>
            ))}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isConsentsDialogOpen}
        onClose={() => setIsConsentsDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant={'h5'}>{t('pages.consents.title')}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ minWidth: { xs: '20rem', md: '35rem', minHeight: '600px' } }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicInput
              fullWidth
              label={t('common.search-label.questions')}
              value={consentsSearchQuery}
              onChange={(e) => setConsentsSearchQuery(e.target.value)}
            />
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <PermissionGuard
                permissions={['ENTERPRISE_CREATE_CONSENT_DRAFT']}
              >
                <Box sx={{ ml: '10px' }}>
                  <ClassicButton
                    sx={{ mt: 0 }}
                    startIcon={<AddIcon />}
                    onClick={() => setIsCreateConsentDialogOpen(true)}
                  >
                    {t('common.button.add-btn')}
                  </ClassicButton>
                </Box>
              </PermissionGuard>
            </Box>
          </Box>

          {availableConsents
            .filter(
              (el) =>
                el.title
                  .toLowerCase()
                  .includes(consentsSearchQuery.toLowerCase()) &&
                !formData.consents.some(
                  (consent) => consent.consentHash === el.consentHash,
                ),
            )
            .map((el, index) => (
              <Box sx={{ width: '100%', marginBottom: 1.5 }}>
                <ConsentItem
                  consent={el}
                  isEditMode={false}
                  showCheckboxes={false}
                  onClick={() => {
                    handleInputChange('consents', [...formData.consents, el]);
                    setIsConsentsDialogOpen(false);
                  }}
                />
              </Box>
            ))}
        </DialogContent>
      </Dialog>

      {isCreateConsentDialogOpen && (
        <ConsentDialog
          open={isConsentsDialogOpen}
          handleClose={() => {
            setIsCreateConsentDialogOpen(false);
          }}
          setRefresh={() => {
            fetchAvailableConsents(formData.taxId);
          }}
        />
      )}

      {isDialogOpen && (
        <QuestionDialog
          open={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
          }}
          setRefresh={() => {
            fetchAvailableQuestions(formData.taxId);
          }}
        />
      )}
    </>
  );
}
