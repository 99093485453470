import { useAuth } from '../providers/AuthProvider';
import api from '../api';
import { IBenefit, IBenefitResponse } from '../interfaces/benefits.interface';
import { EMPTY_DATA } from '../constants/common';

interface BenefitContextType {
  createBenefit: (requestBody: IBenefit) => Promise<string>;
  getBenefits: (
    pageIndex?: number,
    pageSize?: number,
    sorting?: string,
  ) => Promise<IBenefitResponse>;
  getBenefit: (benefitHash: string) => Promise<IBenefit | null>;
  activateBenefit: (benefitHash: string) => Promise<void>;
  deactivateBenefit: (benefitHash: string) => Promise<void>;
}

const useBenefits = (): BenefitContextType => {
  const auth = useAuth();

  const createBenefit = async (requestBody: IBenefit) => {
    try {
      const response = await api.post('/benefits/create', requestBody, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return response.data.benefitHash;
    } catch (error) {
      throw error;
    }
  };

  const getBenefits = async (
    pageIndex: number = 0,
    pageSize: number = 12,
    sorting: string = 'id DESC',
  ): Promise<IBenefitResponse> => {
    try {
      const response = await api.get<IBenefitResponse>('/benefits', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          pageIndex,
          pageSize,
          sorting,
        },
      });
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      throw error;
    }
  };

  const getBenefit = async (benefitHash: string): Promise<IBenefit | null> => {
    try {
      const response = await api.get<IBenefit>(
        `/benefit?benefitHash=${benefitHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data ?? null;
    } catch (error) {
      throw error;
    }
  };

  const activateBenefit = async (benefitHash: string): Promise<void> => {
    try {
      return await api.put(
        '/benefits/activate',
        { benefitHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      throw error;
    }
  };

  const deactivateBenefit = async (benefitHash: string): Promise<void> => {
    try {
      return await api.put(
        '/benefits/deactivate',
        { benefitHash },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      throw error;
    }
  };

  return {
    createBenefit,
    getBenefits,
    getBenefit,
    activateBenefit,
    deactivateBenefit,
  };
};

export default useBenefits;
