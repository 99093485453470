import React, { ReactNode, useEffect, useState } from 'react';
import api from '../api';
import { useCookies } from 'react-cookie';
import { LANGUAGES } from '../utils/constants';
import i18n from 'i18next';

interface AuthContextType {
  loginGoogle: () => Promise<string>;
  sendEmail: (email: string, language?: string) => Promise<void>;
  sendSms: (phoneNumber?: string) => Promise<void>;
  setTokenToCookie: (token: string) => void;
  verifySmsCode: (smsCode: string, temporaryToken?: string) => Promise<string>;
  getToken: () => string;
  signOut: () => void;
  setProjectToken: (token?: string) => void;
  setAccessKey: (accessKey?: string) => void;
  setProjectHash: (projectHash?: string) => void;
  setPhoneHint: (phoneHint: string) => void;
  setHasProjectAccess: (value: boolean) => void;
  setTemporaryToken: (temporaryToken: string) => void;
  isLoggedIn: boolean;
  phoneHint?: string;
  projectToken?: string;
  sessionToken?: string;
  accessKey?: string;
  projectHash?: string;
  temporaryToken?: string;
  isLoading: boolean;
  hasProjectAccess: boolean;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'token',
    'notification',
    'language',
  ]);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [sessionToken, setSessionToken] = useState<string>();
  const [projectToken, setProjectToken] = useState<string>();
  const [accessKey, setAccessKey] = useState<string>();
  const [projectHash, setProjectHash] = useState<string>();
  const [phoneHint, setPhoneHint] = useState<string>('');
  const [temporaryToken, setTemporaryToken] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasProjectAccess, setHasProjectAccess] = useState<boolean>(false);

  useEffect(() => {
    const jwtToken = cookies.token;
    if (jwtToken && isJWT(jwtToken)) {
      const payload = JSON.parse(atob(jwtToken.split('.')[1]));
      if (payload.exp >= Math.floor(Date.now() / 1000)) {
        setSessionToken(jwtToken);
        setIsLoggedIn(true);
      } else {
        signOut();
      }
    } else {
      signOut();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const languageCode = navigator.language.split('-')[0];
    const defaultLanguage =
      LANGUAGES.find((lang) => lang.code === languageCode) || LANGUAGES[0];
    if (!cookies.language) {
      setCookie('language', defaultLanguage, { path: '/' });
      i18n.changeLanguage(defaultLanguage.code);
    } else {
      i18n.changeLanguage(cookies.language.code);
    }
  }, [setCookie]);

  const isJWT = (token: string) => {
    const parts = token.split('.');
    return parts.length === 3;
  };

  const loginGoogle = async () => {
    try {
      const response = await api.get(`/authentication/google/link`);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const setTokenToCookie = (token: string) => {
    const parsedPayload = JSON.parse(atob(token.split('.')[1]));
    setCookie('token', token, {
      path: '/',
      expires: new Date(parsedPayload.exp * 1000),
    });
    setSessionToken(token);
    setIsLoggedIn(true);
  };

  const getToken = () => {
    return sessionToken || '';
  };

  const verifySmsCode = async (smsCode: string): Promise<string> => {
    try {
      const response = await api.get(
        `/authentication/sms/verify/?smsCode=${smsCode}`,
        {
          headers: {
            Authorization: `Bearer ${temporaryToken ?? sessionToken}`,
          },
        },
      );
      setTokenToCookie(response.data.token);
      return response.data.token || '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const sendEmail = async (email: string, language?: string) => {
    try {
      await api.post('/authentication/email/send', {
        email,
        language,
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const sendSms = async (phoneNumber?: string) => {
    try {
      await api.post(
        '/authentication/sms/send',
        { phoneNumber },
        {
          headers: { Authorization: `Bearer ${temporaryToken || getToken()}` },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const signOut = () => {
    try {
      setSessionToken('');
      removeCookie('token', { path: '/' });
      removeCookie('notification', { path: '/' });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
    setIsLoggedIn(false);
  };

  const value = {
    loginGoogle,
    sendEmail,
    sendSms,
    setTokenToCookie,
    verifySmsCode,
    getToken,
    signOut,
    isLoggedIn,
    phoneHint,
    temporaryToken,
    setProjectToken,
    setAccessKey,
    setProjectHash,
    setTemporaryToken,
    setPhoneHint,
    setHasProjectAccess,
    hasProjectAccess,
    isLoading,
    projectHash,
    projectToken,
    accessKey,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };
