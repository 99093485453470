import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EnterpriseProvider,
  useEnterprise,
} from '../providers/EnterpriseProvider';
import { useNavigate } from 'react-router-dom';
import { validatePolandVat } from '../utils/validators';
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {
  ClassicButton,
  ClassicInput,
  OutlineButton,
} from '../layouts/styled/buttons';
import { ROUTE } from '../utils/route';
import {
  COLORS,
  MAX_ITEMS_PER_PAGE,
  MAXIMUM_FILE_SIZE,
  OPERATIONS,
} from '../utils/constants';
import BetaAccessBanner from '../layouts/containers/BetaContainer';
import { ConnectButton } from '../components/utils/ConnectButton';
import { useAccount } from 'wagmi';
import useUtils from '../hooks/useUtils';
import Annotation from '../components/Annotation';
import { useOperation } from '../providers/OperationProvider';
import { IEnterpriseBasicData } from '../interfaces/enterprise.interface';
import { IProfile } from '../interfaces/profile.interface';
import { usePermission } from '../providers/PermissionProvider';
import { SUPER_ENTERPRISE_TAX_ID } from '../constants/common';
import { useAuth } from '../providers/AuthProvider';

const initialError = {
  name: '',
  taxId: '',
  country: '',
  checkbox: '',
};

export default function RegisterEnterprisePage() {
  const { i18n, t } = useTranslation();
  const { setOperation } = useOperation();
  const navigate = useNavigate();
  const { getGUS } = useUtils();
  const enterpriseProvider = useEnterprise();
  const permissionsProvider = usePermission();
  const auth = useAuth();
  const { isDisconnected } = useAccount();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [formData, setFormData] = useState<IEnterpriseBasicData>({
    taxId: '',
    country: '',
    name: '',
    city: '',
    street: '',
    building: '',
    apartment: '',
    zip: '',
    logoUrl: '',
    wallet: '',
    walletSignature: '',
    ownerHash: '',
  });
  const [errors, setErrors] = useState<Record<string, string>>(initialError);
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>(
    {},
  );
  const [users, setUsers] = useState<IProfile[]>([]);
  const [betaEligibility, setBetaEligibility] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [questionSearchQuery, setQuestionSearchQuery] = useState('');
  const [areUsersLoading, setAreUsersLoading] = useState<boolean>(false);
  const [isOwnerDialogOpen, setIsOwnerDialogOpen] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isOwnerDialogOpen) fetchUsers();
  }, [isOwnerDialogOpen]);

  const fetchUsers = async () => {
    setAreUsersLoading(true);
    try {
      const users = await enterpriseProvider.getEnterpriseUsers(
        SUPER_ENTERPRISE_TAX_ID,
        {
          pageSize: MAX_ITEMS_PER_PAGE,
        },
      );
      setUsers(users.data);
    } catch (error) {
      console.error('An error occurred:', error);
      setErrors({
        ...errors,
        taxId: t('common.forms.errors.gus-data-unavailable'),
      });
    } finally {
      setAreUsersLoading(false);
    }
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file && file.size <= MAXIMUM_FILE_SIZE) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const logoUrl = reader.result ? reader.result.toString() : '';
        handleInputChange('logoUrl', logoUrl);
      };
      reader.readAsDataURL(file);
    } else {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: t('common.forms.errors.file-size'),
      });
    }
  };

  useEffect(() => {
    handleValidation();
  }, [formData, checkbox]);

  const fetchGusData = async () => {
    if (!formData.taxId) {
      setErrors({
        ...errors,
        taxId: t('common.forms.errors.field-required'),
      });
      return;
    }
    if (formData.taxId.length < 8) {
      setErrors({
        ...errors,
        taxId: t('common.forms.errors.vat-number-too-short'),
      });
      return;
    }
    if (!validatePolandVat(formData.taxId)) {
      setErrors({
        ...errors,
        taxId: t('common.forms.errors.invalid-vat-number'),
      });
      return;
    }
    setErrors({
      ...errors,
      taxId: '',
    });

    setLoading(true);
    try {
      const response = await getGUS(formData.taxId);
      if (response)
        setFormData({
          ...formData,
          ...response,
        });
    } catch (error) {
      console.error('An error occurred:', error);
      setErrors({
        ...errors,
        taxId: t('common.forms.errors.gus-data-unavailable'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleValidation = () => {
    const newErrors: Record<string, string> = {};
    (Object.keys(formData) as Array<keyof IEnterpriseBasicData>).forEach(
      (field) => {
        newErrors[field] =
          (field === 'taxId' || field === 'name' || field === 'country') &&
          formData[field] === ''
            ? t('common.forms.errors.field-required')
            : '';
      },
    );
    newErrors.checkbox = !checkbox
      ? t('common.forms.errors.field-required')
      : '';
    setErrors(newErrors);
  };

  const isFormValid = () => {
    return !Object.entries(errors).some((el) => el[1] !== '');
  };
  const handleSubmit = async () => {
    if (isFormValid()) {
      await handleNext();
    } else {
      handleValidation();
    }
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      const token = await enterpriseProvider.createEnterprise({
        ...formData,
        country: formData.country || 'Polska',
      });
      if (token) {
        auth.setTokenToCookie(token);
        setIsConfirmationDialogOpen(true);
      } else {
        navigate(ROUTE.dashboardPage.enterprise.root);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    field: keyof IEnterpriseBasicData,
    value: string,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  useEffect(() => {
    if (isDisconnected) {
      setBetaEligibility(false);
      setIsConnected(false);
      handleInputChange('walletSignature', '');
      handleInputChange('wallet', '');
    }
  }, [isDisconnected]);

  const handleWalletSignature = async (signature: string, wallet: string) => {
    setIsConnected(true);
    const betaEligibility =
      await enterpriseProvider.getEnterpriseBetaEligibility(wallet, signature);
    if (betaEligibility) {
      setBetaEligibility(betaEligibility);
      handleInputChange('walletSignature', signature);
      handleInputChange('wallet', wallet);
    }
  };

  const onLinkClick = () => {
    const pdfUrl =
      i18n.language === 'pl'
        ? '/documents/dpa-pl.pdf'
        : '/documents/dpa-eng.pdf';

    window.open(pdfUrl, '_blank');
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <EnterpriseProvider>
      <BetaAccessBanner approved={betaEligibility} isConnected={isConnected} />
      <Container
        component="main"
        sx={{
          m: '0 auto',
          width: { xs: '400px', md: '600px' },
          padding: '50px',
        }}
      >
        <CssBaseline />
        <Card variant={'outlined'} sx={{ overflow: 'visible' }}>
          <Box
            sx={{
              margin: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h3" variant="h3">
              {t('pages.enterprise.title')}
            </Typography>

            <Typography component="p" variant="body1">
              {t('pages.enterprise.subtitle')}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              margin: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {formData.logoUrl && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      style={{
                        width: '50px',
                        height: '50px',
                        overflow: 'hidden',
                        marginLeft: '10px',
                      }}
                    >
                      <img
                        src={formData.logoUrl}
                        alt="Thumbnail"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <label htmlFor="imageInput">
                      <OutlineButton onClick={handleButtonClick} fullWidth>
                        {t('common.button.upload-logo-btn')}
                      </OutlineButton>
                    </label>
                    <input
                      ref={fileInputRef}
                      id="imageInput"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={handleImageUpload}
                      style={{ display: 'none' }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: 'flex-start', display: 'flex', mt: 2 }}
                >
                  <ConnectButton
                    onSignature={(e) =>
                      handleWalletSignature(e.signature, e.address)
                    }
                  />
                  <Annotation
                    tooltip={`${t('common.annotations.connect-wallet')}`}
                  />
                </Grid>

                <Grid
                  container
                  sx={{
                    minWidth: { xs: '300px', md: '500px' },
                  }}
                >
                  <Grid
                    style={{ marginTop: 1 }}
                    container
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    {permissionsProvider.hasFlag(
                      'SUPER_ENTERPRISE_CREATE_ENTERPRISE',
                      SUPER_ENTERPRISE_TAX_ID,
                    ) && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant={'h5'}>
                            {t('common.forms.labels.owner')} :
                          </Typography>
                        </Grid>
                        <Grid item xs={10} md={6}>
                          <OutlineButton
                            sx={{ m: 0 }}
                            onClick={() => setIsOwnerDialogOpen(true)}
                          >
                            {formData.ownerHash
                              ? `${formData.ownerHash.slice(
                                  0,
                                  7,
                                )}...${formData.ownerHash.slice(-6)}`
                              : 'add'}
                          </OutlineButton>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant={'h5'}>
                        {t('common.forms.labels.taxId')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={10} md={6}>
                      <ClassicInput
                        required
                        fullWidth
                        label={t('common.forms.labels.vat-number')}
                        value={formData.taxId}
                        error={touchedFields.taxId && !!errors.taxId}
                        helperText={touchedFields.taxId && errors.taxId}
                        onChange={(e) =>
                          handleInputChange('taxId', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => fetchGusData()}
                        disabled={!formData.taxId && i18n.language !== 'pl'}
                      >
                        <RefreshOutlinedIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid
                    style={{ marginTop: 1 }}
                    container
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.forms.labels.name')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ClassicInput
                        required
                        label={t('common.forms.labels.name')}
                        fullWidth
                        value={formData.name}
                        onChange={(e) =>
                          handleInputChange('name', e.target.value)
                        }
                        error={touchedFields.name && !!errors.name}
                        helperText={touchedFields.name && errors.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ marginTop: 1 }}
                    container
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.profile.country')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ClassicInput
                        required
                        label={t('common.profile.country')}
                        fullWidth
                        value={formData.country}
                        onChange={(e) =>
                          handleInputChange('country', e.target.value)
                        }
                        error={touchedFields.country && !!errors.country}
                        helperText={touchedFields.country && errors.country}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginTop: 1 }}
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.forms.labels.city')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ClassicInput
                        label={t('common.forms.labels.city')}
                        fullWidth
                        value={formData.city}
                        onChange={(e) =>
                          handleInputChange('city', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginTop: 1 }}
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.forms.labels.street')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ClassicInput
                        label={t('common.forms.labels.street')}
                        fullWidth
                        value={formData.street}
                        onChange={(e) =>
                          handleInputChange('street', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginTop: 1 }}
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.forms.labels.building')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ClassicInput
                        label={t('common.forms.labels.building')}
                        fullWidth
                        value={formData.building}
                        onChange={(e) =>
                          handleInputChange('building', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginTop: 1 }}
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.profile.apartment')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ClassicInput
                        label={t('common.profile.apartment')}
                        fullWidth
                        value={formData.apartment}
                        onChange={(e) =>
                          handleInputChange('apartment', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginTop: 1 }}
                    xs={12}
                    spacing={2}
                    textAlign="left"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="left"
                    >
                      <Typography variant={'h5'}>
                        {t('common.forms.labels.zip')} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ClassicInput
                        label={t('common.forms.labels.zip')}
                        fullWidth
                        value={formData.zip}
                        onChange={(e) =>
                          handleInputChange('zip', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0 20px',
                  margin: '10px auto',
                }}
              >
                <Checkbox
                  checked={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                />
                <Typography
                  component="span"
                  variant="body2"
                  color="primary"
                  onClick={onLinkClick}
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {t('common.data-processing-agreement.part1')}
                  {t('common.data-processing-agreement.part2')}
                </Typography>
              </Box>
              {touchedFields.checkbox && errors.checkbox && (
                <Typography
                  variant="caption"
                  sx={{
                    color: COLORS.errorColor,
                    marginLeft: '15px',
                    marginTop: '2.5px',
                    textAlign: 'center',
                    minWidth: { xs: 'auto', md: '500px' },
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {errors.checkbox}
                </Typography>
              )}
              <Grid container justifyContent="center">
                {loading ? (
                  <CircularProgress style={{ marginTop: '20px' }} />
                ) : (
                  <Grid item xs={6}>
                    <span
                      onMouseOver={() => {
                        setTouchedFields({
                          name: true,
                          taxId: true,
                          checkbox: true,
                          country: true,
                        });
                      }}
                    >
                      <ClassicButton
                        onClick={handleSubmit}
                        disabled={!isFormValid()}
                      >
                        {t('common.button.create-account-btn')}
                      </ClassicButton>
                    </span>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>

      <Dialog
        open={isOwnerDialogOpen}
        onClose={() => setIsOwnerDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant={'h5'}>{t('pages.questions.title')}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ minWidth: { xs: '20rem', md: '35rem', minHeight: '600px' } }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicInput
              fullWidth
              label={t('common.search-label.questions')}
              value={questionSearchQuery}
              onChange={(e) => setQuestionSearchQuery(e.target.value)}
            />
          </Box>
          {areUsersLoading ? (
            <CircularProgress style={{ marginTop: '20px' }} />
          ) : (
            users
              .filter((el) =>
                (el.email ?? el.secondName).includes(questionSearchQuery),
              )
              .map((el, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '90%',
                    marginBottom: 1.5,
                    p: 2,
                    border: `1px ${COLORS.accentColor} solid`,
                    borderRadius: 5,
                    cursor: 'pointer',
                    mx: 'auto',
                  }}
                  onClick={() => {
                    handleInputChange('ownerHash', el.userHash);
                    setIsOwnerDialogOpen(false);
                  }}
                >
                  <Typography variant="body1">
                    {el.email || el.secondName}
                  </Typography>
                </Box>
              ))
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isConfirmationDialogOpen}
        onClose={() => navigate(ROUTE.dashboardPage.enterprise.root)}
      >
        <DialogContent>
          <Grid
            container
            sx={{
              minWidth: '200px',
              justifyContent: 'center',
            }}
          >
            <Typography variant={'h5'}>
              {betaEligibility
                ? t('common.beta-access.congratulations')
                : t('pages.accountVerification.title')}
            </Typography>

            <Typography variant="body2">
              {betaEligibility
                ? t('common.beta-access.subtitle')
                : t('pages.accountVerification.subtitle')}
            </Typography>
            <Typography variant="body2">
              {t('pages.accountVerification.questions')}
            </Typography>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <OutlineButton
                onClick={() => navigate(ROUTE.dashboardPage.enterprise.root)}
                sx={{ width: '8rem' }}
              >
                {t('common.button.close-btn')}
              </OutlineButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </EnterpriseProvider>
  );
}
