export const PATIENT_DECLARATION = 'patient-declaration';
export const MOBILE_DEVICE_SIGNATURE = 'mobile-device-signature';
export const SIGNATURE_TYPES = [PATIENT_DECLARATION, MOBILE_DEVICE_SIGNATURE];

export const YES_NO = 'YES_NO';
export const CUSTOM = 'CUSTOM';
export const OPEN = 'OPEN';
export const NO_ANSWER = 'no';
export const YES_ANSWER = 'yes';
export const QUESTION_TYPES = [YES_NO, CUSTOM, OPEN];

export const EMPTY_PHONE_NUMBER = '000***000';

export const SUPER_ENTERPRISE_TAX_ID = '7162828483';
export const SUPER_ENTERPRISE_PHONE = '+48 663 134 604';
export const SUPER_ENTERPRISE_EMAIL = 'office@data-lake.co';

export const SUPER_ADMIN_EMAIL = 'bok@data-lake.co';
export const SUPER_ADMIN_USER_HASH =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

export const MAX_PROJECT_USERS = 1000000000;

export const DAPP_LINK = 'https://dapp.data-lake.co';

export const TRANSACTION_CHECK_TIMEOUT = 2400;
export const SEARCH_INPUT_DELAY = 600;
export const MUMBAI_URL = 'https://www.oklink.com/mumbai/tx/';
export const AMOY_URL = 'https://www.oklink.com/amoy/tx/';
export const MOKOTOW_URL = 'https://explorer.data-lake.co/tx/';
export const MIGRATION_TIMESTAMP = 1713193200;
export const MOKOTOW_MIGRATION_TIMESTAMP = 1720713600;

export const FILE_SIZE_UPLOAD_LIMIT = 2 * 1024 * 1024;
export const FILE_TYPE_LIMIT = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'image/jpg',
];

export const EMPTY_DATA = { data: [], total: 0, new: 0 };

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_SORTING = 'createdAt_DESC';

export const DEMO_PROJECT_HASH_1 =
  '0x81e43267438b794c02abe3d6fb417d4a2e6f479175c7d7cb4dbe1e1ee81d3894e';
export const DEMO_PROJECT_HASH_2 =
  '0x81ad893399648e3e5265b57c7b994281d99907cd1b3dcd2f12bc6810f3c4d558';
export const DEMO_PROJECT_ACCESS_KEY_1 = 'VmQ5S044PUMC0KEcxYZQ';
export const DEMO_PROJECT_ACCESS_KEY_2 = 'SSeRmHKvBEPpdoEt5TLN';
export const DEMO_PROJECT_EMAIL = 'demo@example.com';
export const DEMO_PROJECT_TITLE = 'Demo Project';
export const DEFAULT_ANSWER = { answer: '', id: 1 };
export const SMS_RATE_LIMIT = 30;
