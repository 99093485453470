import { Box, CircularProgress } from '@mui/material';

export default function LoadingController() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 0',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
