import { createTheme } from '@mui/material/styles';
import { COLORS } from '../../utils/constants';

export const layoutTheme = createTheme({
  typography: {
    fontFamily: "'Nunito', sans-serif",
    h1: {
      color: COLORS.primaryColor,
      fontWeight: 900,
      fontSize: 49,
      margin: '10px 0',
    },
    h2: {
      color: COLORS.primaryColor,
      fontWeight: 900,
      fontSize: 34,
      margin: '10px 0',
    },
    h3: {
      color: COLORS.primaryColor,
      fontWeight: 900,
      fontSize: 28,
      margin: '10px 0',
    },
    h4: {
      color: COLORS.primaryColor,
      fontWeight: 700,
      fontSize: 26,
    },
    h5: {
      color: COLORS.primaryColor,
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 'inherit',
    },
    h6: {
      color: COLORS.neutralColor,
      fontWeight: 700,
      fontSize: 22,
    },
    subtitle1: {
      color: COLORS.primaryColor,
      fontWeight: 600,
      fontSize: 14,
      margin: 0,
    },
    subtitle2: {
      color: COLORS.neutralColor,
      fontWeight: 600,
      fontSize: 18,
      margin: 0,
    },
    body1: {
      color: COLORS.neutralColor,
      fontSize: 14,
    },
    body2: {
      color: COLORS.neutralColor,
      fontSize: 14,
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  palette: {
    background: {
      paper: '#F2F8FE',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
          color: COLORS.primaryColor,
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          elevation: 1,
          background: '#fff',
          border: '1px solid',
          borderColor: COLORS.accentColor,
          borderRadius: 8,
          position: 'relative',
          zIndex: 0,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          borderBottom: '1px solid',
          borderColor: COLORS.accentColor,
        },
        title: {
          color: COLORS.primaryColor,
          fontWeight: 900,
          fontSize: 32,
          margin: 10,
        },
        subheader: {
          color: COLORS.primaryColor,
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid',
          borderColor: COLORS.accentColor,
          alignItems: 'center',
          paddingBottom: 15,
          paddingLeft: 5,
          paddingRight: 5,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: COLORS.primaryColor,
          border: '1px solid',
          borderColor: COLORS.accentColor,
          borderRadius: 16,
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: COLORS.primaryColor,
          },
          '&:hover': {
            color: COLORS.primaryColor,
            backgroundColor: COLORS.secondaryColor,
          },
          '&:hover:not(.Mui-selected)': {
            color: COLORS.primaryColor,
            backgroundColor: COLORS.accentColor,
            '&:active': {
              backgroundColor: COLORS.accentColor,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          fontWeight: 700,
          borderRadius: '10px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          margin: '5px 0px',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '&:hover': {
            color: COLORS.primaryColor,
            background: COLORS.accentColor,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 32,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#fff',
          borderRight: '1px solid',
          borderColor: COLORS.accentColor,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.secondaryColor,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#fff',
          boxSizing: 'border-box',
          overflow: 'hidden',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          borderRight: '1px solid',
          borderColor: COLORS.accentColor,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '& .MuiList-root': {
            '& .MuiListItem-root': {
              padding: '2px 15px',
              margin: 0,
              border: 'none',
              '&:hover': {
                color: COLORS.accentColor,
              },
            },
            '& .MuiCollapse-wrapper': {
              padding: '2px 15px',
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: COLORS.secondaryColor,
          backgroundColor: '#DEF0F4',
          padding: 3,
          margin: 2,
        },
        label: {
          fontWeight: 700,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          border: '1px solid',
          borderRadius: '20px',
          borderColor: COLORS.accentColor,
        },
        tag: {
          border: '1px solid',
          borderRadius: '20px',
          borderColor: COLORS.secondaryColor,
        },
        popupIndicator: {
          color: COLORS.accentColor,
        },
      },
    },
  },
});
