import React, { useState } from 'react';
import SignUp from '../components/forms/magic-links/MagicLinkLoginForm';
import PopupContainer from '../layouts/containers/PopupContainer';
import SignUpSuccess from '../components/forms/magic-links/MagicLinkLoginSuccessForm';
import { useTranslation } from 'react-i18next';
import GoogleLoginForm from '../components/forms/google/GoogleLoginForm';
import TermsFooter from '../components/TermsFooter';
import { Typography } from '@mui/material';
import packageJson from './../../package.json';

export default function HomePage() {
  const { t } = useTranslation();
  const [isSuccess, setSuccess] = useState(false);
  const disabled = process.env.REACT_APP_DISABLE_LOGIN === 'true';

  const renderContent = () => {
    if (isSuccess) {
      return (
        <PopupContainer title={t('common.success')}>
          <SignUpSuccess />
        </PopupContainer>
      );
    } else {
      return (
        <>
          <PopupContainer
            title={
              disabled
                ? t('pages.signup.welcome-disabled')
                : t('common.welcome')
            }
            subtitle={
              disabled
                ? t('pages.signup.disabled-description')
                : t('pages.signup.description')
            }
            footer={<TermsFooter />}
            bottom={
              <Typography component="p" variant="body1" color={'white'}>
                v{packageJson.version}
              </Typography>
            }
          >
            <SignUp disabled={disabled} onClick={() => setSuccess(true)} />
            <GoogleLoginForm disabled={disabled} />
          </PopupContainer>
        </>
      );
    }
  };

  return <>{renderContent()}</>;
}
