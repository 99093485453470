import moment from 'moment';
import {
  AMOY_URL,
  MIGRATION_TIMESTAMP,
  MOKOTOW_MIGRATION_TIMESTAMP,
  MOKOTOW_URL,
  MUMBAI_URL,
} from '../constants/common';

export function getBlockExplorer(timestamp: number) {
  let baseUrl;

  if (moment(timestamp).isBefore(moment(MIGRATION_TIMESTAMP))) {
    baseUrl = MUMBAI_URL;
  } else if (moment(timestamp).isBefore(moment(MOKOTOW_MIGRATION_TIMESTAMP))) {
    baseUrl = AMOY_URL;
  } else {
    // Handle the case for migration number 2
    baseUrl = MOKOTOW_URL;
  }

  return baseUrl;
}
