import { Box, Typography, Button, Container } from '@mui/material';
import { FaRegComment, FaRegEnvelope, FaRegFrown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function NoNumber() {
  const { t } = useTranslation();

  const handleEmail = () => {
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

    if (supportEmail) {
      window.location.href = `mailto:${supportEmail}`;
    } else {
      console.error('E-mail not exist in .env file');
    }
  };

  const handleChat = () => {
    try {
      window.Tawk_API?.maximize();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        component={FaRegFrown}
        sx={{
          width: '10%',
          height: 'auto',
          color: 'primary.main',
          marginY: '20px',
        }}
      />
      <Typography variant="body1" sx={{ paddingBottom: '10px' }}>
        {t('common.no-number')}
      </Typography>
      <Container
        maxWidth={false}
        sx={{
          height: '100%',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px', // Adjust the gap between buttons as needed
          }}
        >
          <Button
            startIcon={<FaRegComment />}
            variant="contained"
            onClick={handleChat}
          >
            {t('common.button.chat-btn')}
          </Button>
          <Button
            startIcon={<FaRegEnvelope />}
            variant="contained"
            onClick={handleEmail}
          >
            {t('common.button.email-btn')}
          </Button>
        </div>
      </Container>
    </>
  );
}
