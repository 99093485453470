import { Card, Container } from '@mui/material';
import { useAuth } from '../../providers/AuthProvider';
import ProjectStep from './ProjectStep';
import EmailStep from './EmailStep';
import { useEffect, useState } from 'react';
import LoadingController from '../../components/utils/LoadingController';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ROUTE } from '../../utils/route';

export default function ProjectFlowPage() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!auth.isLoading && (auth.hasProjectAccess || auth.isLoggedIn)) {
      const projectHash = searchParams.get('projectHash');
      if (projectHash) auth.setProjectHash(projectHash);
      setIsLoading(false);
    }
  }, [auth.isLoading, auth.hasProjectAccess, auth.isLoggedIn]);

  return (
    <Container maxWidth="lg">
      <Card>
        {isLoading ? (
          <LoadingController />
        ) : auth.projectHash ? (
          auth.accessKey ? (
            <EmailStep
              projectHash={auth.projectHash}
              accessKey={auth.accessKey}
            />
          ) : (
            <ProjectStep />
          )
        ) : (
          <Navigate to={ROUTE.error404} />
        )}
      </Card>
    </Container>
  );
}
