import {
  Box,
  CardContent,
  CardHeader,
  Container,
  Link,
  Typography,
} from '@mui/material';
import { ClassicButton } from '../../../layouts/styled/buttons';
import { useTranslation } from 'react-i18next';

interface Props {
  onButtonClick: () => void;
}

export default function Congratulations({ onButtonClick }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <CardHeader
        title={
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '2.5rem', md: '3rem' } }}
            >
              {t('userflow.congratulations.title')}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Container
          maxWidth={false}
          sx={{
            height: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            component="span"
            variant="body1"
            sx={{ fontSize: '1.25rem' }}
          >
            {t('userflow.congratulations.description')}
          </Typography>

          <ClassicButton
            sx={{
              width: '10rem',
              my: 3,
            }}
            onClick={onButtonClick}
          >
            {t(`common.button.login-btn`)}
          </ClassicButton>
          <Typography
            component="span"
            variant="body1"
            sx={{ fontSize: '1.25rem' }}
          >
            {t('userflow.congratulations.contact')}{' '}
            <Link href={'mailto:office@data-lake.co'}>office@data-lake.co</Link>
          </Typography>
          <Box>
            <img
              src={`/static/illustrations/welcome.png`}
              width={'100%'}
              alt=""
            />
          </Box>
        </Container>
      </CardContent>
    </>
  );
}
