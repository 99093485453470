import { useTranslation } from 'react-i18next';
import { ClassicButton, OutlineButton } from './../../layouts/styled/buttons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';

type Props = {
  description: string;
  open: boolean;
  handleClose: () => void;
  onConfirmClick: () => void;
};

export default function ConfirmationDialog({
  description,
  open,
  handleClose,
  onConfirmClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Grid
          container
          sx={{
            minWidth: '200px',
            justifyContent: 'center',
          }}
        >
          <Typography variant={'h5'}>{description}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', md: 'flex-end' },
          width: '100%',
        }}
      >
        <OutlineButton sx={{ maxWidth: '10rem' }} onClick={handleClose}>
          {t('common.forms.labels.no')}
        </OutlineButton>

        <ClassicButton sx={{ maxWidth: '10rem' }} onClick={onConfirmClick}>
          {t('common.forms.labels.yes')}
        </ClassicButton>
      </DialogActions>
    </Dialog>
  );
}
