import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/constants';

export default function SignUpSuccess() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CheckCircleIcon
        sx={{
          margin: 5,
          fontSize: 100,
          color: COLORS.primaryColor,
        }}
      />
      <Typography component="p" variant="body1">
        {t('pages.signup.success-msg')}
      </Typography>
    </Box>
  );
}
