import {
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ErrorPage from '../pages/ErrorPage';
import React, { ReactNode } from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ConsentPage from '../pages/dashboard/consents/ConsentPage';
import QuestionsPage from '../pages/dashboard/questions/QuestionsPage';
import BenefitsPage from '../pages/dashboard/benefits/BenefitsPage';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PollIcon from '@mui/icons-material/Poll';
import LogPage from '../pages/dashboard/settings/LogPage';
import ProfilePage from '../pages/dashboard/settings/ProfilePage';
import RegisterEnterprisePage from '../pages/RegisterEnterprisePage';
import ProjectPage from '../pages/dashboard/projects/ProjectPage';
import PersonIcon from '@mui/icons-material/Person';
import ProjectIcon from '@mui/icons-material/Addchart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NotificationPage from '../pages/dashboard/notifications/NotificationPage';
import UserPage from '../pages/dashboard/users/UserPage';
import BenefitAddPage from '../pages/dashboard/benefits/BenefitAddPage';
import SimpleLayout from '../layouts/SimpleLayout';
import ManageAccountIcon from '@mui/icons-material/ManageAccounts';
import EnterprisePage from '../pages/dashboard/enterprises/EnterprisePage';
import NotificationAddPage from '../pages/dashboard/notifications/NotificationAddPage';
import UserAddPage from '../pages/dashboard/users/UserAddPage';
import TagPage from '../pages/dashboard/tags/TagPage';
import TagAddPage from '../pages/dashboard/tags/TagAddPage';
import TagIcon from '@mui/icons-material/Tag';
import ProjectFlowPage from '../pages/userflow/ProjectFlowPage';
import FlowLayout from '../layouts/FlowLayout';
import ProjectInvitePage from '../pages/dashboard/projects/ProjectInvitePage';
import { ROUTE } from '../utils/route';
import SMSVerificationPage from '../pages/phone/PhonePage';
import UserDataPage from '../pages/dashboard/userData/UserDataPage';
import * as Sentry from '@sentry/react';
import WidgetPage from '../pages/WidgetPage';
import { AuthWrapper } from '../layouts/AuthWrapper';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.5,
  tracePropagationTargets: [
    /^https:\/\/app-review.data-lake\.co\//,
    /^https:\/\/app.data-lake\.co\//,
  ],
  environment: process.env.REACT_APP_URL,
});

export interface RouterPage {
  key: string;
  path: string;
  permissions: string[];
  icon?: ReactNode;
  layout?: ReactNode;
  element: ReactNode;
  subpages?: RouterPage[];
}

const rootPage: RouterPage[] = [
  {
    key: '',
    path: ROUTE.rootPage,
    permissions: [''],
    layout: <DefaultLayout />,
    element: (
      <AuthWrapper type="Home">
        <HomePage />
      </AuthWrapper>
    ),
  },
  {
    key: '',
    path: ROUTE.projectFlowPage.add,
    permissions: [''],
    layout: <FlowLayout />,
    element: (
      <AuthWrapper type="Basic">
        <ProjectFlowPage />
      </AuthWrapper>
    ),
  },
  {
    key: '',
    path: ROUTE.projectFlowPage.join,
    permissions: [''],
    layout: <FlowLayout />,
    element: (
      <AuthWrapper type="Project">
        <ProjectFlowPage />
      </AuthWrapper>
    ),
  },
  {
    key: '',
    path: ROUTE.error404,
    permissions: [''],
    layout: <DefaultLayout />,
    element: <ErrorPage />,
  },
  {
    key: '',
    path: ROUTE.widget,
    permissions: [''],
    layout: <DefaultLayout />,
    element: <WidgetPage />,
  },
  {
    key: '',
    path: ROUTE.registerPage,
    permissions: [''],
    layout: <SimpleLayout />,
    element: (
      <AuthWrapper type="Basic">
        <RegisterEnterprisePage />
      </AuthWrapper>
    ),
  },
  {
    key: '',
    path: ROUTE.smsVerificationPage,
    permissions: [''],
    layout: <DefaultLayout />,
    element: (
      <AuthWrapper type="SMS">
        <SMSVerificationPage />
      </AuthWrapper>
    ),
  },
];

const dashboardPage: RouterPage[] = [
  {
    key: 'pages.project.title',
    path: ROUTE.dashboardPage.projects.root,
    permissions: ['USER_GET_PROJECT'],
    icon: <ProjectIcon sx={{ fontSize: 32 }} />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <ProjectPage />
      </AuthWrapper>
    ),
    subpages: [
      {
        key: '',
        path: ROUTE.dashboardPage.projects.inviteParticipant,
        permissions: [],
        icon: <ProjectIcon />,
        layout: <SimpleLayout />,
        element: (
          <AuthWrapper type="Basic">
            <ProjectInvitePage />
          </AuthWrapper>
        ),
      },
    ],
  },
  {
    key: 'pages.userData.title',
    path: ROUTE.dashboardPage.userData.root,
    permissions: ['USER_GET_OWN_CONSENTS'],
    icon: <DescriptionOutlinedIcon sx={{ fontSize: 26 }} />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <UserDataPage />
      </AuthWrapper>
    ),
  },
  {
    key: 'pages.consents.title',
    path: ROUTE.dashboardPage.consents.root,
    permissions: ['ENTERPRISE_CREATE_CONSENT_DRAFT'],
    icon: <CheckCircleIcon sx={{ fontSize: 26 }} />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <ConsentPage />
      </AuthWrapper>
    ),
  },

  {
    key: 'pages.questions.title',
    path: ROUTE.dashboardPage.questions.root,
    permissions: ['ENTERPRISE_CREATE_QUESTION'],
    icon: <PollIcon sx={{ fontSize: 26 }} />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <QuestionsPage />
      </AuthWrapper>
    ),
  },

  {
    key: 'pages.benefits.title',
    path: ROUTE.dashboardPage.benefits.root,
    permissions: ['USER_GET_BENEFITS'],
    icon: <CardGiftcardIcon sx={{ fontSize: 26 }} />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <BenefitsPage />
      </AuthWrapper>
    ),
    subpages: [
      {
        key: '',
        path: ROUTE.dashboardPage.benefits.add,
        permissions: [''],
        layout: <SimpleLayout />,
        element: (
          <AuthWrapper type="Basic">
            <BenefitAddPage />
          </AuthWrapper>
        ),
      },
    ],
  },

  {
    key: 'pages.enterprises.title',
    path: ROUTE.dashboardPage.enterprise.root,
    permissions: ['USER_GET_ALL_ENTERPRISES_DATA'],
    icon: <ManageAccountIcon sx={{ fontSize: 26 }} />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <EnterprisePage />
      </AuthWrapper>
    ),
    subpages: [
      {
        key: '',
        path: ROUTE.dashboardPage.enterprise.tags,
        permissions: [],
        icon: <TagIcon />,
        layout: <SimpleLayout />,
        element: (
          <AuthWrapper type="Basic">
            <TagPage />
          </AuthWrapper>
        ),
        subpages: [
          {
            key: '',
            path: ROUTE.dashboardPage.enterprise.addTag,
            permissions: [],
            icon: <TagIcon />,
            layout: <SimpleLayout />,
            element: (
              <AuthWrapper type="Basic">
                <TagAddPage />{' '}
              </AuthWrapper>
            ),
          },
        ],
      },
      {
        key: '',
        path: ROUTE.dashboardPage.enterprise.users,
        permissions: ['ENTERPRISE_GET_USERS'],
        icon: <CheckCircleIcon />,
        layout: <SimpleLayout />,
        element: (
          <AuthWrapper type="Basic">
            <UserPage />
          </AuthWrapper>
        ),
      },
      {
        key: '',
        path: ROUTE.dashboardPage.enterprise.addUser,
        permissions: ['ENTERPRISE_ADD_USERS'],
        icon: <CheckCircleIcon />,
        layout: <SimpleLayout />,
        element: (
          <AuthWrapper type="Basic">
            <UserAddPage />
          </AuthWrapper>
        ),
      },
    ],
  },
];

const profilePage: RouterPage[] = [
  {
    key: 'pages.profile.title',
    path: ROUTE.dashboardPage.profile.root,
    permissions: ['USER_MODIFY_USER_DATA'],
    icon: <PersonIcon fontSize="small" />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <ProfilePage />
      </AuthWrapper>
    ),
    subpages: [],
  },
  {
    key: 'pages.notification.title',
    path: ROUTE.dashboardPage.notification.root,
    permissions: ['USER_GET_NOTIFICATIONS'],
    icon: <NotificationsIcon fontSize="small" />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <NotificationPage />
      </AuthWrapper>
    ),
    subpages: [
      {
        key: 'pages.notification.add',
        path: ROUTE.dashboardPage.notification.add,
        permissions: ['ENTERPRISE_SEND_NOTIFICATION'],
        icon: <NotificationsIcon fontSize="small" />,
        layout: <SimpleLayout />,
        element: (
          <AuthWrapper type="Basic">
            <NotificationAddPage />
          </AuthWrapper>
        ),
      },
    ],
  },
  {
    key: 'pages.operations.title',
    path: ROUTE.dashboardPage.operations.root,
    permissions: ['ENTERPRISE_GET_LOGS'],
    icon: <ListAltIcon fontSize="small" />,
    layout: <DashboardLayout />,
    element: (
      <AuthWrapper type="Basic">
        <LogPage />
      </AuthWrapper>
    ),
  },
];

function generateRouterConfig(pages: RouterPage[]): any[] {
  const flattenedMap = new Map<string, RouterPage>();

  const processPage = (page: RouterPage) => {
    flattenedMap.set(page.path, page);
    page.subpages?.forEach(processPage);
  };

  pages.forEach(processPage);

  return Array.from(flattenedMap.values()).map((page) => ({
    path: page.path,
    element: page.layout,
    errorElement: <ErrorPage />,
    children: [{ path: page.path, element: page.element }],
  }));
}

const routerConfig = generateRouterConfig([
  ...rootPage,
  ...dashboardPage,
  ...profilePage,
]);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routerConfig);

export { router, rootPage, dashboardPage, profilePage };
