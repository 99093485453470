import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ClassicButton,
  UnderlinedButton,
} from '../../../../layouts/styled/buttons';
import ElementItem from '../../../../components/items/ElementItem';
import { ROUTE } from '../../../../utils/route';
import PermissionGuard from '../../../../guards/PermissionGuard';
import { useNavigate } from 'react-router-dom';
import { useEnterprise } from '../../../../providers/EnterpriseProvider';
import { useOperation } from '../../../../providers/OperationProvider';
import { IBenefit } from '../../../../interfaces/benefits.interface';
import useBenefits from '../../../../hooks/useBenefits';
import { OPERATIONS } from '../../../../utils/constants';

interface Props {
  benefit: IBenefit;
}

export default function BenefitItem({ benefit }: Props) {
  const navigate = useNavigate();
  const { activateBenefit, deactivateBenefit } = useBenefits();
  const enterpriseProvider = useEnterprise();
  const { setOperation } = useOperation();

  const { t } = useTranslation();
  const [activate, setActivation] = useState(false);

  const selectedEnterprise = enterpriseProvider.enterprises.data.find(
    (item) => {
      return item.taxId === benefit.taxId;
    },
  );

  const handleBenefitActivation = async () => {
    setActivation(true);
    setOperation({
      severity: OPERATIONS.INFO,
    });
    try {
      benefit.activated
        ? await deactivateBenefit(benefit.benefitHash)
        : await activateBenefit(benefit.benefitHash);
      benefit.activated = !benefit.activated;
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: benefit.activated
          ? t('pages.operations.messages.benefit-activated')
          : t('pages.operations.messages.benefit-deactivated'),
      });
    } catch (error) {
      console.error(error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setActivation(false);
    }
  };

  return (
    <ElementItem
      title={benefit.title}
      description={benefit.description}
      chip={[`${benefit.pointsPrice} pkt`]}
      gradient={benefit.gradient}
      enterpriseLogo={selectedEnterprise?.logoUrl}
      children={
        <>
          <ClassicButton disabled={true}>
            {t('common.button.claim-btn')}
          </ClassicButton>
          <PermissionGuard
            permissions={[
              'ENTERPRISE_ACTIVATE_BENEFIT',
              'ENTERPRISE_DEACTIVATE_BENEFIT',
            ]}
            taxId={benefit.taxId}
          >
            <ClassicButton
              onClick={handleBenefitActivation}
              startIcon={
                activate && (
                  <CircularProgress style={{ color: '#FFF' }} size={20} />
                )
              }
            >
              {benefit.activated
                ? t('common.button.deactivate-btn')
                : t('common.button.activate-btn')}
            </ClassicButton>
          </PermissionGuard>
          <UnderlinedButton
            key="more-info-button"
            sx={{ paddingBottom: '10px' }}
            onClick={() =>
              navigate(ROUTE.dashboardPage.benefits.add, {
                state: {
                  previousPath: ROUTE.dashboardPage.benefits.root,
                  benefitHash: benefit.benefitHash,
                },
              })
            }
          >
            {t('common.button.more-info-btn')}
          </UnderlinedButton>
        </>
      }
    />
  );
}
