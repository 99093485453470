import { useEffect, useState } from 'react';
import useProjects, { DEFAULT_STATISTICS } from '../../../../hooks/useProjects';
import LoadingController from '../../../../components/utils/LoadingController';
import { useTranslation } from 'react-i18next';
import DynamicTable from '../../../../components/builders/DynamicTable';
import { useOperation } from '../../../../providers/OperationProvider';
import { IProjectStatistics } from '../../../../interfaces/projects.interface';
import { OPERATIONS } from '../../../../utils/constants';

interface Props {
  projectHash: string;
}

export default function UsersInProjectStatisticsTable({ projectHash }: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getProjectStatistics } = useProjects();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statistics, setStatistics] =
    useState<IProjectStatistics>(DEFAULT_STATISTICS);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setStatistics(await getProjectStatistics(projectHash));
    } catch (error) {
      console.error('Error fetching statistics:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingController />
      ) : (
        <DynamicTable
          title={t('common.labels.users-in-project-statistics-table')}
          data={[statistics]}
          columns={[
            {
              label: t('common.tables.header.today'),
              accessor: 'today',
              type: 'today',
              formatter: (value) => value || 0,
            },
            {
              label: t('common.tables.header.yesterday'),
              accessor: 'yesterday',
              type: 'yesterday',
              formatter: (value) => value || 0,
            },
            {
              label: t('common.tables.header.thisWeek'),
              accessor: 'thisWeek',
              type: 'thisWeek',
              formatter: (value) => value || 0,
            },
            {
              label: t('common.tables.header.thisMonth'),
              accessor: 'thisMonth',
              type: 'thisMonth',
              formatter: (value) => value || 0,
            },
            {
              label: t('common.tables.header.lastMonth'),
              accessor: 'lastMonth',
              type: 'lastMonth',
              formatter: (value) => value || 0,
            },
            {
              label: t('common.tables.header.all'),
              accessor: 'all',
              type: 'all',
              formatter: (value) => value || 0,
            },
          ]}
          onHeaderClick={() => {}}
          onItemClick={() => {}}
          onExpandClick={() => {}}
        />
      )}
    </>
  );
}
