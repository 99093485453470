import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TermsFooter() {
  const { i18n, t } = useTranslation();
  const openTerm = () => {
    const pdfUrl =
      i18n.language === 'pl'
        ? '/documents/tos-pl.pdf'
        : '/documents/tos-eng.pdf';

    window.open(pdfUrl, '_blank');
  };
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ m: 3 }}
    >
      {t('common.terms-of-service.part1')}
      <Typography
        component="span"
        variant="body2"
        color="primary"
        onClick={openTerm}
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        {t('common.terms-of-service.part2')}
      </Typography>
    </Typography>
  );
}
