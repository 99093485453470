import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container } from '@mui/material';
import { FaKey } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

type Props = {
  phoneHint: string;
  smsTimeout: number;
  onSendClick(): Promise<void>;
  onVerifyClick(code: string): Promise<void>;
};

export default function ConfirmSMS({
  phoneHint,
  smsTimeout,
  onSendClick,
  onVerifyClick,
}: Props) {
  const [verificationCode, setVerificationCode] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const { t } = useTranslation();

  const handleCodeChange = (index: number, value: string) => {
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 1);
    setVerificationCode((prevCodes) => {
      const newCodes = [...prevCodes];
      newCodes[index] = sanitizedValue;
      if (sanitizedValue && index < 5) {
        document.getElementById(`codeInput${index + 1}`)?.focus();
      }
      return newCodes;
    });
  };

  const handleCodeKeyPress = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (event.key === 'Backspace' && index > 0) {
      document.getElementById(`codeInput${index - 1}`)?.focus();
    } else if (event.key === 'ArrowLeft' && index > 0) {
      document.getElementById(`codeInput${index - 1}`)?.focus();
    } else if (event.key === 'ArrowRight' && index < 5) {
      document.getElementById(`codeInput${index + 1}`)?.focus();
    }
  };

  return (
    <>
      <Box
        component={FaKey}
        sx={{
          width: '10%',
          height: 'auto',
          color: 'primary.main',
          marginY: '20px',
        }}
      />
      <Typography variant="body1" sx={{ paddingBottom: '10px' }}>
        {t('common.enter-code')}: <br />
        <b>{phoneHint}</b>
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        {verificationCode.map((code, index) => (
          <TextField
            key={index}
            id={`codeInput${index}`}
            variant="outlined"
            value={code}
            onChange={(event) => handleCodeChange(index, event.target.value)}
            onKeyUp={(event) => handleCodeKeyPress(index, event)}
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center', fontSize: '1.5em' },
            }}
            sx={{ margin: '0 8px' }}
          />
        ))}
      </Box>
      <Container
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: '0!important',
        }}
      >
        <Button
          variant="contained"
          onClick={() => onSendClick()}
          disabled={!!smsTimeout}
          sx={{ maxWidth: '10rem' }}
        >
          {t('common.button.send-code-btn')}
        </Button>
        <Button
          variant="contained"
          onClick={() => onVerifyClick(verificationCode.join(''))}
          disabled={verificationCode.some((el) => !el)}
          sx={{ maxWidth: '10rem' }}
        >
          {t('common.button.verify-btn')}
        </Button>
      </Container>
      {smsTimeout > 0 && (
        <Typography variant="body2" align="center">
          {t('common.sms-rate-limit')} {smsTimeout < 10 ? '00:0' : '00:'}
          {smsTimeout}
        </Typography>
      )}
    </>
  );
}
