import { ReactNode, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useAuth } from '../providers/AuthProvider';
import { ROUTE } from '../utils/route';

type Props = {
  children: ReactNode;
  type: 'Home' | 'SMS' | 'Project' | 'Basic';
};

const AuthWrapper = ({ children, type }: Props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleAuth = () => {
      switch (type) {
        case 'Home':
          if (auth.isLoggedIn) navigate(ROUTE.dashboardPage.rootPage);
          break;
        case 'SMS':
          const temporaryToken = searchParams.get('temporaryToken');
          if (!temporaryToken) {
            navigate(ROUTE.rootPage);
          } else {
            auth.setPhoneHint(searchParams.get('phoneHint') || '');
            auth.setTemporaryToken(temporaryToken);
          }
          break;
        case 'Project':
          const projectToken = searchParams.get('token') || undefined;
          const accessKey = searchParams.get('accessKey') || undefined;
          const projectHash = searchParams.get('projectHash') || undefined;
          if (!projectToken && !(accessKey && projectHash)) {
            navigate(ROUTE.rootPage);
          } else {
            auth.setProjectToken(projectToken);
            auth.setAccessKey(accessKey);
            auth.setProjectHash(projectHash);
            auth.setPhoneHint(searchParams.get('phoneHint') || '');
            auth.setHasProjectAccess(true);
          }
          break;
        case 'Basic':
          if (!auth.isLoggedIn) {
            navigate(ROUTE.rootPage);
          }
          break;
        default:
          break;
      }
    };

    if (!auth.isLoading) {
      handleAuth();
    }
  }, [type, auth.isLoading]);

  if (auth.isLoading) return <CircularProgress />;

  return <>{children}</>;
};

export { AuthWrapper };
