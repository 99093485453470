import DynamicTable from '../../../components/builders/DynamicTable';
import { useTranslation } from 'react-i18next';
import {
  IQuestion,
  IQuestionHistory,
} from '../../../interfaces/questions.interface';
import { Box, Chip } from '@mui/material';
import { YES_NO } from '../../../constants/common';
import LoadingController from '../../../components/utils/LoadingController';
import NoData from '../../../components/utils/NoData';
import moment from 'moment';

interface Props {
  question: IQuestion;
  data: IQuestionHistory[];
  isLoading: boolean;
}

export default function QuestionHistory({ question, data, isLoading }: Props) {
  const { t } = useTranslation();

  return isLoading ? (
    <LoadingController />
  ) : data.length > 0 ? (
    <Box sx={{ overflowY: 'scroll', maxHeight: '300px' }}>
      <DynamicTable
        title={t('common.labels.users-in-question-table')}
        data={data}
        columns={[
          {
            label: t('common.tables.header.email'),
            accessor: 'user',
            type: 'userEmail',
          },
          {
            label: t('common.tables.header.phone'),
            accessor: 'user',
            type: 'userPhone',
          },
          {
            label: t('common.tables.header.first-name'),
            accessor: 'user',
            type: 'userFirstName',
          },
          {
            label: t('common.tables.header.second-name'),
            accessor: 'user',
            type: 'userSecondName',
          },
          {
            label: t('common.labels.personalId'),
            accessor: 'user',
            type: 'userPersonalId',
          },
          {
            label: question.question,
            type: 'question',
            accessor: (item: IQuestionHistory) => {
              const answer = item.answers.find(
                (answer) => answer.question === question.question,
              );
              if (answer) {
                return answer.type === YES_NO && answer.answer
                  ? t(`common.forms.labels.${answer.answer}`)
                  : answer.answer;
              }
            },
          },
          {
            label: t('common.tables.header.created-at'),
            type: 'timestamp',
            accessor: (item: IQuestionHistory) => {
              const answer = item.answers.find(
                (answer) => answer.question === question.question,
              );

              return answer && !!answer.timestamp ? (
                <span>
                  {moment.unix(answer.timestamp).format('DD-MM-YYYY HH:mm:ss')}
                </span>
              ) : (
                <Chip
                  label={t('common.no-data')}
                  color="default"
                  variant="outlined"
                  size="small"
                />
              );
            },
          },
        ]}
        onHeaderClick={() => {}}
        onItemClick={() => {}}
        onExpandClick={() => {}}
      />
    </Box>
  ) : (
    <NoData />
  );
}
