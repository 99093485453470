import { useState } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { FaMobileAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { COLORS } from '../../../utils/constants';

type Props = {
  onSendClick(phoneNumber: string): Promise<void>;
};

export default function NewNumber({ onSendClick }: Props) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const { t } = useTranslation();

  const handleVerification = async () => {
    await onSendClick(phoneNumber);
  };

  return (
    <>
      <Box
        component={FaMobileAlt}
        sx={{
          width: '10%',
          height: 'auto',
          color: 'primary.main',
          marginY: '20px',
        }}
      />
      <Typography variant="body1" sx={{ paddingBottom: '10px' }}>
        {t('common.enter-number')}:
      </Typography>
      <Container
        maxWidth={false}
        sx={{
          height: '100%',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        <PhoneInput
          value={phoneNumber}
          country={'pl'}
          onChange={(e) => setPhoneNumber(e.length > 0 ? '+' + e : e)}
          inputStyle={{
            width: '100%',
            color: COLORS.primaryColor,
            borderColor: COLORS.accentColor,
            borderRadius: '20px',
          }}
          dropdownStyle={{ height: '300px' }}
        />
      </Container>
      <Container
        maxWidth={false}
        sx={{
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Button variant="contained" onClick={handleVerification}>
          {t('common.button.send-code-btn')}
        </Button>
      </Container>
    </>
  );
}
