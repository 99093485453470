import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer';
import PDFStyle from '../../../../layouts/styled/pdf';
import moment from 'moment';
import { IProjectUserData } from '../../../../interfaces/projects.interface';
import { IConsent } from '../../../../interfaces/consents.interface';
import { IQuestion } from '../../../../interfaces/questions.interface';
import {
  OPEN,
  PATIENT_DECLARATION,
  YES_NO,
} from '../../../../constants/common';
import { useTranslation } from 'react-i18next';
import { getBlockExplorer } from '../../../../utils/getLink';

interface Props {
  projectTitle: string;
  questions: IQuestion[];
  consents: IConsent[];
  projectOverview?: IProjectUserData;
}

export default function ProjectPrint({
  projectTitle,
  questions,
  consents,
  projectOverview,
}: Props) {
  const { t } = useTranslation();
  const footerRowHeight = 25;
  return (
    <Document>
      <Page size="A4" style={PDFStyle.page} wrap>
        <View style={PDFStyle.titleSection}>
          <Text style={PDFStyle.title}>{projectTitle}</Text>
        </View>
        <View style={PDFStyle.userDataSection} wrap>
          <View style={PDFStyle.userDataLine}>
            <Text style={PDFStyle.userDataTitle}>
              {t('common.prints.fullName')}:
            </Text>
            <Text style={PDFStyle.userDataValue}>
              {projectOverview
                ? projectOverview.user.firstName || '-'
                : '....................'}{' '}
              {projectOverview
                ? projectOverview.user.secondName || '-'
                : '....................'}
            </Text>
          </View>
          <View style={PDFStyle.userDataLine}>
            <Text style={PDFStyle.userDataTitle}>
              {t('common.prints.personalId')}:
            </Text>
            <Text style={PDFStyle.userDataValue}>
              {projectOverview
                ? projectOverview.user.personalId || '-'
                : '....................'}
            </Text>
          </View>
          <View style={PDFStyle.userDataLine}>
            <Text style={PDFStyle.userDataTitle}>
              {t('common.prints.phone')}:
            </Text>
            <Text style={PDFStyle.userDataValue}>
              {projectOverview
                ? projectOverview.user.phone || '-'
                : '....................'}
            </Text>
          </View>
          <View style={PDFStyle.userDataLine}>
            <Text style={PDFStyle.userDataTitle}>
              {t('common.prints.email')}:
            </Text>
            <Text style={PDFStyle.userDataValue}>
              {projectOverview
                ? projectOverview.user.email || '-'
                : '....................'}
            </Text>
          </View>
        </View>
        {questions.length > 0 && (
          <View style={PDFStyle.questionSection} wrap>
            <Text style={PDFStyle.questionTitle}>
              {t('common.prints.question')}:
            </Text>
            {questions.map((question) => (
              <View key={question.id} style={PDFStyle.question} wrap={false}>
                <Text style={PDFStyle.questionText}>{question.question}</Text>
                <View
                  style={
                    question.type === YES_NO
                      ? PDFStyle.checkboxContainer
                      : PDFStyle.question
                  }
                >
                  {question.type !== OPEN ? (
                    question.answers.map((answer, index) => (
                      <View key={index} style={PDFStyle.answersContainer}>
                        <View style={PDFStyle.checkbox}>
                          {projectOverview &&
                            projectOverview.answers &&
                            projectOverview.answers.some(
                              (questionAnswer) =>
                                questionAnswer.questionId === question.id &&
                                questionAnswer.answer === answer.answer,
                            ) && <View style={PDFStyle.selectedCheckbox} />}
                        </View>
                        <Text style={PDFStyle.answerText}>
                          {question.type === YES_NO && answer
                            ? t(`common.forms.labels.${answer.answer}`)
                            : answer.answer}
                        </Text>
                      </View>
                    ))
                  ) : (
                    <Text style={PDFStyle.answerText}>
                      {projectOverview &&
                      projectOverview.answers &&
                      projectOverview.answers.some(
                        (questionAnswer) =>
                          questionAnswer.questionId === question.id,
                      )
                        ? projectOverview.answers.find(
                            (questionAnswer) =>
                              questionAnswer.questionId === question.id,
                          )!.answer
                        : '....................'}
                    </Text>
                  )}
                </View>
              </View>
            ))}
          </View>
        )}
      </Page>

      {consents.map((consent, index) => {
        const consentStatus = projectOverview?.operations.find(
          (el) => el.consentHash === consent.consentHash,
        );
        return (
          <Page size="A4" style={PDFStyle.page} wrap>
            <View>
              <Text style={PDFStyle.consentTitle}>
                {t('common.prints.consents')}:
              </Text>
              <View style={PDFStyle.consentsSection}>
                <Text style={PDFStyle.consentTitleText}>{consent.title}</Text>
                <Text style={PDFStyle.consentContentText}>
                  {consent.content}
                </Text>
                <View style={PDFStyle.signatureSection} wrap={false}>
                  <View style={PDFStyle.signatureContainer}>
                    {consentStatus && (
                      <Text style={PDFStyle.consentStatus}>
                        {t('common.prints.consentStatus')}{' '}
                        {'isSigned' in consentStatus
                          ? !consentStatus.isSigned
                            ? t('common.prints.withdrawn')
                            : t('common.prints.signed')
                          : t('common.prints.notSigned')}
                      </Text>
                    )}
                    <Text style={PDFStyle.signatureLabel}>
                      {consentStatus ? (
                        consentStatus.signature ? (
                          <Image
                            src={consentStatus.signature}
                            style={PDFStyle.signatureImage}
                          />
                        ) : consentStatus.signatureType ===
                            PATIENT_DECLARATION && consentStatus.isSigned ? (
                          <Text style={PDFStyle.dataField}>
                            {t('common.prints.systemInfo')} Data Lake System.
                          </Text>
                        ) : (
                          <></>
                        )
                      ) : (
                        '....................'
                      )}
                    </Text>
                    {consentStatus && consentStatus.txnHash && (
                      <Text style={PDFStyle.dataField}>
                        <Text style={PDFStyle.dataField}>
                          {t('common.prints.operationInfo')}{' '}
                          <Link
                            src={`${getBlockExplorer(consentStatus.timestamp)}${
                              consentStatus.txnHash
                            }`}
                          >
                            {t('common.prints.link')}
                          </Link>
                        </Text>
                      </Text>
                    )}
                    <Text style={PDFStyle.dataField}>
                      {consentStatus ? (
                        consentStatus.timestamp ? (
                          moment
                            .unix(consentStatus.timestamp)
                            .format('DD-MM-YYYY HH:mm:ss')
                        ) : (
                          <></>
                        )
                      ) : (
                        '....................'
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={PDFStyle.footer} fixed>
              <View key={index} style={PDFStyle.footerRow}>
                <Text style={PDFStyle.footerText}>
                  {consent.title}, {t('common.prints.version')}:{' '}
                  {consent.version}, {t('common.prints.fromDay')}{' '}
                  {moment.unix(consent.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                </Text>
              </View>
            </View>
          </Page>
        );
      })}

      <Page
        size="A4"
        style={{
          ...PDFStyle.page,
          paddingBottom: consents.length * footerRowHeight,
        }}
        wrap
      >
        <View>
          {consents.map((consent, index) => (
            <View key={index}>
              <Text style={PDFStyle.consentTitle}>
                {t('common.prints.informationObligation')} - "{consent.title}"
              </Text>
              <Text style={PDFStyle.consentContentText}>
                {consent.informationObligation}
              </Text>
            </View>
          ))}
        </View>

        <View style={PDFStyle.footer} fixed>
          {consents.map((consent, index) => (
            <View key={index} style={PDFStyle.footerRow}>
              <Text style={PDFStyle.footerText}>
                {consent.title}, {t('common.prints.version')}: {consent.version}
                , {t('common.prints.fromDay')}{' '}
                {moment.unix(consent.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
