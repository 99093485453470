import React, { ReactNode, useEffect } from 'react';
import api from '../api';
import { useAuth } from './AuthProvider';
import { IBasicUser, IProfileResponse } from '../interfaces/profile.interface';
import { IPermission } from './PermissionProvider';
import { useOperation } from './OperationProvider';
import { ITagModifyRequest } from '../interfaces/tags.interface';
import { OPERATIONS, MAX_ITEMS_PER_PAGE } from '../utils/constants';
import { IParams } from '../interfaces/request.interface';
import { EMPTY_DATA } from '../constants/common';
import {
  IEnterprise,
  IEnterpriseBasicData,
  IEnterpriseResponse,
} from '../interfaces/enterprise.interface';

interface EnterpriseContextType {
  getEnterprises: (params: IParams) => Promise<IEnterpriseResponse>;
  getEnterprise: (taxId: string) => Promise<IEnterprise | null>;
  getEnterpriseBetaEligibility: (
    wallet: string,
    walletSignature: string,
  ) => Promise<boolean>;
  createEnterprise: (enterprise: IEnterpriseBasicData) => Promise<string>;
  modifyEnterprise: (enterprise: IEnterpriseBasicData) => Promise<void>;
  activeEnterprise: (taxId: string) => Promise<void>;
  deactivateEnterprise: (taxId: string) => Promise<void>;
  getEnterpriseUsers: (
    taxId: string,
    params: IParams,
  ) => Promise<IProfileResponse>;
  importEnterpriseUser: (
    taxId: string,
    users: IBasicUser[],
    permissionGroup: string,
    language?: string,
  ) => Promise<void>;
  removeEnterpriseUser: (
    taxId: string,
    userHash: string,
    language?: string,
  ) => Promise<void>;
  getEnterpriseUserPermissions: (
    taxId: string,
    userHash: string,
  ) => Promise<IPermission[]>;
  modifyUserEnterpriseTags: (
    taxId: string,
    tags: ITagModifyRequest,
  ) => Promise<void>;
  modifyEnterpriseMember: (taxId: string, tags: IBasicUser) => Promise<void>;
  enterprises: IEnterpriseResponse;
}

let EnterpriseContext = React.createContext<EnterpriseContextType>(null!);

const EnterpriseProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const [enterprises, setEnterprises] = React.useState<IEnterpriseResponse>();
  const { setOperation } = useOperation();

  useEffect(() => {
    const fetchData = async () => {
      if (auth.getToken()) {
        try {
          await getEnterprises({
            pageSize: MAX_ITEMS_PER_PAGE,
            activated: true,
          });
        } catch (error) {
          console.error('Error:', error);
          setOperation({
            severity: OPERATIONS.ERROR,
            message: error.response.data.details,
          });
        }
      }
    };

    if (!auth.isLoading) fetchData();
  }, [auth.isLoading]);

  const createEnterprise = async (
    enterprise: IEnterpriseBasicData,
  ): Promise<string> => {
    try {
      const response = await api.post('/enterprise/create', enterprise, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return response.data.token || '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getEnterpriseBetaEligibility = async (
    wallet: string,
    walletSignature: string,
  ): Promise<boolean> => {
    try {
      const response = await api.get<boolean>(
        `/enterprise/beta/eligibility?wallet=${wallet}&walletSignature=${walletSignature}`,
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        },
      );
      return response.data ?? null;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getEnterprise = async (taxId: string): Promise<IEnterprise | null> => {
    try {
      const response = await api.get<IEnterprise>(
        `/enterprise?taxId=${taxId}`,
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        },
      );
      return response.data ?? null;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getEnterprises = async (
    params: IParams,
  ): Promise<IEnterpriseResponse> => {
    try {
      const response = await api.get<IEnterpriseResponse>('/enterprises', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params,
      });
      const items: IEnterpriseResponse = response.data ?? {
        data: [],
        total: 0,
      };
      setEnterprises(items);
      return items;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getEnterpriseUsers = async (
    taxId: string,
    params: IParams,
  ): Promise<IProfileResponse> => {
    try {
      const response = await api.get<IProfileResponse>(
        `/enterprise/users?taxId=${taxId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
          params,
        },
      );
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const importEnterpriseUser = async (
    taxId: string,
    users: IBasicUser[],
    permissionGroup: string,
    language?: string,
  ): Promise<void> => {
    try {
      await api.post(
        '/enterprise/users/add',
        { users, permissionGroup, taxId, language },
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const removeEnterpriseUser = async (
    taxId: string,
    userHash: string,
    language?: string,
  ): Promise<void> => {
    try {
      const requestBody = {
        userHash: userHash,
        taxId: taxId,
        language: language,
      };
      await api.post('/enterprise/users/remove', requestBody, {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getEnterpriseUserPermissions = async (
    taxId: string,
    userHash: string,
  ): Promise<IPermission[]> => {
    try {
      const response = await api.get(
        `/enterprise/users/permissions?userHash=${userHash}&taxId=${taxId}`,
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        },
      );
      return response.data ?? [];
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const activeEnterprise = async (taxId: string): Promise<void> => {
    try {
      await api.put(
        `/enterprise/activate`,
        { taxId: taxId },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const deactivateEnterprise = async (taxId: string): Promise<void> => {
    try {
      await api.put(
        `/enterprise/deactivate`,
        { taxId: taxId },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const modifyEnterprise = async (
    enterprise: IEnterpriseBasicData,
  ): Promise<void> => {
    try {
      await api.post(`/enterprise/modify`, enterprise, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const modifyUserEnterpriseTags = async (
    taxId: string,
    requestBody: ITagModifyRequest,
  ): Promise<void> => {
    try {
      await api.put(
        `/enterprise/users/modify/tags`,
        { ...requestBody, taxId },
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const modifyEnterpriseMember = async (
    taxId: string,
    requestBody: IBasicUser,
  ): Promise<void> => {
    try {
      await api.put(
        `/enterprise/user`,
        { ...requestBody, taxId },
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const value = {
    getEnterprises,
    getEnterprise,
    createEnterprise,
    modifyEnterprise,
    activeEnterprise,
    deactivateEnterprise,
    getEnterpriseUsers,
    getEnterpriseBetaEligibility,
    importEnterpriseUser,
    removeEnterpriseUser,
    getEnterpriseUserPermissions,
    modifyUserEnterpriseTags,
    modifyEnterpriseMember,
    enterprises: enterprises || EMPTY_DATA,
  };

  return (
    <EnterpriseContext.Provider value={value}>
      {children}
    </EnterpriseContext.Provider>
  );
};

const useEnterprise = () => {
  return React.useContext(EnterpriseContext);
};

export { EnterpriseProvider, EnterpriseContext, useEnterprise };
