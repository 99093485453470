import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import StarIcon from '@mui/icons-material/Star';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useAuth } from '../../providers/AuthProvider';
import useProfile from '../../hooks/useProfile';
import { profilePage } from '../../routes/router';
import PermissionGuard from '../../guards/PermissionGuard';
import { useAccount, useBalance, useDisconnect } from 'wagmi';
import { LAKE_TOKEN_ADDRESS } from '../../utils/constants';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { LinkOff } from '@mui/icons-material';
import { Link } from '@mui/icons-material';
import { ROUTE } from '../../utils/route';

export default function ProfileSelector() {
  const { t } = useTranslation();
  const auth = useAuth();
  const profile = useProfile();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-menu';
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const balance = useBalance({
    address: address,
    token: LAKE_TOKEN_ADDRESS,
  });
  const [lakeBalance, setLakeBalance] = useState(
    Math.round(Number(balance.data?.formatted)) || 0,
  );

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (address) {
      setLakeBalance(Math.round(Number(balance.data?.formatted)));
    } else {
      setLakeBalance(0);
    }
  }, [address]);

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <ListItem
          alignItems="center"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
        >
          <ListItemText
            primary={profile.currentProfile?.email}
            secondary={
              <>
                <StarIcon style={{ verticalAlign: 'middle', height: 12 }} />
                {t('common.points')} : 0
                <br />
                <MonetizationOnIcon
                  style={{ verticalAlign: 'middle', height: 12 }}
                />
                {t('common.lake')} :{' '}
                {new Intl.NumberFormat('de-DE').format(lakeBalance || 0)}
              </>
            }
          />
          <ListItemAvatar sx={{ paddingLeft: '10px' }}>
            <Avatar>
              <AccountCircleIcon />
            </Avatar>
          </ListItemAvatar>
          <ArrowDropDownIcon />
        </ListItem>
      </Box>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ListSubheader>{t('common.settings')}</ListSubheader>
        {profilePage.map((item, index) => (
          <PermissionGuard permissions={item.permissions} key={index}>
            <MenuItem
              onClick={() => {
                navigate(item.path);
                handleMenuClose();
              }}
              divider
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {t(item.key)}
            </MenuItem>
          </PermissionGuard>
        ))}
        {isConnected ? (
          <MenuItem
            onClick={() => {
              disconnect();
              handleMenuClose();
            }}
            key={'wallet'}
            divider
          >
            <ListItemIcon>
              <LinkOff fontSize="small" />
            </ListItemIcon>
            {t('common.wallet.disconnect')}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              open();
              handleMenuClose();
            }}
            key={'wallet'}
            divider
          >
            <ListItemIcon>
              <Link fontSize="small" />
            </ListItemIcon>
            {t('common.wallet.connect')}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            auth.signOut();
            handleMenuClose();
            navigate(ROUTE.rootPage);
          }}
          key={'logout'}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t('pages.logout.title')}
        </MenuItem>
      </Menu>
    </>
  );
}
