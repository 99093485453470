import React from 'react';
import Button from '@mui/material/Button';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useTranslation } from 'react-i18next';
import { DAPP_LINK } from '../../constants/common';
import { LastPage } from '@mui/icons-material';
import { COLORS } from '../../utils/constants';

export default function ContactSupport() {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

    if (supportEmail) {
      window.location.href = `mailto:${supportEmail}`;
    } else {
      console.error('E-mail not exist in .env file');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          color="inherit"
          startIcon={<ContactSupportIcon />}
          onClick={handleButtonClick}
          style={{ whiteSpace: 'nowrap', marginRight: '12px' }}
        >
          {t('common.contact-support')}
        </Button>
        <div
          style={{
            width: '1px',
            height: '24px',
            backgroundColor: COLORS.primaryColor,
            margin: '0 12px',
          }}
        ></div>

        <Button
          color="inherit"
          startIcon={<LastPage />}
          onClick={() => window.open(DAPP_LINK)}
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('common.go-to-dapp')}
        </Button>
      </div>
    </>
  );
}
