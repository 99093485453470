import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import { Box, Tab } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import NoData from '../../../components/utils/NoData';
import PermissionGuard from '../../../guards/PermissionGuard';
import AddIcon from '@mui/icons-material/Add';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import LoadingController from '../../../components/utils/LoadingController';
import Pagination from '@mui/material/Pagination';
import { Tabs, ITEMS_PER_PAGE, OPERATIONS } from '../../../utils/constants';
import { TabContext, TabList } from '@mui/lab';
import { usePermission } from '../../../providers/PermissionProvider';
import { useOperation } from '../../../providers/OperationProvider';
import {
  IConsent,
  IConsentOperation,
  IConsentResponse,
} from '../../../interfaces/consents.interface';
import useConsents from '../../../hooks/useConsents';
import DynamicTable, {
  Column,
} from '../../../components/builders/DynamicTable';
import moment from 'moment';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import ConsentDialog from './ConsentDialog';
import ConsentHistory from './ConsentHistory';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_SORTING,
  EMPTY_DATA,
  SEARCH_INPUT_DELAY,
} from '../../../constants/common';

export default function ConsentPage() {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const {
    getConsents,
    getConsentDrafts,
    getConsentHistory,
    getConsentVersion,
  } = useConsents();
  const permissionsProvider = usePermission();
  const enterpriseProvider = useEnterprise();

  const [searchParams, setSearchParams] = useSearchParams();
  const [fetchedConsents, setFetchedConsents] =
    useState<IConsentResponse>(EMPTY_DATA);
  const [consentHistory, setConsentHistory] = useState<IConsentOperation[]>([]);
  const [consentVersions, setConsentVersions] = useState<IConsent[]>([]);
  const [areConsentVersionsLoading, setAreConsentVersionsLoading] =
    useState<boolean>(false);
  const [selectedConsent, setSelectedConsent] = useState<IConsent>();
  const [expandedConsent, setExpandedConsent] = useState<IConsent>();
  const [selectedConsentVersion, setSelectedConsentVersion] =
    useState<IConsent>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isConsentHistoryLoading, setIsConsentHistoryLoading] =
    useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [sorting, setSorting] = useState<string>(DEFAULT_SORTING);
  const [tab, setTab] = useState<Tabs>(Tabs.NONE);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState<number>(0);
  const [activityRefresh, setActivityRefresh] = useState<number>(0);

  const columnsBase: Column<IConsent>[] = [
    {
      label: t('common.tables.header.version'),
      accessor: 'version',
      type: 'version',
    },
    {
      label: t('common.tables.header.title'),
      accessor: 'title',
      type: 'title',
    },
    {
      label: t('common.tables.header.description'),
      accessor: 'description',
      type: 'description',
    },
    {
      label: t('common.tables.header.content'),
      accessor: 'content',
      type: 'content',
    },
    {
      label: t('common.tables.header.informationObligation'),
      accessor: 'informationObligation',
      type: 'informationObligation',
    },
    {
      label: t('common.tables.header.signatureType'),
      accessor: 'signatureType',
      type: 'signatureType',
    },
    {
      label: t('common.tables.header.taxId'),
      accessor: 'taxId',
      type: 'taxId',
    },
    {
      label: t('common.tables.header.tags'),
      accessor: 'tags',
      type: 'tags',
    },
    {
      label: t('common.tables.header.activated'),
      accessor: 'activated',
      type: 'activated',
    },
    {
      label: t('common.tables.header.modified-at'),
      accessor: 'createdAt',
      type: 'createdAt',
      formatter: (value: number) => (
        <span>{moment.unix(value).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
  ];

  const columns: Column<IConsent>[] =
    tab !== Tabs.DRAFT
      ? [
          {
            label: '',
            accessor: 'consentHash',
            type: 'expand',
          },
          ...columnsBase,
        ]
      : columnsBase;

  useEffect(() => {
    if (enterpriseProvider.enterprises.data.length > 0 && refresh > 0)
      fetchConsents(pageIndex, tab, sorting, searchQuery);
  }, [refresh, enterpriseProvider.enterprises]);

  useEffect(() => {
    if (expandedConsent) {
      fetchConsentVersions(expandedConsent.consentHash);
      setSelectedConsentVersion(expandedConsent);
    }
  }, [expandedConsent]);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    const tab = searchParams.get('tab');
    setSearchQuery(searchParams.get('searchQuery') || '');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      sorting && sorting.split('_').length === 2 ? sorting : DEFAULT_SORTING,
    );
    setTab(tab === Tabs.DRAFT ? Tabs.DRAFT : Tabs.NONE);
    setRefresh(Date.now());
  }, [searchParams]);

  useEffect(() => {
    if (selectedConsentVersion)
      fetchConsentHistory(selectedConsentVersion.consentHash);
  }, [selectedConsentVersion, activityRefresh]);

  const fetchConsentVersions = async (consentHash: string) => {
    setAreConsentVersionsLoading(true);
    try {
      setConsentVersions(await getConsentVersion(consentHash));
    } catch (error) {
      console.error('Error fetching consent versions:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreConsentVersionsLoading(false);
    }
  };

  const onSortClick = (column: string) => {
    setSearchParams({
      tab,
      searchQuery,
      sorting: `${column}_${
        sorting.split('_')[0] === column && sorting.split('_')[1] === 'ASC'
          ? 'DESC'
          : 'ASC'
      }`,
    });
  };

  const onConsentClick = (consent: IConsent) => {
    setSelectedConsent(consent);
    setIsDialogOpen(true);
  };

  const onTabClick = (tab: Tabs) => {
    setSearchParams({ tab });
  };

  const fetchConsents = async (
    pageIndex: number,
    tab: string,
    sorting: string,
    searchQuery: string,
  ) => {
    setIsLoading(true);
    try {
      const taxIds = enterpriseProvider.enterprises.data
        .filter((ent) =>
          permissionsProvider.permissions.some(
            (perm) =>
              perm.taxId === ent.taxId &&
              perm.flag ===
                (tab === Tabs.DRAFT
                  ? 'ENTERPRISE_GET_CONSENT_DRAFTS'
                  : 'ENTERPRISE_GET_CONSENTS'),
          ),
        )
        .map((el) => el.taxId);

      const fetchedConsents =
        tab === Tabs.DRAFT
          ? await getConsentDrafts(taxIds, {
              pageIndex: pageIndex - 1,
              sorting,
              searchQuery,
            })
          : await getConsents(taxIds, {
              pageIndex: pageIndex - 1,
              sorting,
              searchQuery,
            });

      setFetchedConsents(fetchedConsents);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchConsentHistory = async (consentHash: string) => {
    setIsConsentHistoryLoading(true);
    try {
      setConsentHistory(await getConsentHistory(consentHash));
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsConsentHistoryLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      tab,
      sorting,
      searchQuery,
    });
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({
        tab,
        sorting,
        searchQuery,
      });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.consents.title')}
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.consents')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
        subtitle={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <PermissionGuard permissions={['ENTERPRISE_GET_CONSENT_DRAFTS']}>
              <TabContext value={tab}>
                <Box>
                  <TabList onChange={(event, newValue) => onTabClick(newValue)}>
                    <Tab label={t('common.sorting.all')} value={Tabs.NONE} />
                    <Tab
                      label={t('common.sorting.drafts')}
                      value={Tabs.DRAFT}
                    />
                  </TabList>
                </Box>
              </TabContext>
            </PermissionGuard>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <PermissionGuard
                permissions={['ENTERPRISE_CREATE_CONSENT_DRAFT']}
              >
                <Box sx={{ ml: '10px' }}>
                  <ClassicButton
                    sx={{ mt: 0 }}
                    startIcon={<AddIcon />}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    {t('common.button.add-btn')}
                  </ClassicButton>
                </Box>
              </PermissionGuard>
            </Box>
          </Box>
        }
      >
        {isLoading ? (
          <LoadingController />
        ) : fetchedConsents.total === 0 ? (
          <NoData />
        ) : (
          <DynamicTable
            title={''}
            data={fetchedConsents.data}
            columns={columns}
            onHeaderClick={onSortClick}
            onItemClick={onConsentClick}
            onExpandClick={(value) =>
              setExpandedConsent(
                value.consentHash === expandedConsent?.consentHash
                  ? undefined
                  : value,
              )
            }
            sort={{
              column: sorting.split('_')[0],
              direction: sorting.split('_')[1],
            }}
            innerData={
              <>
                {areConsentVersionsLoading ? (
                  <LoadingController />
                ) : (
                  consentHistory &&
                  expandedConsent &&
                  selectedConsentVersion && (
                    <ConsentHistory
                      data={consentHistory}
                      consent={selectedConsentVersion}
                      versions={consentVersions}
                      setSelectedConsentVersion={(consent) =>
                        setSelectedConsentVersion(consent)
                      }
                      isConsentHistoryLoading={isConsentHistoryLoading}
                      setRefresh={setActivityRefresh}
                    />
                  )
                )}
              </>
            }
            expandedRow={expandedConsent?.consentHash}
          />
        )}

        {fetchedConsents.total > 0 && (
          <Pagination
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            count={Math.ceil(fetchedConsents.total / ITEMS_PER_PAGE)}
            page={pageIndex}
            onChange={handlePageChange}
            style={{ margin: '10px 0' }}
          />
        )}
      </NodeContainer>

      {isDialogOpen && (
        <ConsentDialog
          consent={selectedConsent}
          open={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
            setSelectedConsent(undefined);
          }}
          setRefresh={(value) => {
            setRefresh(value);
          }}
        />
      )}
    </header>
  );
}
