import { DefaultTFuncReturn } from 'i18next';
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface OperationsContextType {
  operation: { severity: string; message?: string | DefaultTFuncReturn } | null;
  setOperation: (
    operation: {
      severity: string;
      message?: string | DefaultTFuncReturn;
    } | null,
  ) => void;
}

const OperationsContext = createContext<OperationsContextType | undefined>(
  undefined,
);

const OperationProvider = ({ children }: { children: ReactNode }) => {
  const [operation, setOperation] = useState<{
    severity: string;
    message?: string | DefaultTFuncReturn;
  } | null>(null);
  const value: OperationsContextType = {
    operation,
    setOperation: (operation) => setOperation(operation),
  };

  return (
    <OperationsContext.Provider value={value}>
      {children}
    </OperationsContext.Provider>
  );
};

const useOperation = () => {
  const context = useContext(OperationsContext);
  if (!context) {
    throw new Error('useOperation must be used within an OperationsProvider');
  }
  return context;
};

export { OperationProvider, OperationsContext, useOperation };
