import api from '../api';
import { ILogResponse } from '../interfaces/logs.interface';
import { useAuth } from '../providers/AuthProvider';
import { IParams } from '../interfaces/request.interface';
import { EMPTY_DATA } from '../constants/common';

interface LoggerContextType {
  getLogs: (taxIds: string[], params: IParams) => Promise<ILogResponse>;
}

const useLogs = (): LoggerContextType => {
  const auth = useAuth();

  const getLogs = async (
    taxIds: string[],
    params: IParams,
  ): Promise<ILogResponse> => {
    try {
      const response = await api.get<ILogResponse>('/logs', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          taxIds,
          ...params,
        },
      });
      return response.data ?? EMPTY_DATA;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getLogs,
  };
};

export default useLogs;
