import { useState } from 'react';
import DynamicTable from '../../../components/builders/DynamicTable';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  IConsent,
  IConsentOperation,
} from '../../../interfaces/consents.interface';
import ConsentActivityTable from './ConsentActivityTable';
import { TabContext, TabList } from '@mui/lab';
import LoadingController from '../../../components/utils/LoadingController';

interface Props {
  consent: IConsent;
  versions: IConsent[];
  data: IConsentOperation[];
  isConsentHistoryLoading: boolean;
  setSelectedConsentVersion: (consent: IConsent) => void;
  setRefresh: (value: number) => void;
}

export default function ConsentHistory({
  data,
  consent,
  versions,
  isConsentHistoryLoading,
  setSelectedConsentVersion,
  setRefresh,
}: Props) {
  const { t } = useTranslation();
  const [selectedActivity, setSelectedActivity] = useState<IConsentOperation>();

  return (
    <>
      <TabContext value={consent.consentHash}>
        <Box sx={{ marginBottom: '10px' }}>
          <TabList
            onChange={(event, newValue) =>
              setSelectedConsentVersion(
                versions.find((version) => version.consentHash === newValue)!,
              )
            }
          >
            {versions.map((version) => (
              <Tab
                label={`v.${version.version}`}
                value={version.consentHash}
                key={version.id}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      {isConsentHistoryLoading ? (
        <LoadingController />
      ) : data.length > 0 ? (
        <Box sx={{ overflowY: 'scroll', maxHeight: '300px' }}>
          <DynamicTable
            title={t('common.labels.users-in-consent-table')}
            data={data}
            columns={[
              {
                label: t('common.tables.header.status'),
                accessor: 'operations',
                type: 'status',
              },
              {
                label: t('common.tables.header.email'),
                accessor: 'user',
                type: 'userEmail',
              },
              {
                label: t('common.tables.header.phone'),
                accessor: 'user',
                type: 'userPhone',
              },
              {
                label: t('common.tables.header.first-name'),
                accessor: 'user',
                type: 'userFirstName',
              },
              {
                label: t('common.tables.header.second-name'),
                accessor: 'user',
                type: 'userSecondName',
              },
              {
                label: t('common.labels.personalId'),
                accessor: 'user',
                type: 'userPersonalId',
              },
            ]}
            onHeaderClick={() => {}}
            onItemClick={(item) => {
              setSelectedActivity(item);
            }}
            onExpandClick={() => {}}
          />
        </Box>
      ) : (
        <div>{t('common.error.no-data')}</div>
      )}
      {selectedActivity && (
        <Dialog
          open={!!selectedActivity}
          onClose={() => setSelectedActivity(undefined)}
          fullWidth
          maxWidth={'lg'}
        >
          <DialogTitle>
            <Typography component="h5" variant="h5">
              {t('common.labels.operationsHistory')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <ConsentActivityTable
              data={selectedActivity.operations}
              consent={consent}
              user={selectedActivity.user}
              setRefresh={(value) => {
                setRefresh(value);
                setSelectedActivity(undefined);
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
