import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import { usePopper } from 'react-popper';
import Button from '@mui/material/Button';
import { TabletMac } from '@mui/icons-material';
import { OutlineButton, UnderlinedButton } from '../../layouts/styled/buttons';
import { COLORS } from '../../utils/constants';

interface QRCodeButtonProps {
  link: string;
  targetElementId: string;
}

const QRCodeButton: React.FC<QRCodeButtonProps> = ({
  link,
  targetElementId,
}) => {
  const [showQR, setShowQR] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const targetRef = useRef<HTMLElement | null>(
    document.getElementById(targetElementId),
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(buttonRef.current, popperElement, {
    placement: 'top',
  });

  const handleClick = () => {
    setShowQR(!showQR);
  };

  return (
    <div>
      <UnderlinedButton
        ref={buttonRef}
        sx={{
          width: '20px',
          height: '40px',
          marginLeft: '5px',
          borderRadius: '30%',
          color: COLORS.primaryColor,
        }}
        onClick={handleClick}
      >
        <TabletMac />
      </UnderlinedButton>
      {showQR && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            border: '1px solid blue',
            padding: '5px',
            marginBottom: '10px',
            backgroundColor: 'white',
          }}
          {...attributes.popper}
        >
          <QRCode value={link} />
        </div>
      )}
    </div>
  );
};

export default QRCodeButton;
