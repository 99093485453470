import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';

interface Props {
  header: ReactNode;
  content: ReactNode;
}

export default function GridElement({ header, content }: Props) {
  return (
    <Grid container spacing={2} sx={{ py: 1 }}>
      <Grid item xs={12} md={6}>
        {header}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ textAlign: { xs: 'center', md: 'right' } }}
      >
        {content}
      </Grid>
    </Grid>
  );
}
