import React, { useState } from 'react';
import { Card, Chip, Typography } from '@mui/material';
import { IBasicUser, IProfile } from '../../../../interfaces/profile.interface';
import { UnderlinedButton } from '../../../../layouts/styled/buttons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GridElement from '../../../../components/items/GridElement';
import { ROUTE } from '../../../../utils/route';
interface Props {
  taxId: string;
  previousPath: string;
  profile: IProfile;
}
export default function UserItem({ taxId, previousPath, profile }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IBasicUser>({
    userHash: profile?.userHash ?? '',
    email: profile?.email ?? '',
    firstName: profile?.firstName ?? '',
    secondName: profile?.secondName ?? '',
    phone: profile?.phone ?? '',
    personalId: profile?.personalId ?? '',
    verificationLevel: profile.verificationLevel,
  });

  return (
    <Card sx={{ p: 5 }}>
      <Typography variant={'h3'} sx={{ paddingBottom: '10px' }}>
        {formData?.firstName && formData?.secondName
          ? `${formData.firstName} ${formData.secondName}`
          : formData?.email || 'Brak danych'}
      </Typography>

      {Object.keys(formData).map((field) => (
        <GridElement
          key={field}
          header={
            <Typography variant={'body1'}>
              {t(`common.profile.${field}`)}
            </Typography>
          }
          content={
            formData[field as keyof IBasicUser] !== '' ? (
              <Typography variant={'subtitle1'}>
                {field === 'userHash'
                  ? `${formData.userHash.slice(
                      0,
                      7,
                    )}...${formData.userHash.slice(-6)}`
                  : formData[field as keyof IBasicUser]}
              </Typography>
            ) : (
              <Chip
                label={t('common.no-data')}
                color="default"
                variant="outlined"
                size="small"
                style={{ marginLeft: '8px' }}
              />
            )
          }
        />
      ))}

      <UnderlinedButton
        sx={{ paddingBottom: '10px' }}
        onClick={() =>
          navigate(
            ROUTE.dashboardPage.enterprise.addUser.replace(':taxId', taxId),
            { state: { profile: profile, previousPath: previousPath } },
          )
        }
      >
        {t('common.button.show-more')}
      </UnderlinedButton>
    </Card>
  );
}
