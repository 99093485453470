import { useEffect, useState } from 'react';
import api from '../api';
import { useAuth } from '../providers/AuthProvider';
import { useOperation } from '../providers/OperationProvider';
import { IBasicUser, IProfile } from '../interfaces/profile.interface';
import { OPERATIONS } from '../utils/constants';

interface ProfileContextType {
  deleteProfile: () => Promise<boolean>;
  modifyProfile: (requestBody: IBasicUser) => Promise<boolean>;
  setRefresh: (value: number) => void;
  currentProfile: IProfile | undefined;
}

const useProfile = (): ProfileContextType => {
  const auth = useAuth();
  const [refresh, setRefresh] = useState(0);
  const [currentProfile, setCurrentProfile] = useState<IProfile>();
  const { setOperation } = useOperation();

  useEffect(() => {
    const fetchData = async () => {
      if (auth.getToken()) {
        try {
          await getProfile();
        } catch (error) {
          setOperation({
            severity: OPERATIONS.ERROR,
            message: error.response.data.details,
          });
        }
      }
    };

    fetchData();
  }, [refresh]);

  const deleteProfile = async () => {
    try {
      await api.delete('/user', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return true;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getProfile = async () => {
    try {
      const response = await api.get<IProfile>('/user', {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      setCurrentProfile(response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        auth.signOut();
      } else {
        console.error('Error:', error);
        throw error;
      }
    }
  };

  const modifyProfile = async (requestBody: IBasicUser) => {
    try {
      await api.put('/user', requestBody, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return true;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    deleteProfile,
    modifyProfile,
    setRefresh,
    currentProfile,
  };
};

export default useProfile;
